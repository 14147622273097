import React, { useState, useCallback, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import EmailExportGrid from "./EmailExportGrid";

import { axiosEmailExportRoute } from "../helpers/axios";

const EmailExport = () => {
  const {
    token,
    notify,
    referentSelection,
    seminarReferenteSelection,
    getSeminarData,
    axiosGetEmailListsRoute,
    axiosCustomQueryRoute,
  } = useOutletContext();

  const [loadingDyn, setLoadingDyn] = useState(false);
  const [selectedReferent, setSelectedReferent] = useState([]);
  const [seminarQueryResults, setSeminarQueryResults] = useState([]);
  const [emailQueryResults, setEmailQueryResults] = useState([]);
  const [taggedSeminars, setTaggedSeminars] = useState([]);
  const [selectedSeminarIds, setSelectedSeminarIds] = useState([]);
  const [seminarsToTag, setSeminarsToTag] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmailList, setSelectedEmailList] = useState(0);

  const [query, setQuery] = useState({
    daterange: "",
    emailsearch: "",
  });

  const searchByEmail = useCallback(
    async (query) => {
      try {
        const customqueryresults = await axiosCustomQueryRoute.post(
          `/searchemails`,
          {
            data: { query },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (customqueryresults.data) {
          return customqueryresults.data;
        }
      } catch (err) {
        notify("error", err.response.data.error.message);
      }
    },
    [token, axiosCustomQueryRoute, notify]
  );

  const filterHandler = useCallback(
    async (e, referentlist = [], tagoption = null, taggedseminars = []) => {
      if (e !== null) {
        e.preventDefault();
        setLoadingDyn(e.target.name);
      }

      let data = null;
      let today = new Date(); // Get today's date
      let lastYear = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      ); // Subtract one year from today's date

      if (query.emailsearch !== "") {
        // search by client emails

        data = await searchByEmail(query.emailsearch);

        if (data?.length > 0) {
          setEmailQueryResults(data);
        } else {
          data = null;
        }
      } else {
        data = await getSeminarData(
          (query?.daterange && query.daterange[0]) || lastYear,
          (query?.daterange && query?.daterange[1]) || null,
          referentlist,
          tagoption,
          taggedseminars
        );

        if (data?.results?.length > 0) {
          setSeminarQueryResults(data.results);
          setTaggedSeminars(data.tagged_seminars);
        } else {
          data = null;
        }
      }

      if (!data) {
        notify("info", "Keine Ergebnisse");
      }

      if (e !== null) {
        setLoadingDyn(false);
        setSeminarsToTag([]);
        setSelectedSeminarIds([]);
        setSelectedEmails([]);
      }
    },
    [getSeminarData, query, searchByEmail, notify]
  );

  const getEmailLists = useCallback(async () => {
    try {
      let result;

      result = await axiosGetEmailListsRoute.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data) {
        setEmailList(result.data);
      }
    } catch (err) {
      notify("info", err.response.data.error.message);
    }
  }, [token, axiosGetEmailListsRoute, notify]);

  const exportEmailHandler = useCallback(
    async (
      selectedEmailsList = [],
      method = null,
      selected_mailing_list = null
    ) => {
      setLoadingDyn(method);
      try {
        let result;

        result = await axiosEmailExportRoute.post(
          `/`,
          {
            data: {
              selected_emails_list: [...selectedEmailsList],
              method,
              selected_mailing_list: selected_mailing_list,
              ...(method === "addtolist" && {
                taggedseminars: taggedSeminars
                  .filter((seminar) => selectedSeminarIds.includes(seminar.id))
                  .map((seminar) => seminar.id),
              }),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          if (method === "exportlist") {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `biteg-email-list-export.csv`);
            document.body.appendChild(link);
            link.click();
            window.scrollTo({ top: 0, behavior: "smooth" });
          } else if (method === "addtolist" || method === "removefromlist") {
            const { message } = result.data;

            await filterHandler(
              null,
              selectedReferent.map((ref) => ref.id)
            );
            await getEmailLists();
            setSelectedEmails([]);

            message && notify("success", message);
          }
          setLoadingDyn(false);
        }
      } catch (err) {
        setLoadingDyn(false);
        notify("info", err.response.data.error.message);
      }
    },
    [
      notify,
      token,
      selectedReferent,
      filterHandler,
      selectedSeminarIds,
      taggedSeminars,
      getEmailLists,
    ]
  );

  const getTaggedSeminars = useCallback(async () => {
    try {
      const results = await getSeminarData();
      setTaggedSeminars(results.tagged_seminars);
    } catch (err) {
      notify("info", err.response.data.error.message);
    }
  }, [getSeminarData, notify]);

  useEffect(() => {
    getEmailLists();
    getTaggedSeminars();
  }, [getEmailLists, getTaggedSeminars]);

  const resetState = (current) => {
    setSelectedSeminarIds([]);
    setSeminarQueryResults([]);
    setSeminarsToTag([]);
    setSelectedEmails([]);
    setTaggedSeminars([]);
    setSelectedEmailList(0);
    setEmailQueryResults([]);
    if (current === "emailsearch") {
      setQuery({ ...query, daterange: "" });
      setSelectedReferent([]);
    } else if (current === "daterange" || current === "referent") {
      setQuery({ ...query, emailsearch: "" });
    }
  };

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Email Export</h3>
        </div>
      </div>

      <div className="w-full p-5 text-sm font-medium text-gray-700">
        <form>
          <div className="grid gap-4">
            <EmailExportGrid
              taggedSeminars={taggedSeminars}
              selectedReferent={selectedReferent}
              setSelectedReferent={setSelectedReferent}
              exportEmailHandler={exportEmailHandler}
              setQuery={setQuery}
              query={query}
              loadingDyn={loadingDyn}
              filterHandler={filterHandler}
              seminarQueryResults={seminarQueryResults}
              setSeminarQueryResults={setSeminarQueryResults}
              seminarReferenteSelection={seminarReferenteSelection}
              referentSelection={referentSelection}
              seminarsToTag={seminarsToTag}
              setSeminarsToTag={setSeminarsToTag}
              selectedSeminarIds={selectedSeminarIds}
              setSelectedSeminarIds={setSelectedSeminarIds}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
              emailList={emailList}
              setEmailList={setEmailList}
              selectedEmailList={selectedEmailList}
              setSelectedEmailList={setSelectedEmailList}
              emailQueryResults={emailQueryResults}
              resetState={resetState}
            />
          </div>
        </form>
      </div>
    </main>
  );
};

export default EmailExport;
