import React, { useState, Fragment, useCallback } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import deLocale from "date-fns/locale/de";

import Modal from "@mui/material/Modal";

import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import emailtemplates from "./EmailTemplates";
import { useEffect } from "react";

import { axiosSendEmailRoute } from "../helpers/axios";
import Fileviewer from "./Fileviewer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { HTMLEditor } from "./partials/HTMLEditor";

const EmailModal = ({
  sendEmail,
  setSendEmail,
  seminarInputs,
  raumeSelection,
  selectedParticipantIds,
  token,
  notify,
  invoiceParticipantConnection,
  invoiceInputs,
  emailDelayTimer,
  getFutureEmails,
  userDetails,
  emailQueryMode,
  // resetAndGet,
  activeParticipantCheck,
}) => {
  const [emailInputs, setEmailInputs] = useState({});
  const [raumeQuery, setRaumeQuery] = useState("");
  const [loadingDyn, setLoadingDyn] = useState(false);
  const [target, setTarget] = useState("participants");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileSelector, setFileSelector] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const emailInvoiceHandler = useCallback(
    async (type, mode, target) => {
      try {
        let result;

        let form = new FormData();

        uploadedFiles.forEach((file) => {
          form.append(`files.attachments`, file, file.name);
        });

        let data = {
          type,
          mode: mode === "genselectedkunden" ? "genselected" : mode,
          target,
          emaildelay: emailDelayTimer,
          searchbyseminarid: seminarInputs.id,
          attachedfileids:
            selectedFiles.length > 0
              ? selectedFiles.map((file) => file.id)
              : [],
          emailcontents: {
            subject: emailInputs.subject,
            body: emailInputs.body,
          },
          ...(mode === "genselected" || mode === "genselectedkunden"
            ? {
                sempartids:
                  mode === "genselected"
                    ? selectedParticipantIds
                    : invoiceParticipantConnection.map(
                        (participant) => participant.id
                      ),
              }
            : mode === "paymentreminder" && { invoiceids: [invoiceInputs.id] }),
        };

        form.append("data", JSON.stringify(data));

        result = await axiosSendEmailRoute.post(`/`, form, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result?.data) {
          notify("info", "Email Sent");
          setTarget("participant");
          getFutureEmails(emailQueryMode);
          setSendEmail(false);
          setEmailInputs({});
          setLoadingDyn(false);
          setUploadedFiles([]);
          setSelectedFiles([]);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      } catch (err) {
        setEmailInputs({});
        setLoadingDyn(false);
        notify("error", err.response.data.error.message, { autoClose: false });
      }
      setSendEmail(false);
    },
    [
      notify,
      token,
      seminarInputs.id,
      selectedParticipantIds,
      emailInputs,
      selectedFiles,
      setSendEmail,
      invoiceInputs.id,
      emailDelayTimer,
      emailQueryMode,
      getFutureEmails,
      setTarget,
      uploadedFiles,
      invoiceParticipantConnection,
    ]
  );

  const convertIsoToLocal = (params) => {
    if (params !== null) {
      let isoDate = params;

      var d = new Date(isoDate);
      return d.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return "";
    }
  };

  const filteredRaume =
    raumeQuery === ""
      ? raumeSelection
      : raumeSelection.filter((raume) =>
          raume.attributes.bezeichnung
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(raumeQuery.toLowerCase().replace(/\s+/g, ""))
        );

  const currentRaumeHandler = (e) => {
    const id = Number(e);

    const filterRaumeById = raumeSelection.filter(
      (raume) => raume.id === id
    )[0];

    setEmailInputs((current) => ({
      ...current,
      neuRaume: filterRaumeById,
    }));
  };

  useEffect(() => {
    const { subject, body } = emailtemplates(
      seminarInputs,
      {
        reason: emailInputs.reason,
        neuRaume: emailInputs.neuRaume,
        neu_datum: emailInputs.neu_datum,
      },
      invoiceInputs,
      invoiceParticipantConnection,
      userDetails.username
    );

    setEmailInputs((current) => ({
      ...current,
      subject,
      body,
    }));
  }, [
    invoiceInputs,
    invoiceParticipantConnection,
    emailInputs.reason,
    emailInputs.neuRaume,
    emailInputs.neu_datum,
    seminarInputs,
    userDetails.username,
  ]);

  const handleFileEvent = (e) => {
    const limit = 10;
    const uploaded = [];
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    // limit file size in total to 10MB
    const totalSize = chosenFiles.reduce((acc, file) => {
      return acc + file.size;
    }, 0);

    if (chosenFiles.length > limit) {
      notify("error", `maximal ${limit} Anhänge`);
    } else if (totalSize > 10000000) {
      notify("error", `maximal 10MB Anhänge insgesamt`);
    } else {
      uploaded.push(chosenFiles);
      setUploadedFiles(...uploaded);
    }

    // require reset of input field to trigger onChange event again
    e.target.value = "";
  };

  return (
    <Modal
      open={
        sendEmail === "genall" ||
        sendEmail === "genselectedkunden" ||
        sendEmail === "genselected" ||
        sendEmail === "paymentreminder"
      }
      aria-describedby="modal-modal-description"
      onClose={() => {
        setSendEmail(false);
        setEmailInputs({});
        setTarget("participants");
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="customized-scrollbar h-screen w-screen p-4"
      >
        <div
          id="email-send"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="bg-white relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-full overflow-auto max-h-full customized-scrollbar">
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 px-10 p-5">
              {/* <div className="col-span-12 border-b border-lightgray pb-5">
                <h3>
                  Angaben Zur{" "}
                  {sendEmail === "paymentreminder"
                    ? "Erinnerungs-E-Mail"
                    : "Seminarverschiebung"}
                </h3>
              </div> */}
              <div className="col-span-12 flex flex-col">
                <div className="w-full border-b border-lightgray pb-4">
                  {sendEmail !== "paymentreminder"
                    ? "Seminarthema: " + seminarInputs?.thema
                    : "Adressat und Rechnungsnummer: " +
                      `${invoiceInputs?.attributes?.clientDetails?.attributes?.bezeichnung}, ${invoiceInputs?.attributes?.rechnungsNum}`}
                </div>
                <div className="grid grid-cols-7 gap-x-6 gap-y-3 mt-4">
                  <div className="col-span-4 w-full">
                    <div className="grid grid-cols-5 gap-y-3">
                      {sendEmail !== "paymentreminder" && (
                        <>
                          <span className="col-span-2">Alter Termin:</span>
                          <div className="col-span-3">
                            {convertIsoToLocal(seminarInputs.datum)}
                          </div>
                          <span
                            className={`${
                              emailInputs.reason !== "referenten" &&
                              "opacity-25 pointer-events-none"
                            } col-span-2`}
                          >
                            Neuer Termin:
                          </span>
                          <div
                            className={`${
                              emailInputs.reason !== "referenten" &&
                              "opacity-25 pointer-events-none"
                            } col-span-3`}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={deLocale}
                            >
                              <DatePicker
                                mask="__.__.____"
                                value={emailInputs.neu_datum || null}
                                onChange={(newValue) =>
                                  setEmailInputs((prior) => ({
                                    ...prior,
                                    neu_datum: newValue,
                                  }))
                                }
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                          <span className="col-span-2">
                            Alte Raumbezeich...
                          </span>
                          <div className="col-span-3">
                            {seminarInputs?.raum_details?.bezeichnung}
                          </div>
                          <span className="col-span-2">
                            Alter Veranstaltungsort:
                          </span>
                          <span className="col-span-3">
                            {seminarInputs?.id_raum !== 423 && (
                              <>{seminarInputs?.raum_details?.ort}</>
                            )}
                          </span>

                          <span className="col-span-2">
                            Alte Anschrift Veranstaltungsort:
                          </span>
                          <div className="col-span-3">
                            {seminarInputs?.id_raum !== 423 && (
                              <>
                                {seminarInputs?.raum_details?.strasse &&
                                seminarInputs?.id_raum !== null
                                  ? seminarInputs.raum_details.strasse
                                  : ""}{" "}
                                {seminarInputs?.raum_details?.haus_nr &&
                                seminarInputs?.id_raum !== null
                                  ? seminarInputs.raum_details.haus_nr
                                  : ""}
                                <br />
                                {seminarInputs?.raum_details?.plz &&
                                seminarInputs?.id_raum !== null
                                  ? seminarInputs.raum_details.plz
                                  : ""}{" "}
                                {seminarInputs?.raum_details?.ort &&
                                seminarInputs?.id_raum !== null
                                  ? seminarInputs.raum_details.ort
                                  : ""}
                              </>
                            )}
                          </div>

                          <>
                            <span
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-2 text-primary-600`}
                            >
                              Neue Raumbezeichung:
                            </span>
                            <span
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-4`}
                            >
                              <Combobox
                                value={
                                  !emailInputs.neuRaume
                                    ? ""
                                    : emailInputs.neuRaume
                                }
                                onChange={currentRaumeHandler}
                              >
                                <div className="relative mt-1">
                                  <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                    <Combobox.Input
                                      className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                                      displayValue={(raume) =>
                                        raume !== ""
                                          ? raume.attributes.bezeichnung
                                          : ""
                                      }
                                      onChange={(event) =>
                                        setRaumeQuery(event.target.value)
                                      }
                                    />
                                    {raumeQuery === "" &&
                                    !emailInputs.neuRaume ? null : (
                                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-7">
                                        <HighlightOffIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                          onClick={() =>
                                            setEmailInputs((current) => ({
                                              ...current,
                                              neuRaume: null,
                                            }))
                                          }
                                        />
                                      </Combobox.Button>
                                    )}
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </Combobox.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setRaumeQuery("")}
                                  >
                                    <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {filteredRaume.length === 0 &&
                                      raumeQuery !== "" ? (
                                        <div className="relative cursor-default select-none py-2 px-4 ">
                                          Nichts gefunden.
                                        </div>
                                      ) : (
                                        filteredRaume.map((raume) => (
                                          <Combobox.Option
                                            key={raume.id}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 px-2 ${
                                                active
                                                  ? "bg-primary-900 text-white"
                                                  : ""
                                              }`
                                            }
                                            value={raume.id}
                                          >
                                            <span className="block truncate">
                                              {raume.attributes.bezeichnung}{" "}
                                            </span>
                                          </Combobox.Option>
                                        ))
                                      )}
                                    </Combobox.Options>
                                  </Transition>
                                </div>
                              </Combobox>
                            </span>
                            <span
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-2 text-primary-600`}
                            >
                              Neuer Veranstaltungsort:
                            </span>
                            <div
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-3`}
                            >
                              {emailInputs?.neuRaume?.id !== 423 && (
                                <>{emailInputs?.neuRaume?.attributes.ort}</>
                              )}
                            </div>
                            <span
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-2 text-primary-600`}
                            >
                              Neue Anschrift Veranstaltungsort:
                            </span>
                            <div
                              className={`${
                                emailInputs.reason !== "neuraum" &&
                                "opacity-25 pointer-events-none"
                              } col-span-3 `}
                            >
                              {emailInputs?.neuRaume?.id !== 423 && (
                                <>
                                  {" "}
                                  {
                                    emailInputs?.neuRaume?.attributes.strasse
                                  }{" "}
                                  {emailInputs?.neuRaume?.attributes.haus_nr}
                                  <br />
                                  {emailInputs?.neuRaume?.attributes.plz}{" "}
                                  {emailInputs?.neuRaume?.attributes.ort}
                                </>
                              )}
                            </div>
                          </>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-span-3">
                    <div>Inhalt der zu generierenden E-Mails</div>
                    <div
                      onChange={(e) =>
                        setEmailInputs((prior) => ({
                          ...prior,
                          reason: e.target.value,
                        }))
                      }
                      className="mt-2 gap-y-3 flex flex-col place-content-evenly w-full p-3 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 shadow-sm outline-none"
                    >
                      {sendEmail !== "paymentreminder" ? (
                        <>
                          {activeParticipantCheck && (
                            <>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="teilnahmebest"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Teilnahmebestätigung an Teilnehmer
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="referenten"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Termingründe des Referenten
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="erkrankung"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Absage wegen Erkrankung des Referenten
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="neuraum"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Neuer Seminarraum
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="sonst"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Sonstige Gründe
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="zuwenig"
                                  onClick={() => setTarget("participant")}
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Zu wenige Teilnehmer
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="zertifikat_nachsendung"
                                  onClick={() =>
                                    setTarget("participant_certificate")
                                  }
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Nachsendung Zertifikat
                                </div>
                              </div>
                            </>
                          )}
                          {sendEmail === "genall" && (
                            <>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="bestdurchreg"
                                  onClick={() => setTarget("referent")}
                                />
                                <div className="ml-3 text-xs font-medium text-green-600">
                                  Bestätigung über Seminardurchführung an
                                  Referenten
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="infoanrefsemabsage"
                                  onClick={() => setTarget("referent")}
                                />
                                <div className="ml-3 text-xs font-medium text-red-600">
                                  Information an Referent über Seminarabsage
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {invoiceInputs?.attributes?.status === "storno" ? (
                            <div className="flex items-center">
                              <input
                                name="persoenlich"
                                type="radio"
                                value="storno_rechnung"
                                onClick={() => setTarget("kunde")}
                              />
                              <div className="ml-3 text-xs font-medium text-blue-600">
                                Storno Rechnung
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="paymentreminderparticipantyes"
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Zahlungserinnerung mit Teilnahme
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  name="persoenlich"
                                  type="radio"
                                  value="paymentreminderparticipantno"
                                />
                                <div className="ml-3 text-xs font-medium text-gray-900">
                                  Zahlungserinnerung ohne Teilnahme
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-span-7 flex flex-col ">
                    <span className="">Subject:</span>
                    <input
                      onChange={(e) =>
                        setEmailInputs((current) => ({
                          ...current,
                          subject: e.target.value,
                        }))
                      }
                      value={emailInputs?.subject}
                      name=""
                      className="mt-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                      variant="outlined"
                    />
                    <span className="mt-4">Email:</span>
                    <div>
                      <HTMLEditor
                        value={emailInputs?.body || ""}
                        onChange={(e) => {
                          setEmailInputs((current) => ({
                            ...current,
                            body: e,
                          }));
                        }}
                        readOnly={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 py-3 px-6 flex justify-between items-center">
              <div className="flex w-1/2 items-center">
                <CloudUploadIcon
                  color={selectedFiles.length > 0 ? "primary" : "black"}
                  onClick={() => setFileSelector(true)}
                />

                <input
                  className="hidden"
                  onChange={handleFileEvent}
                  id="fileUpload"
                  type="file"
                  multiple
                />

                <label className="pl-2 cursor-pointer" htmlFor="fileUpload">
                  <AttachFileIcon />
                </label>
                <div className="flex">
                  {uploadedFiles.map((file, ind) => (
                    <div className="pl-4" key={ind}>
                      {file.name}
                    </div>
                  ))}
                </div>
                {uploadedFiles.length > 0 && (
                  <div className="cursor-pointer pl-4">
                    <DeleteForeverIcon onClick={() => setUploadedFiles([])} />
                  </div>
                )}
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    setUploadedFiles([]);
                    setSendEmail(false);
                    setEmailInputs({});
                    setSelectedFiles([]);
                    setTarget("participants");
                  }}
                  className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                >
                  {"Abbrechen"}
                </button>
                <button
                  disabled={
                    emailInputs?.subject &&
                    emailInputs?.body &&
                    loadingDyn === false
                      ? false
                      : true
                  }
                  name="sendemail"
                  onClick={(e) => {
                    setLoadingDyn(e.target.name);
                    emailInvoiceHandler("sendrawemail", sendEmail, target);
                    // e.preventDefault();
                  }}
                  className={`
                bg-primary-900 disabled:opacity-40
                   ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                >
                  {"OK - E-mail-Erzeugen"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={fileSelector}
          aria-describedby="modal-modal-description"
          onClose={() => {
            setFileSelector(false);
          }}
        >
          <Box
            sx={{ overflowY: "scroll", maxHeight: "100%" }}
            className="customized-scrollbar h-screen w-screen p-4"
          >
            <div className="p-4 h-full flex items-center justify-center">
              <div className="p-5 bg-white relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-full overflow-auto max-h-full customized-scrollbar">
                <Fileviewer
                  setFileSelector={setFileSelector}
                  mode="fileselector"
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />{" "}
              </div>
            </div>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default EmailModal;
