import React, { useRef, useMemo, useState, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";

import AG_GRID_LOCALE_DE from "./locale.de";

import { DateRangePicker } from "@mantine/dates";

import CircularProgress from "@mui/material/CircularProgress";

import MultiSelect from "./partials/MultiSelect";

const SeminarConfirmedGrid = ({
  query,
  setQuery,
  loadingDyn,
  filterHandler,
  referentSelection,
  seminarQueryResults,
  exportEmailHandler,
  selectedReferent,
  setSelectedReferent,
  selectedSeminarIds,
  setSelectedSeminarIds,
  seminarsToTag,
  setSeminarsToTag,
  setSelectedEmails,
  selectedEmails,
  emailList,
  selectedEmailList,
  setSelectedEmailList,
  taggedSeminars,
  emailQueryResults,
  resetState,
}) => {
  const [getEligibleEmails, setEligibleEmails] = useState([]);
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const gridRefSeminar = useRef();
  const gridRefSelectedSeminar = useRef();
  const gridRefSelectedEmails = useRef();

  const convertIsoToLocal = (params) => {
    if (params.value !== null) {
      let isoDate = params.value;

      var d = new Date(isoDate);
      return d.toLocaleDateString("de-DE");
    } else {
      return "";
    }
  };

  const columnSeminarDefs = useMemo(
    () => [
      {
        width: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <div className="flex h-full items-center">
              <input
                type="checkbox"
                defaultChecked={seminarsToTag.some(
                  (invid) => params.data.id === invid
                )}
              />
            </div>
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: true,
      },
      {
        field: "thema",
        headerName: "Thema",
        sortable: true,
        width: 350,
        headerClass: "header-blue",
      },
      {
        field: "datum",
        headerName: "Datum",
        sortable: true,
        width: 110,
        headerClass: "header-blue",
        cellRenderer: (params) => convertIsoToLocal(params),
      },
      {
        field: "id_raum.ort",
        headerName: "Ort",
        sortable: true,
        width: 140,
        headerClass: "header-blue",
      },
      {
        headerName: "Teilnehmeranzahl",
        sortable: true,
        headerClass: "header-blue",
        cellRenderer: (params) => {
          return params?.data?.participant_data?.length;
        },
      },
    ],
    [seminarsToTag]
  );

  const columnSelectedSeminarDefs = useMemo(
    () => [
      {
        width: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <div className="flex h-full items-center">
              <input
                type="checkbox"
                defaultChecked={selectedSeminarIds.some(
                  (invid) => params.data.id === invid
                )}
              />
            </div>
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: true,
      },
      {
        field: "thema",
        width: 350,
        headerName: "Thema",
        sortable: true,
        headerClass: "header-blue",
      },
      {
        field: "datum",
        headerName: "Datum",
        sortable: true,
        width: 110,
        headerClass: "header-blue",
        cellRenderer: (params) => convertIsoToLocal(params),
      },
      {
        field: "id_raum.ort",
        headerName: "Ort",
        sortable: true,
        width: 140,
        headerClass: "header-blue",
      },
      {
        headerName: "Teilnehmeranzahl",
        sortable: true,
        headerClass: "header-blue",
        cellRenderer: (params) => {
          return params?.data?.participant_data?.length;
        },
      },
    ],
    [selectedSeminarIds]
  );

  const columnSelectedEmails = useMemo(
    () => [
      {
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <div className="flex h-full items-center">
              <input
                type="checkbox"
                defaultChecked={selectedEmails.some(
                  (invid) => params.data.email === invid.email
                )}
              />
            </div>
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: true,
      },
      {
        field: "vorname",
        headerName: "Vorname",
        sortable: true,
        width: 120,
        headerClass: "header-blue",
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        width: 120,
        headerClass: "header-blue",
      },
      {
        field: "email",
        headerName: "Email Address",
        width: 350,
        sortable: true,
        headerClass: "header-blue",
      },
    ],
    [selectedEmails]
  );

  useEffect(() => {
    if (
      emailQueryResults.length === 0 &&
      taggedSeminars.length > 0 &&
      selectedSeminarIds.length > 0
    ) {
      const emails = taggedSeminars
        .filter((seminar) => selectedSeminarIds.includes(seminar.id))
        .flatMap((seminar) => seminar.participant_data);

      // ensure participant data exists, filter out duplicates based on id_teilnehmer.email and if id_teilnehmer.email_opt_out is false or null

      const uniqueEmails = emails
        .filter(
          (email) =>
            email?.id_teilnehmer?.email !== null &&
            email?.id_teilnehmer?.email !== undefined &&
            email?.id_teilnehmer?.email !== ""
        )
        .filter(
          (email, index, self) =>
            self.findIndex(
              (t) => t?.id_teilnehmer?.email === email?.id_teilnehmer?.email
            ) === index
        )
        .filter((email) => email?.id_teilnehmer?.email_opt_out !== true)
        .map((email) => {
          return {
            email: email?.id_teilnehmer?.email,
            vorname: email?.id_teilnehmer?.vorname,
            name: email?.id_teilnehmer?.name,
            geschlecht: email?.id_teilnehmer?.geschlecht || "",
          };
        });

      setEligibleEmails(uniqueEmails);
    } else if (
      emailQueryResults.length > 0 &&
      selectedSeminarIds.length === 0 &&
      taggedSeminars.length === 0
    ) {
      setEligibleEmails(emailQueryResults);
    } else {
      setEligibleEmails([]);
    }
  }, [taggedSeminars, selectedSeminarIds, emailQueryResults]);

  return (
    <>
      <div className="col-span-12 flex space-x-4">
        <div className="w-1/3 flex">
          <div className="whitespace-nowrap flex items-center mr-5">
            Auswahlfeld Referenten:
          </div>
          <div className="relative h-full w-full">
            <MultiSelect
              options={referentSelection}
              selectedReferent={selectedReferent}
              setSelectedReferent={setSelectedReferent}
              resetState={resetState}
            />
          </div>
        </div>
        <div className="w-1/3 flex relative h-9">
          <DateRangePicker
            classNames={{ input: "placeholder:text-black" }}
            placeholder="Datumsbereich auswählen"
            inputFormat="DD/MM/YYYY"
            className="w-full p-1 relative border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="unstyled"
            clearable={true}
            value={query.daterange}
            onChange={(newValue) => {
              resetState("daterange");
              setQuery((prior) => ({
                ...prior,
                daterange: newValue,
              }));
            }}
          />
        </div>
        <div className="w-1/3 flex relative h-9">
          <input
            type="text"
            name="search"
            id="search"
            className="w-full p-1 relative border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            placeholder="Suche nach Email Adresse"
            value={query.emailsearch}
            onChange={(event) => {
              resetState("emailsearch");
              setQuery((prior) => ({
                ...prior,
                emailsearch: event.target.value,
              }));
            }}
          />
        </div>
        <div className="ml-5 flex h-9">
          {" "}
          <button
            disabled={selectedReferent.length === 0 && query.emailsearch === ""}
            name="search"
            className={`${
              loadingDyn === "search"
                ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                : "bg-primary-900 disabled:opacity-25"
            } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            onClick={(event) => {
              filterHandler(
                event,
                selectedReferent.map((ref) => ref.id)
              );
            }}
          >
            Suchen
            {loadingDyn === "search" && (
              <CircularProgress
                size={24}
                sx={{
                  color: "",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </button>
        </div>
      </div>
      <div className="col-span-10 grid gap-4">
        <div className="ag-theme-alpine w-full h-[350px]">
          <div className="text-lg pb-1">Alle Seminare</div>
          <AgGridReact
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowHeight={33}
            rowData={seminarQueryResults.filter(
              (seminar) => !seminar.tagged_for_email
            )}
            columnDefs={columnSeminarDefs}
            rowSelection={"multiple"}
            rowMultiSelectWithClick
            ref={gridRefSeminar}
            localeText={localeText}
            // paginationPageSize={14}
            // pagination={true}
            // onGridSizeChanged={(e) => {
            //   e.api.sizeColumnsToFit();
            // }}
            onSelectionChanged={(event) => {
              setSeminarsToTag(
                event.api.getSelectedNodes().map((node) => node.data.id)
              );
            }}
          ></AgGridReact>
        </div>
        <div className="pt-8">
          {" "}
          <button
            name="addtags"
            disabled={seminarsToTag.length === 0}
            onClick={(event) => {
              filterHandler(
                event,
                selectedReferent.map((ref) => ref.id),
                "tag",
                seminarsToTag
              );
            }}
            className={`${
              loadingDyn === "addtags"
                ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                : "bg-primary-900 disabled:opacity-25"
            } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
          >
            {"Seminare markieren"}
            {loadingDyn === "addtags" && (
              <CircularProgress
                size={24}
                sx={{
                  color: "",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </button>
        </div>
        <div className="ag-theme-alpine h-[350px]">
          <div className="text-lg pb-1">Markierte Seminare</div>
          <AgGridReact
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowHeight={33}
            rowData={taggedSeminars || []}
            columnDefs={columnSelectedSeminarDefs}
            rowSelection={"multiple"}
            rowMultiSelectWithClick
            ref={gridRefSelectedSeminar}
            localeText={localeText}
            onSelectionChanged={(event) => {
              setSelectedSeminarIds(
                event.api.getSelectedNodes().map((node) => node.data.id)
              );
            }}
          ></AgGridReact>
        </div>
        <div className="pt-8">
          <button
            name="removetags"
            disabled={selectedSeminarIds.length === 0}
            onClick={(event) => {
              filterHandler(
                event,
                selectedReferent.map((ref) => ref.id),
                "removetag",
                selectedSeminarIds
              );
            }}
            className={`${
              loadingDyn === "removetags"
                ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                : "bg-primary-900 disabled:opacity-25"
            } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
          >
            {"Seminar-Tags entfernen"}
            {loadingDyn === "removetags" && (
              <CircularProgress
                size={24}
                sx={{
                  color: "",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </button>
        </div>
      </div>
      <div className="col-span-2 flex flex-col gap-4">
        <div className="ag-theme-alpine h-[350px]">
          <div className="text-lg pb-1">E-mailadressen</div>
          <AgGridReact
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowHeight={33}
            rowData={getEligibleEmails}
            columnDefs={columnSelectedEmails}
            rowSelection={"multiple"}
            rowMultiSelectWithClick
            ref={gridRefSelectedEmails}
            localeText={localeText}
            // paginationPageSize={14}
            // pagination={true}
            // onGridSizeChanged={(e) => {
            //   e.api.sizeColumnsToFit();
            // }}
            onSelectionChanged={(event) => {
              setSelectedEmails(
                event.api.getSelectedNodes().map((node) => node.data)
              );
            }}
          ></AgGridReact>
        </div>
        <div className="pt-8">
          {" "}
          <div className="flex">
            {" "}
            <span className="whitespace-nowrap p-2">E-Mail-Listen:</span>
            <div
              className={`w-full p-2 outline-none sm:text-sm  border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                    } `}
            >
              <select
                value={selectedEmailList}
                onChange={(e) => {
                  setSelectedEmailList(e.target.value);
                }}
                className="w-full background-transparant border-0 sm:text-sm focus-within:ring-0 p-0 bg-transparent"
              >
                <option value={0} disabled>
                  Wählen Sie Ein Email List
                </option>
                {emailList.map((value) => (
                  <option value={value.id} key={value.id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <button
              name="exportlist"
              disabled={selectedEmails.length === 0}
              onClick={(e) => {
                e.preventDefault();
                exportEmailHandler(selectedEmails, e.target.name);
              }}
              className={`${
                loadingDyn === "exportlist"
                  ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                  : "bg-primary-900 disabled:opacity-25"
              } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
            >
              {"Export Teilnehmer List (csv)"}
              {loadingDyn === "exportlist" && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </button>
            <button
              name="addtolist"
              disabled={selectedEmailList === 0 || selectedEmails.length === 0}
              onClick={(e) => {
                e.preventDefault();
                exportEmailHandler(
                  selectedEmails,
                  e.target.name,
                  selectedEmailList
                );
              }}
              className={`${
                loadingDyn === "addtolist"
                  ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                  : "bg-primary-900 disabled:opacity-25"
              } ml-4 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
            >
              {"E-mail Liste Hinzufügen"}
              {loadingDyn === "addtolist" && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </button>
          </div>
          <div>
            {" "}
            <button
              name="removefromlist"
              disabled={selectedEmails.length === 0}
              onClick={(e) => {
                e.preventDefault();
                exportEmailHandler(selectedEmails, e.target.name);
              }}
              className={`${
                loadingDyn === "removefromlist"
                  ? "bg-white hover:bg-white disabled:ring-red-500 disabled:ring-offset-2 disabled:ring-2"
                  : "bg-red-500 disabled:opacity-25"
              } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-red-300 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-red-500`}
            >
              {"Email Opt-Out"}
              {loadingDyn === "removefromlist" && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeminarConfirmedGrid;
