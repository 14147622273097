import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";
import { axiosRaumeRoute } from "../helpers/axios";
import EditIcon from "@mui/icons-material/Edit";
import ContentConpyIcon from "@mui/icons-material/ContentCopy";
import Fuse from "fuse.js";

import { validateMe } from "../helpers/validation";

import { AgGridReact } from "ag-grid-react";
import RaumeModal from "./RaumeModal";

import AG_GRID_LOCALE_DE from "./locale.de";
import parse from "html-react-parser";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import listofGermanStates from "./partials/listofGermanStates";

const Raume = () => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const [raumeQueryResults, setRaumeQueryResults] = useState([]);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const {
    token,
    notify,
    raumeSelection,
    getData,
    errorHandling,
    setErrorHandling,
  } = useOutletContext();

  const [raumeInputs, setRaumeInputs] = useState({});
  const [formErrorHandling, setFormErrorHandling] = useState(null);

  const [onlyActiveRaum, setOnlyActiveRaum] = useState(true);

  const [loading, setLoading] = useState(false);

  const currentRaumeHandler = (e) => {
    const id = Number(e.target.value);

    const filterRaumeById = raumeSelection.filter(
      (raume) => raume.id === id
    )[0];

    setRaumeInputs({ ...filterRaumeById.attributes, id: filterRaumeById.id });
  };

  const handleChange = (value, name, type) => {
    setRaumeInputs((values) => ({
      ...values,
      [name]: value,
      ...(name === "bundesland" && {
        bundesland_id: listofGermanStates.find(
          (state) => state.province === value
        ).id,
      }),
    }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  const formHandler = async (event, open) => {
    event.preventDefault();
    setLoading(true);
    try {
      let result;

      const data = {
        ...raumeInputs,
      };

      if (open === "edit") {
        result = await axiosRaumeRoute.put(
          `/${raumeInputs.id}`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (open === "add" || open === "copy") {
        const { id, ...noid } = raumeInputs;

        result = await axiosRaumeRoute.post(
          "/",
          {
            data: {
              ...(open === "copy" ? noid : raumeInputs),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (result?.data) {
        notify(
          "info",
          `${open === "edit" ? "Aktualisiert" : "Hinzugefügt"} Raume`
        );
        setOpen(false);
        setLoading(false);
        setRaumeInputs({});
        setErrorHandling({});
        getData();
      }
    } catch (err) {
      setFormErrorHandling(err.response.data.error.details.errors);
      console.log(err.response.data.error);
      setLoading(false);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "edit",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: () => (
          <EditIcon
            fontSize="small"
            className="cursor-pointer flex h-full"
            onClick={() => setOpen("edit")}
          />
        ),
      },
      {
        field: "copy",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: () => (
          <ContentConpyIcon
            fontSize="small"
            className="cursor-pointer flex h-full"
            onClick={() => setOpen("copy")}
          />
        ),
      },
      {
        field: "ort",
        headerName: "Ort",
        sortable: true,
        sort: "asc",
        headerClass: "header-blue",
        width: 190,
        resizable: true,
      },
      {
        field: "bezeichnung",
        headerName: "Raumauswahl",
        sortable: true,

        width: 300,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "is_aktiv",
        headerName: "Aktiv",
        sortable: true,
        width: 70,
        minWidth: 70,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: (params) => <div>{params.value ? "Ja" : "Nein"}</div>,
        hide: onlyActiveRaum,
      },
      {
        field: "bundesland",
        headerName: "Bundesland",
        sortable: true,

        headerClass: "header-blue",
        width: 190,
        resizable: true,
      },
      {
        field: "plz",
        headerName: "PLZ",
        sortable: true,

        width: 85,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "telefon",
        headerName: "Telefon",
        sortable: true,

        width: 200,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email",
        headerName: "E-Mail",
        sortable: true,

        width: 230,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "infotext",
        headerName: "Info",
        sortable: true,

        width: 200,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: ({ value }) => (
          <div>{parse(`${value === null ? "" : value}`)}</div>
        ),
      },
    ],
    [onlyActiveRaum]
  );

  const gridRef = useRef();
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedRaume = selectedRows.length === 1 ? selectedRows[0] : "";
    setRaumeInputs(selectedRaume);
  }, []);

  useEffect(() => {
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: [
        "attributes.bezeichnung",
        "attributes.ort",
        "attributes.bundesland",
        "attributes.createdAt",
      ],
    };
    const fuse = new Fuse(raumeSelection, options);
    if (raumeSelection?.length > 0) {
      setRaumeQueryResults(
        fuse.search(
          query !== ""
            ? {
                $or: [
                  {
                    $path: ["attributes.bezeichnung"],
                    $val: query,
                  },
                  {
                    $path: ["attributes.bundesland"],
                    $val: query,
                  },
                  {
                    $path: ["attributes.ort"],
                    $val: query,
                  },
                ],
              }
            : {
                "attributes.createdAt": "'2",
              }
        )
      );
    } else {
      setRaumeQueryResults([]);
    }

    // eslint-disable-next-line
  }, [query, raumeSelection]);

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  return (
    <main className="h-screen flex flex-col">
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Räumeverwaltung</h3>

          <button
            onClick={() => {
              setOpen("add");
            }}
            className="addButton"
          >
            Hinzufügen
          </button>
        </div>
      </div>
      <div className="w-full px-6 py-5 text-sm text-left font-medium text-gray-700 flex-1 flex flex-col">
        <div className="col-span-12 flex mb-4 justify-between">
          <div className="w-3/12 flex mr-5">
            <div className="whitespace-nowrap flex items-center">Suche:</div>
            <input
              className="ml-5 h-min w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              label="Nur aktiv"
              control={
                <Switch
                  checked={onlyActiveRaum}
                  onChange={() =>
                    setOnlyActiveRaum((prior) => (prior ? false : true))
                  }
                />
              }
            />
          </FormGroup>
        </div>
        <div className="ag-theme-alpine w-full h-full">
          {
            <AgGridReact
              getRowId={getRowId}
              enableCellTextSelection
              defaultColDef={{
                filter: false,
              }}
              rowHeight={33}
              // pagination
              // paginationPageSize={15}
              rowData={raumeQueryResults
                ?.filter((filterforactive) =>
                  onlyActiveRaum === false
                    ? true
                    : filterforactive.item.attributes.is_aktiv
                )
                .filter((raume) => raume.item.id !== 423)
                .map((raume) => ({
                  ...raume.item.attributes,
                  id: raume.item.id,
                }))}
              columnDefs={columnDefs}
              rowSelection={"single"}
              onRowClicked={onSelectionChanged}
              ref={gridRef}
              localeText={localeText}
              // onGridSizeChanged={(e) => {
              //   e.api.sizeColumnsToFit();
              // }}
            ></AgGridReact>
          }
        </div>
      </div>

      <RaumeModal
        validateMe={validateMe}
        open={open}
        setOpen={setOpen}
        raumeInputs={raumeInputs}
        setRaumeInputs={setRaumeInputs}
        handleChange={handleChange}
        listofGermanStates={listofGermanStates}
        raumeSelection={raumeSelection}
        currentRaumeHandler={currentRaumeHandler}
        formHandler={formHandler}
        formErrorHandling={formErrorHandling}
        setFormErrorHandling={setFormErrorHandling}
        loading={loading}
        errorHandling={errorHandling}
        setErrorHandling={setErrorHandling}
      />
    </main>
  );
};

export default Raume;
