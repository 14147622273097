// Example locale file for English, give this to your locale team to translate

const AG_GRID_LOCALE_DE = {
  // Filter setzen
  selectAll: "(Alle auswählen)",
  selectAllSearchResults: "(Alle Suchergebnisse auswählen)",
  searchOoo: "Suche...",
  Leerzeichen: "(Leerzeichen)",
  noMatches: "Keine Übereinstimmungen",

  // Zahlenfilter & Textfilter
  filterOoo: "Filter...",
  equals: "Gleich",
  notEqual: "Ungleich",
  blank: "Leer",
  notBlank: "Nicht leer",
  empty: "Wähle eine",

  // Zahlenfilter
  lessThan: "Kleiner als",
  greaterThan: "größer als",
  lessThanOrEqual: "Kleiner als oder gleich",
  greaterThanOrEqual: "Größer als oder gleich",
  inRange: "Im Bereich",
  inRangeStart: "von",
  inRangeEnd: "bis",

  // Textfilter
  contains: "Enthält",
  notContains: "Enthält nicht",
  startsWith: "Beginnt mit",
  endsWith: "Endet mit",

  // Datumsfilter
  dateFormatOoo: "yyyy-mm-dd",

  // Bedingungen filtern
  andCondition: "UND",
  orCondition: "ODER",

  // Filterschaltflächen
  applyFilter: "Übernehmen",
  resetFilter: "Zurücksetzen",
  clearFilter: "Löschen",
  cancelFilter: "Abbrechen",

  // Titel filtern
  textFilter: "Textfilter",
  numberFilter: "Zahlenfilter",
  dateFilter: "Datumsfilter",
  setFilter: "Filter setzen",

  // Seitenleiste
  columns: "Spalten",
  filters: "Filter",

  // Werkzeugleiste für Spalten
  pivotMode: "Pivot-Modus",
  groups: "Zeilengruppen",
  rowGroupColumnsEmptyMessage:
    "Ziehen Sie hierher, um Zeilengruppen festzulegen",
  values: "Werte",
  valueColumnsEmptyMessage: "Zum Aggregieren hierher ziehen",
  pivots: "Spaltenbeschriftungen",
  pivotColumnsEmptyMessage:
    "Ziehen Sie hierher, um Spaltenbeschriftungen festzulegen",

  // Kopfzeile der Standardgruppenspalte
  group: "Gruppe",

  // Zeilen ziehen
  rowDragRows: "Zeilen",

  // Sonstiges
  loadingOoo: "Wird geladen...",
  noRowsToShow: "Keine Zeilen zum Anzeigen",
  enabled: "Aktiviert",

  // Speisekarte
  pinColumn: "Pin-Spalte",
  pinLeft: "Pin links",
  pinRight: "Pin rechts",
  noPin: "Kein Pin",
  valueAggregation: "Wertaggregation",
  autosizeThiscolumn: "Diese Spalte automatisch anpassen",
  autosizeAllColumns: "Alle Spalten automatisch anpassen",
  groupBy: "Gruppieren nach",
  ungroupBy: "Gruppierung aufheben nach",
  // eslint-disable-next-line
  addToValues: "${variable} zu Werten hinzufügen",
  // eslint-disable-next-line
  removeFromValues: "${variable} aus Werten entfernen",
  // eslint-disable-next-line
  addToLabels: "${variable} zu Labels hinzufügen",
  // eslint-disable-next-line
  removeFromLabels: "${variable} aus Labels entfernen",
  resetColumns: "Spalten zurücksetzen",
  expandAll: "Alle erweitern",
  collapseAll: "Alle schließen",
  copy: "Kopieren",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "Mit Kopfzeilen kopieren",
  copyWithHeaderGroups: "Mit Header-Gruppen kopieren",
  paste: "Einfügen",
  ctrlV: "Ctrl+V",
  export: "Exportieren",
  csvExport: "CSV-Export",
  excelExport: "Excel-Export",

  // Unternehmensmenüaggregation und Statusleiste
  sum: "Summe",
  min: "Min",
  max: "maximal",
  none: "Keine",
  count: "Zählen",
  avg: "Durchschnitt",
  filteredRows: "Gefiltert",
  selectedRows: "Ausgewählt",
  totalRows: "Gesamtzeilen",
  totalAndFilteredRows: "Zeilen",
  more: "mehr",
  to: "zu",
  of: "von",
  page: "Seite",
  nextPage: "Nächste Seite",
  lastPage: "Letzte Seite",
  firstPage: "Erste Seite",
  previousPage: "Vorherige Seite",

  // Schwenken
  pivotColumnGroupTotals: "Gesamt",

  // Unternehmensmenü (Charts)
  pivotChartAndPivotMode: "Pivot-Diagramm & Pivot-Modus",
  pivotChart: "Pivot-Diagramm",
  chartRange: "Diagrammbereich",

  columnChart: "Spalte",
  groupedColumn: "Gruppiert",
  stackedColumn: "Gestapelt",
  normalizedColumn: "100 % gestapelt",

  barChart: "Balken",
  groupedBar: "Gruppiert",
  stackedBar: "Gestapelt",
  normalizedBar: "100 % gestapelt",

  pieChart: "Kuchen",
  pie: "Kuchen",
  doughnut: "Krapfen",

  line: "Linie",

  xyChart: "X Y (Scatter)",
  scatter: "Streuung",
  bubble: "Blase",

  areaChart: "Fläche",
  area: "Bereich",
  StackedArea: "Gestapelt",
  normalizedArea: "100 % gestapelt",

  histogramChart: "Histogramm",

  combinationChart: "Kombination",
  columnLineCombo: "Spalte & Zeile",
  AreaColumnCombo: "Fläche & Spalte",

  pivotChartTitle: "Pivot Chart",
  rangeChartTitle: "Range Chart",
  settings: "Settings",
  data: "Data",
  format: "Format",
  categories: "Categories",
  defaultCategory: "(None)",
  series: "Series",
  xyValues: "X Y Values",
  paired: "Paired Mode",
  axis: "Axis",
  navigator: "Navigator",
  color: "Color",
  thickness: "Thickness",
  xType: "X Type",
  automatic: "Automatic",
  category: "Category",
  number: "Number",
  time: "Time",
  xRotation: "X Rotation",
  yRotation: "Y Rotation",
  ticks: "Ticks",
  width: "Width",
  height: "Height",
  length: "Length",
  padding: "Padding",
  spacing: "Spacing",
  chart: "Chart",
  title: "Title",
  titlePlaceholder: "Chart title - double click to edit",
  background: "Background",
  font: "Font",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  labels: "Labels",
  size: "Size",
  minSize: "Minimum Size",
  maxSize: "Maximum Size",
  legend: "Legend",
  position: "Position",
  markerSize: "Marker Size",
  markerStroke: "Marker Stroke",
  markerPadding: "Marker Padding",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "Horizontal Spacing",
  layoutVerticalSpacing: "Vertical Spacing",
  strokeWidth: "Stroke Width",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  normal: "Normal",
  bold: "Bold",
  italic: "Italic",
  boldItalic: "Bold Italic",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeOpacity: "Line Opacity",
  histogramBinCount: "Bin count",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  histogramGroup: "Histogram",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  doughnutTooltip: "Doughnut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  columnLineComboTooltip: "Column & Line",
  areaColumnComboTooltip: "Area & Column",
  customComboTooltip: "Custom Combination",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",
  seriesChartType: "Series Chart Type",
  seriesType: "Series Type",
  secondaryAxis: "Secondary Axis",

  // ARIA
  ariaHidden: "hidden",
  ariaVisible: "visible",
  ariaChecked: "checked",
  ariaUnchecked: "unchecked",
  ariaIndeterminate: "indeterminate",
  ariaDefaultListName: "List",
  ariaColumnSelectAll: "Toggle Select All Columns",
  ariaInputEditor: "Input Editor",
  ariaDateFilterInput: "Date Filter Input",
  ariaFilterList: "Filter List",
  ariaFilterInput: "Filter Input",
  ariaFilterColumnsInput: "Filter Columns Input",
  ariaFilterValue: "Filter Value",
  ariaFilterFromValue: "Filter from value",
  ariaFilterToValue: "Filter to value",
  ariaFilteringOperator: "Filtering Operator",
  ariaColumn: "Column",
  ariaColumnList: "Column List",
  ariaColumnGroup: "Column Group",
  ariaRowSelect: "Press SPACE to select this row",
  ariaRowDeselect: "Press SPACE to deselect this row",
  ariaRowToggleSelection: "Press Space to toggle row selection",
  ariaRowSelectAll: "Press Space to toggle all rows selection",
  ariaToggleVisibility: "Press SPACE to toggle visibility",
  ariaSearch: "Search",
  ariaSearchFilterValues: "Search filter values",

  ariaRowGroupDropZonePanelLabel: "Row Groups",
  ariaValuesDropZonePanelLabel: "Values",
  ariaPivotDropZonePanelLabel: "Column Labels",
  ariaDropZoneColumnComponentDescription: "Press DELETE to remove",
  ariaDropZoneColumnValueItemDescription:
    "Press ENTER to change the aggregation type",

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: "Column Menu",
  ariaLabelCellEditor: "Cell Editor",
  ariaLabelDialog: "Dialog",
  ariaLabelSelectField: "Select Field",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Context Menu",
  ariaLabelSubMenu: "SubMenu",
  ariaLabelAggregationFunction: "Aggregation Function",

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",
};

export default AG_GRID_LOCALE_DE;
