import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import { AgGridReact } from "ag-grid-react";
import Fuse from "fuse.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

// import sanitizeHtml from "sanitize-html";
// import { Editor } from "@tinymce/tinymce-react";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import CircularProgress from "@mui/material/CircularProgress";

import InputAdornment from "@mui/material/InputAdornment";

// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";

// import Chip from "@mui/material/Chip";

// import Select from "@mui/material/Select";

import deLocale from "date-fns/locale/de";

import AG_GRID_LOCALE_DE from "./locale.de";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { CheckIcon } from "@heroicons/react/solid";

import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import DeleteIcon from "@mui/icons-material/Delete";

import { DateTime } from "luxon";

import { HTMLEditor } from "./partials/HTMLEditor";
export default function SeminarAddModal({
  open,
  setOpen,
  seminarInputs,
  handleSeminarChange,
  raumeSelection,
  updateSeminarHandler,
  setSeminarInputs,
  formErrorHandling,
  setFormErrorHandling,
  setSeminarReferentListInputs,
  loading,
  dateValidation,
  rangeValidation,
  referentSelection,
  setCurrentSection,
  currentSection,
  referenteArray,
  setReferenteArray,
  referenteAddData,
  setReferenteAddData,
  wordPressAudienceOptions,
}) {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const [query, setQuery] = useState({ bezeichnung: "" });
  const [raumeQueryResults, setRaumeQueryResults] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [firstRun, setFirstRun] = useState(true);

  useEffect(
    () =>
      open === "add" &&
      setSeminarInputs({
        wp_published: true,
        preis: "",
        thema: "",
        max_teilnehmer: 0,
        anzahl_skripten: 0,
        max_tn_zahl: 0,
        id_raum: 423,
        audiences: "",
        anfangs_zeit: DateTime.utc(2023, 1, 1, 9, 0),
        end_zeit: DateTime.utc(2023, 1, 1, 9, 0),
      }),
    [open, setSeminarInputs]
  );

  const handlereferenteAddData = (value, name) => {
    setReferenteAddData((values) => ({ ...values, [name]: value }));
  };

  const currentreferenteAddData = (e) => {
    const id = Number(e);

    const filterReferentById = referentSelection?.filter(
      (referent) => referent.id === id
    )[0];

    setReferenteAddData((current) => ({
      ...current,
      ...filterReferentById.attributes,
      id_referent: filterReferentById.id,
    }));
  };

  const createReferenteArray = () => {
    setReferenteArray((current) => [...current, referenteAddData]);
    setReferenteAddData({ ist_mehrwert_steuer: true });
  };

  useEffect(() => {
    const options = {
      includeScore: false,
      useExtendedSearch: true,
      keys: ["attributes.bezeichnung", "attributes.ort"],
    };

    const ramueFilteredActive = raumeSelection.filter(
      (filterforactive) => filterforactive.attributes.is_aktiv !== false
    );
    const fuse = new Fuse(ramueFilteredActive, options);
    if (ramueFilteredActive?.length > 0) {
      let result;
      if (query.bezeichnung !== "") {
        result = fuse.search({
          $or: [
            ...(query.bezeichnung && [
              {
                $path: ["attributes.bezeichnung"],
                $val: query.bezeichnung,
              },
            ]),
            ...(query.bezeichnung && [
              {
                $path: ["attributes.ort"],
                $val: query.bezeichnung,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);

        setRaumeQueryResults(destructitems.filter((raume) => raume.id !== 423));
      } else {
        setRaumeQueryResults(
          ramueFilteredActive.filter((raume) => raume.id !== 423)
        );
      }
    }

    // eslint-disable-next-line
  }, [query, raumeSelection]);

  const gridRefSeminar = useRef();
  const onRaumeSelectionChanged = useCallback(
    (event) => {
      if (event.node.isSelected()) {
        event.node.setSelected(false);
        handleSeminarChange(null, "id_raum");
        event.api.onFilterChanged();
      } else {
        event.node.setSelected(true, true);
        handleSeminarChange(event.data.id, "id_raum");
      }
    },
    [handleSeminarChange]
  );

  useEffect(() => {
    if (seminarInputs.id_raum === 423 && gridRefSeminar?.current?.api) {
      gridRefSeminar.current.api.deselectAll();
    }
  }, [seminarInputs.id_raum, gridRefSeminar]);

  useEffect(() => {
    if (seminarInputs.id_raum !== null && gridRefSeminar?.current?.api) {
      gridRefSeminar.current.api.onFilterChanged();
    }
  }, [seminarInputs, gridRefSeminar]);

  const [columnSeminarDefs] = useState([
    {
      checkboxSelection: true,
      width: 50,
      cellStyle: { pointerEvents: "none" },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bezeichnung",
      headerName: "Räume",
      sortable: true,
      width: 190,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.ort",
      headerName: "Ort",
      sortable: true,
      width: 190,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bundesland",
      headerName: "Bundesland",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.ansprechpartner",
      headerName: "Ansprechpartner",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const isExternalFilterPresent = useCallback(() => {
    return (
      seminarInputs.hasOwnProperty("id_raum") && seminarInputs.id_raum !== null
    );
  }, [seminarInputs]);

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const [referentQuery, setReferentQuery] = useState("");

  const refeentenFiltered = referentSelection?.filter((referent) =>
    referenteArray.some((semref) => referent.id === semref.id_referent)
      ? false
      : true
  );

  const refeentenFilteredActive = refeentenFiltered.filter(
    (referent) => referent.attributes.ist_aktuell === true
  );

  const filteredPeople =
    referentQuery === ""
      ? refeentenFilteredActive
      : refeentenFilteredActive.filter(
          (person) =>
            (person.attributes.name + " " + person.attributes.vorname)
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(referentQuery.toLowerCase().replace(/\s+/g, "")) //|| person.attributes.vorname.toLowerCase().replace(/\s+/g, '').includes(referentQuery.toLowerCase().replace(/\s+/g, ''))
        );

  const choosenSachbeieten = useCallback(
    (text) => {
      // 1. Strip HTML tags from focusAreas using a regular expression.

      const tokenizeAndSanitize = (text) => {
        if (!text) return [];
        text = text
          .replace(/<[^>]*>?/gm, "") // Remove HTML tags
          .split(/\s+/)
          .map((keyword) => keyword.replace(/[.,/#$%^&*;:{}=\-`~()'"?]/g, "")) // Remove punctuation
          .map((keyword) => keyword.trim()) // Remove whitespace around the keyword
          .map((keyword) => keyword.replace(/[0-9]/g, "")) // Remove numbers
          .map((keyword) => keyword.toLowerCase()) // Convert to lowercase
          .map((keyword) => keyword.replace(/\s\s+/g, " ").replace(/[„“]/g, "")) // remove single and double quotes
          .filter((keyword) => keyword); // Remove empty strings

        return text;
      };

      let keywords = [];

      keywords = tokenizeAndSanitize(text);

      // 2. Define a list of typical German stop words.
      const germanStopWords = [
        "aber",
        "alle",
        "als",
        "am",
        "an",
        "auch",
        "auf",
        "aus",
        "bei",
        "bin",
        "bis",
        "bist",
        "da",
        "dadurch",
        "daher",
        "darum",
        "das",
        "dass",
        "denn",
        "der",
        "des",
        "dem",
        "die",
        "doch",
        "dort",
        "du",
        "durch",
        "ein",
        "eine",
        "einem",
        "einen",
        "einer",
        "eines",
        "er",
        "es",
        "euer",
        "eure",
        "für",
        "hat",
        "hatte",
        "hatten",
        "hier",
        "hinter",
        "ich",
        "ihr",
        "ihre",
        "im",
        "in",
        "ist",
        "ja",
        "jede",
        "jedem",
        "jeden",
        "jeder",
        "jedes",
        "jener",
        "jenes",
        "jetzt",
        "kann",
        "kein",
        "keine",
        "können",
        "könnte",
        "machen",
        "man",
        "manche",
        "manchem",
        "manchen",
        "mancher",
        "manches",
        "mein",
        "meine",
        "mit",
        "muß",
        "mußt",
        "musst",
        "nach",
        "nicht",
        "nun",
        "nur",
        "ob",
        "oder",
        "ohne",
        "sehr",
        "sein",
        "seine",
        "sich",
        "sie",
        "sind",
        "so",
        "solche",
        "solchem",
        "solchen",
        "solcher",
        "solches",
        "soll",
        "sollte",
        "sondern",
        "sonst",
        "über",
        "um",
        "und",
        "uns",
        "unse",
        "unsem",
        "unsen",
        "unser",
        "unses",
        "unter",
        "viel",
        "vom",
        "von",
        "vor",
        "während",
        "war",
        "waren",
        "warst",
        "was",
        "weg",
        "weil",
        "weiter",
        "welche",
        "welchem",
        "welchen",
        "welcher",
        "welches",
        "wenn",
        "wer",
        "werde",
        "werden",
        "wie",
        "wieder",
        "will",
        "wir",
        "wird",
        "wirst",
        "wo",
        "wollen",
        "wollte",
        "würde",
        "würden",
        "zu",
        "zum",
        "zur",
        "zwar",
        "zwischen",
      ];

      // 3. Initialize the Fuse instance with options.
      const options = {
        includeScore: false,
        useExtendedSearch: true,
        keys: ["name"],
        distance: 0,
        threshold: 0.2,
      };

      const matches = [];

      const fuse = new Fuse(wordPressAudienceOptions, options);

      // 5. Search for WordPress audience options that match the keywords after excluding stop words.
      keywords.forEach((keyword) => {
        // Check if the keyword is not a stop word
        if (!germanStopWords.includes(keyword.toLowerCase())) {
          const result = fuse.search(keyword);

          matches.push(...result);
        }
      });

      // 6. Remove duplicates from the matches array.

      const uniqueMatches = matches.filter(
        (match, index, self) =>
          index === self.findIndex((m) => m.item.id === match.item.id)
      );

      const results = uniqueMatches.map((match) => match.item.name).join(",");

      handleSeminarChange(results === "" ? null : results, "audiences");
    },
    [wordPressAudienceOptions, handleSeminarChange]
  );

  useEffect(() => {
    if (seminarInputs.focus_areas && firstRun) {
      choosenSachbeieten(seminarInputs.focus_areas);
      setFirstRun(false);
    }
  }, [seminarInputs, choosenSachbeieten, firstRun]);

  return (
    <Modal
      open={open === "copy" || open === "add"}
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ overflowY: "scroll" }} className="customized-scrollbar">
        <div
          id="modal-modal-description"
          className="p-4 h-screen flex items-center justify-center"
        >
          <div className="relative flex flex-col justify-between bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-9/12">
            <div className="grid grid-cols-10 gap-x-3 gap-y-5 px-10 pt-5 pb-4">
              <div className="col-span-10 border-b border-lightgray pb-5">
                <nav aria-label="Progress">
                  <div className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                    <div className="relative md:flex-1 md:flex">
                      <div className="group flex items-center w-full justify-between">
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          {currentSection === 1 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-900 rounded-full">
                              <span className="text-indigo-600">1</span>
                            </span>
                          ) : (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-900 rounded-full">
                              <CheckIcon
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          )}
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            Seminar Informationen
                          </span>
                        </span>
                        <svg
                          className="h-20 w-[20px] text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="group flex items-center w-full justify-between">
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          {currentSection === 2 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-900 rounded-full">
                              <span className="text-primary-900">2</span>
                            </span>
                          ) : currentSection > 2 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-900 rounded-full">
                              <CheckIcon
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          ) : currentSection < 2 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">
                                2
                              </span>
                            </span>
                          ) : null}
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            Informationen für Webseite
                          </span>
                        </span>
                        <svg
                          className="h-20 w-[20px] text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="group flex items-center w-full justify-between">
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          {currentSection === 3 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-900 rounded-full">
                              <span className="text-primary-900">3</span>
                            </span>
                          ) : currentSection > 3 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-900 rounded-full">
                              <CheckIcon
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          ) : currentSection < 3 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">
                                3
                              </span>
                            </span>
                          ) : null}
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            Raum hinzufügen
                          </span>
                        </span>
                        <svg
                          className="h-20 w-[20px] text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="group flex items-center w-full">
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          {currentSection === 4 ? (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-900 rounded-full">
                              <span className="text-primary-900">4</span>
                            </span>
                          ) : (
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">
                                4
                              </span>
                            </span>
                          )}
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            Referent hinzufügen
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              {currentSection === 1 && (
                <>
                  {/*                   <span className="col-span-2">Markierung:</span>
                  <input
                    name="marke"
                    variant="outlined"
                    className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    value={seminarInputs.marke || ""}
                    onChange={(e) =>
                      handleSeminarChange(e.target.value, e.target.name)
                    }
                    error={determineIfError("marke")}
                  /> */}
                  <span
                    className={`col-span-2 ${
                      !seminarInputs.thema ? "text-red-600" : ""
                    }`}
                  >
                    Seminarthema:
                  </span>
                  <input
                    name="thema"
                    variant="outlined"
                    required
                    type="text"
                    className="col-span-8 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    value={
                      seminarInputs.thema === null ? "" : seminarInputs.thema
                    }
                    onChange={(e) =>
                      handleSeminarChange(e.target.value, e.target.name)
                    }
                  />

                  <div
                    className={`col-span-2 ${
                      dateValidation(seminarInputs.datum) ? "text-red-600" : ""
                    }`}
                  >
                    Datum:
                  </div>
                  <div className="col-span-3">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={deLocale}
                    >
                      <DatePicker
                        // disableOpenPicker
                        mask="__.__.____"
                        value={seminarInputs.datum}
                        onChange={(newValue) =>
                          handleSeminarChange(newValue, "datum")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="w-full"
                            onBlur={() =>
                              handleSeminarChange(
                                seminarInputs.datum,
                                "end_datum"
                              )
                            }
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              className="w-full"
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    className={`col-span-2 ${
                      dateValidation(seminarInputs.end_datum) ||
                      Date.parse(seminarInputs.datum) >
                        Date.parse(seminarInputs.end_datum)
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    bis:
                  </div>
                  <div className="col-span-3">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={deLocale}
                    >
                      <DatePicker
                        // disableOpenPicker
                        mask="__.__.____"
                        value={seminarInputs.end_datum}
                        onChange={(newValue) =>
                          handleSeminarChange(newValue, "end_datum")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="w-full"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              className="w-full"
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                        disabled={!seminarInputs.datum}
                      />
                    </LocalizationProvider>
                  </div>
                  {rangeValidation("lessthantoday") && (
                    <>
                      <div className="col-span-2"></div>
                      <div className="col-span-8 text-red-400 text-sm">
                        Es ist nicht möglich, das Seminar mit einem früheren
                        Datum als heute anzulegen
                      </div>
                    </>
                  )}
                  {rangeValidation("daterange") && (
                    <>
                      <div className="col-span-5"></div>
                      <div className="col-span-5 text-red-400 text-sm">
                        Das Enddatum des Seminars darf nicht vor dem Startdatum
                        liegen
                      </div>
                    </>
                  )}
                  <div
                    className={`col-span-2 ${
                      dateValidation(seminarInputs.anfangs_zeit)
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    Anfangszeit:
                  </div>
                  <div className="col-span-3">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <TimePicker
                        ampm={false}
                        inputFormat="HH:mm"
                        mask="__:__"
                        disableOpenPicker
                        value={seminarInputs.anfangs_zeit || null}
                        onChange={(newValue) =>
                          handleSeminarChange(newValue, "anfangs_zeit")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="w-full"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              className="w-full"
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    className={`col-span-2 ${
                      dateValidation(seminarInputs.end_zeit)
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    Endzeit:
                  </div>
                  <div className="col-span-3">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <TimePicker
                        ampm={false}
                        inputFormat="HH:mm"
                        mask="__:__"
                        disableOpenPicker
                        value={seminarInputs.end_zeit || null}
                        onChange={(newValue) =>
                          handleSeminarChange(newValue, "end_zeit")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="w-full"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              className="w-full"
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {rangeValidation("timerange") && (
                    <>
                      <div className="col-span-2"></div>
                      <div className="col-span-8 text-red-600 text-sm">
                        Die Schließzeit kann nicht die Öffnungszeit des Seminars
                        vorwegnehmen
                      </div>
                    </>
                  )}
                  <div className="col-span-2 whitespace-nowrap">
                    Rabatt Vor Datum:
                  </div>
                  <div className="col-span-3 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={deLocale}
                    >
                      <DatePicker
                        // disableOpenPicker
                        clearable
                        mask="__.__.____"
                        value={
                          Date.parse(seminarInputs.discount_before_date) || null
                        }
                        onChange={(newValue) =>
                          handleSeminarChange(newValue, "discount_before_date")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="w-full"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              className="w-full"
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <span className="col-span-2 whitespace-nowrap text-black peer-invalid:text-red-600">
                    Rabattpreis:
                  </span>
                  <input
                    name="discount_price"
                    variant="outlined"
                    type="number"
                    className="col-span-3 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    value={seminarInputs.discount_price || ""}
                    onChange={(e) =>
                      handleSeminarChange(e.target.value, e.target.name)
                    }
                    error={determineIfError("discount_price")}
                  />
                  <span className="col-span-2 whitespace-nowrap">
                    Max. Teilnehmerzahl:
                  </span>
                  <input
                    name="max_tn_zahl"
                    variant="outlined"
                    type="number"
                    className="col-span-3 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    value={seminarInputs.max_tn_zahl || ""}
                    onChange={(e) =>
                      handleSeminarChange(e.target.value, e.target.name)
                    }
                  />
                  <span
                    className={`col-span-2 whitespace-nowrap ${
                      !seminarInputs.preis ? "text-red-600" : ""
                    }`}
                  >
                    Netto-Teilnehmerpreis:
                  </span>
                  <div className="col-span-3 flex relative">
                    <EuroSymbolIcon className="pointer-events-none w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-2" />
                    <input
                      name="preis"
                      type="number"
                      variant="outlined"
                      className="w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                      value={seminarInputs.preis || ""}
                      onChange={(e) =>
                        handleSeminarChange(e.target.value, e.target.name)
                      }
                      error={determineIfError("preis")}
                    />
                  </div>
                </>
              )}
              {currentSection === 2 && (
                <>
                  <div className="flex flex-wrap w-full col-span-10">
                    <FormGroup className="ml-2 select-none absolute  left-0 pl-10 mt-2">
                      <FormControlLabel
                        control={
                          <Switch
                            value={seminarInputs.wp_published}
                            checked={
                              seminarInputs.wp_published
                                ? seminarInputs.wp_published
                                : false
                            }
                            onChange={(e) =>
                              handleSeminarChange(
                                e.target.checked,
                                "wp_published"
                              )
                            }
                          />
                        }
                        label="Seminar auf der Website veröffentlichen"
                      />
                    </FormGroup>
                    <div
                      className={`w-full h-full ${
                        seminarInputs.wp_published
                          ? ""
                          : "opacity-30 pointer-events-none"
                      }`}
                    >
                      <nav
                        className="mb-0 list-none flex justify-end px-2"
                        role="tablist"
                      >
                        <div className="px-5 py-2 flex items-center w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 1
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(1);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                          >
                            Seminarbeschreibung
                          </div>
                        </div>
                        <div className="pr-5 py-2 flex items-center w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 2
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link2"
                            role="tablist"
                          >
                            Teilnehmende
                          </div>
                        </div>
                        <div className="px-5 py-2 flex items-center w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 3
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);
                            }}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                          >
                            Schwerpunkte
                          </div>
                        </div>
                        <div className="px-5 py-2 flex items-center w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 4
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(4);
                            }}
                            data-toggle="tab"
                            href="#link4"
                            role="tablist"
                          >
                            Teilnahmebedingungen
                          </div>
                        </div>
                        {/* <div className="px-5 py-2 flex items-center w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 5
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(5);
                            }}
                            data-toggle="tab"
                            href="#link5"
                            role="tablist"
                          >
                            Sachgebiete
                          </div>
                        </div> */}
                      </nav>
                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
                        <div className="px-2 pt-5 flex-auto">
                          <div className="tab-content tab-space">
                            <div
                              className={openTab === 1 ? "block" : "hidden"}
                              id="link1"
                            >
                              <HTMLEditor
                                value={seminarInputs.lang_thema || ""}
                                onChange={(value) => {
                                  handleSeminarChange(value, "lang_thema");
                                }}
                                readOnly={false}
                              />
                            </div>
                            <div
                              className={openTab === 2 ? "block" : "hidden"}
                              id="link2"
                            >
                              <HTMLEditor
                                value={seminarInputs.participants || ""}
                                onChange={(value) => {
                                  handleSeminarChange(value, "participants");
                                }}
                                readOnly={false}
                              />
                            </div>
                            <div
                              className={openTab === 3 ? "block" : "hidden"}
                              id="link3"
                            >
                              <HTMLEditor
                                value={seminarInputs.focus_areas || ""}
                                onChange={(value) => {
                                  handleSeminarChange(value, "focus_areas");
                                }}
                                readOnly={false}
                              />
                            </div>
                            <div
                              className={openTab === 4 ? "block" : "hidden"}
                              id="link4"
                            >
                              <HTMLEditor
                                value={
                                  seminarInputs.conditions_of_participation
                                }
                                onChange={(value) => {
                                  handleSeminarChange(
                                    value,
                                    "conditions_of_participation"
                                  );
                                }}
                                readOnly={false}
                              />
                            </div>
                            {/* <div
                              className={openTab === 5 ? "block" : "hidden"}
                              id="link5"
                            >
                              <div className="h-[300px]">
                                {" "}
                                <div className="w-[500px]">
                                  <FormControl fullWidth>
                                    Sachgebiete Wahlen:
                                    <Select
                                      multiple
                                      labelId="audiences-select-label"
                                      id="audiences-select"
                                      value={
                                        seminarInputs?.audiences
                                          ? seminarInputs.audiences.split(",")
                                          : []
                                      }
                                      onChange={(event) =>
                                        handleSeminarChange(
                                          event.target.value.join(","),
                                          "audiences"
                                        )
                                      }
                                      renderValue={(selected) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                          ))}
                                        </Box>
                                      )}
                                    >
                                      {wordPressAudienceOptions.map(
                                        (audience) => (
                                          <MenuItem
                                            id={audience.id}
                                            value={audience.slug}
                                          >
                                            {audience.slug}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {currentSection === 3 && (
                <>
                  <div className="col-span-10">
                    <div className="flex">
                      <FormGroup className="">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={seminarInputs.id_raum === 423}
                              onChange={(e) =>
                                handleSeminarChange(
                                  e.target.checked ? 423 : null,
                                  "id_raum"
                                )
                              }
                            />
                          }
                          label="Online"
                        />
                      </FormGroup>
                      <input
                        name="bezeichnung"
                        placeholder="Tippen, um Räume zu filtern"
                        className="flex w-full p-1 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm"
                        variant="outlined"
                        disabled={seminarInputs.id_raum === 423}
                        value={query.bezeichnung}
                        onChange={(e) =>
                          setQuery((prior) => ({
                            ...prior,
                            bezeichnung: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div
                      className={`ag-theme-alpine w-full py-2 col-span-3 ${
                        seminarInputs.id_raum === 423
                          ? "pointer-events-none opacity-25"
                          : ""
                      }`}
                    >
                      <div style={{ height: 235 }}>
                        <AgGridReact
                          enableCellTextSelection
                          defaultColDef={{
                            filter: false,
                          }}
                          localeText={localeText}
                          //domLayout={"autoHeight"}
                          rowData={raumeQueryResults}
                          columnDefs={columnSeminarDefs}
                          rowSelection={"single"}
                          onRowClicked={onRaumeSelectionChanged}
                          isExternalFilterPresent={
                            seminarInputs.id_raum === 423
                              ? false
                              : isExternalFilterPresent
                          }
                          suppressRowClickSelection
                          doesExternalFilterPass={(e) =>
                            e.data.id === seminarInputs.id_raum &&
                            seminarInputs.id_raum !== 423
                          }
                          onGridReady={(e) => {
                            if (
                              seminarInputs.hasOwnProperty("id_raum") &&
                              seminarInputs.id_raum !== null
                            ) {
                              e.api.forEachNode((node) => {
                                seminarInputs.id_raum === node.data.id &&
                                  node.setSelected(true, true);
                              });
                            }
                          }}
                          onGridSizeChanged={(e) => {
                            e.api.sizeColumnsToFit();
                          }}
                          ref={gridRefSeminar}
                        ></AgGridReact>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {currentSection === 4 && (
                <>
                  <div className="col-span-5 grid grid-cols-10 gap-y-5">
                    <span className="col-span-4"> Auswahlfeld Referenten:</span>
                    <span className="col-span-6">
                      <Combobox
                        value={
                          Object.keys(referenteAddData).length <= 1
                            ? // ||  Object.keys(seminarReferentListInputs).length === 1
                              ""
                            : referenteAddData.name +
                              " " +
                              referenteAddData.vorname
                        }
                        onChange={currentreferenteAddData}
                      >
                        <div className="relative mt-1">
                          <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                              displayValue={(person) => person}
                              onChange={(event) =>
                                setReferentQuery(event.target.value)
                              }
                            />
                            {referentQuery === "" &&
                            Object.keys(referenteAddData).length ===
                              0 ? null : (
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-7">
                                <HighlightOffIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                  onClick={() => {
                                    setReferenteAddData({
                                      ist_mehrwert_steuer: true,
                                    });
                                  }}
                                />
                              </Combobox.Button>
                            )}
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setReferentQuery("")}
                          >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {filteredPeople.length === 0 &&
                              referentQuery !== "" ? (
                                <div className="relative cursor-default select-none py-2 px-4 ">
                                  Nichts gefunden.
                                </div>
                              ) : (
                                filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 px-2 ${
                                        active
                                          ? "bg-primary-900 text-white"
                                          : ""
                                      }`
                                    }
                                    value={person.id}
                                  >
                                    <span className="block truncate">
                                      {person.attributes.name}{" "}
                                      {person.attributes.vorname}
                                    </span>
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </span>
                    <div className="col-span-4 whitespace-nowrap">
                      Vereinbartes Netto-Honorar:
                    </div>
                    <input
                      name="brutto_honorar_euro"
                      variant="outlined"
                      className="col-span-6 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                      value={referenteAddData.brutto_honorar_euro || ""}
                      onChange={(e) =>
                        handlereferenteAddData(e.target.value, e.target.name)
                      }
                      error={determineIfError("brutto_honorar_euro")}
                      inputprops={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                      disabled={loading || !referenteAddData.id_referent}
                    />

                    <span className="col-span-4 flex items-center">
                      Referent MwSt.-pflichtig:
                    </span>
                    <div className="col-span-6 flex items-center justify-between">
                      <div
                        className={`flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none`}
                      >
                        <div className="flex items-center">
                          <input
                            name="ist_mehrwert_steuer"
                            type="checkbox"
                            value={
                              referenteAddData.ist_mehrwert_steuer
                                ? referenteAddData.ist_mehrwert_steuer
                                : undefined
                            }
                            checked={
                              referenteAddData.ist_mehrwert_steuer
                                ? true
                                : false
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded disabled:cursor-not-allowed"
                            onChange={(e) =>
                              handlereferenteAddData(
                                e.target.checked,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            mit MwSt.
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="ist_mehrwert_steuer"
                            type="checkbox"
                            value={
                              referenteAddData.ist_mehrwert_steuer
                                ? referenteAddData.ist_mehrwert_steuer
                                : undefined
                            }
                            checked={
                              referenteAddData.ist_mehrwert_steuer === false
                                ? true
                                : false
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded disabled:cursor-not-allowed"
                            onChange={(e) =>
                              handlereferenteAddData(
                                e.target.checked ? false : true,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            ohne MwSt.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-10 flex justify-start">
                      <button
                        type="button"
                        disabled={loading || !referenteAddData.id_referent}
                        className={`${
                          loading === true
                            ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                            : "bg-primary-900 disabled:opacity-25"
                        }  relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                        onClick={createReferenteArray}
                      >
                        Referent hinzufügen
                      </button>
                    </div>
                  </div>

                  <div className="col-span-5">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-primary-900">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Honorar
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            MwSt.
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Entfernen</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className=" h-min overflow-y-auto">
                        {referenteArray.map((item) => (
                          <tr
                            key={item.id_referent}
                            className="w-full p-2 h-min"
                          >
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 h-min">
                              {item.name + " " + item.vorname}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500  h-min">
                              {item.brutto_honorar_euro
                                ? Number(
                                    item.brutto_honorar_euro
                                  ).toLocaleString("de-DE", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }) + " €"
                                : ""}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 h-min">
                              {item.ist_mehrwert_steuer === true
                                ? "Ja"
                                : "Nein"}
                            </td>
                            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 h-min">
                              {
                                <DeleteIcon
                                  fontSize="small"
                                  onClick={() =>
                                    setReferenteArray((prev) =>
                                      prev.filter(
                                        (referente) =>
                                          referente.id_referent !==
                                          item.id_referent
                                      )
                                    )
                                  }
                                />
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            <div className="bg-gray-50 px-10 py-3 flex justify-between w-full">
              <button
                type="button"
                className="disabled:pointer-events-none disabled:opacity-25 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setFormErrorHandling(null);
                  setSeminarReferentListInputs({});
                  setSeminarInputs({});
                  setQuery({ bezeichnung: "" });
                  setOpen(false);
                  setFirstRun(true);
                  setCurrentSection(1);
                  setReferenteAddData({ ist_mehrwert_steuer: true });
                  setReferenteArray([]);
                }}
                disabled={loading}
              >
                Abbrechen
              </button>
              <div className="flex justify-end">
                {currentSection === 1 ? null : (
                  <button
                    type="button"
                    className={`${
                      loading === true
                        ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                        : "bg-primary-900 disabled:opacity-25"
                    } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                    onClick={() => setCurrentSection((current) => current - 1)}
                    disabled={loading}
                  >
                    Zurück
                  </button>
                )}
                <button
                  onClick={(event) => {
                    if (currentSection === 4) {
                      updateSeminarHandler(event, open);
                    } else {
                      setCurrentSection((current) => current + 1);
                    }
                  }}
                  disabled={
                    loading ||
                    dateValidation(seminarInputs.end_datum) ||
                    dateValidation(seminarInputs.datum) ||
                    dateValidation(seminarInputs.anfangs_zeit) ||
                    dateValidation(seminarInputs.end_zeit) ||
                    !seminarInputs.id_raum ||
                    rangeValidation("timerange") ||
                    rangeValidation("daterange") ||
                    rangeValidation("lessthantoday") ||
                    seminarInputs.preis === "" ||
                    seminarInputs.thema === ""
                  }
                  className={`${
                    loading === true
                      ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                      : "bg-primary-900 disabled:opacity-25"
                  } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                  type="submit"
                >
                  {currentSection === 4 ? "Weiter" : "Weiter"}
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
