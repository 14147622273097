import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import EmailPreviewGrid from "./EmailPreviewGrid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { axiosSendEmailRoute } from "../helpers/axios";
import IconButton from "@mui/material/IconButton";
import SearchButton from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearButton from "@mui/icons-material/Clear";

// import Fuse from "fuse.js";

const EmailPreview = () => {
  const {
    token,
    notify,
    emailQueryResults,
    getFutureEmails,
    setEmailQueryMode,
    emailQueryMode,
  } = useOutletContext();

  const [query, setQuery] = useState("");

  const [selectedEmail, setSelectedEmail] = useState([]);
  const [deleteSelectedEmails, setDeleteSelectedEmails] = useState(false);
  const [loadingDyn, setLoadingDyn] = useState(false);
  const [filterActive, setFilterActive] = useState(false);

  const bulkEmailDeleteHandler = async (emailids = {}) => {
    try {
      let result;

      result = await axiosSendEmailRoute.post(
        `/bulkdelete/`,
        {
          data: {
            emailentries: emailids,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result?.data) {
        notify("info", "Emails gelöscht");
        setSelectedEmail([]);
        setFilterActive(false);
        getFutureEmails(emailQueryMode);
        setDeleteSelectedEmails(false);
      }
    } catch (err) {
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const filterHandler = async (e, filter = false) => {
    e.preventDefault();
    setLoadingDyn(true);

    if (filter === false) {
      await getFutureEmails(emailQueryMode);
      setQuery("");
      setFilterActive(false);
    } else {
      await getFutureEmails(emailQueryMode, query);
      setFilterActive(true);
    }

    setLoadingDyn(false);
  };

  useEffect(() => {
    if (emailQueryMode === "bounced") {
      setQuery("");
      getFutureEmails(emailQueryMode);
    }
  }, [emailQueryMode, getFutureEmails]);

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Emails Vorschauen</h3>

          <div className="flex items-center">
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        setEmailQueryMode((prior) =>
                          prior === "future" ? "past" : "future"
                        )
                      }
                      checked={emailQueryMode === "future" ? true : false}
                    />
                  }
                  label={emailQueryMode === "future" ? "Abgehend" : "Gesendet"}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex items-end mx-6 mt-4 h-min`}>
        <>
          <div className="flex items-center whitespace-nowrap">Suchen:</div>
          <div
            className={`${
              emailQueryMode === "bounced" && "opacity-20 pointer-events-none"
            } relative w-1/3 overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm`}
          >
            <input
              disabled={loadingDyn}
              className="disabled:opacity-20 ml-5 w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
              value={query}
              onKeyDown={(e) => {
                if (e.key === "Enter" && query) {
                  filterHandler(e, true);
                }
              }}
              onChange={(e) => setQuery(e.target.value)}
            />

            <div className="absolute inset-y-0 right-0 flex items-center justify-center">
              <LoadingButton
                sx={{ minWidth: "auto", padding: 0 }}
                disableRipple
                loading={loadingDyn}
                disabled={query ? false : true}
                onClick={(e) => filterHandler(e, true)}
              >
                {<SearchButton />}
              </LoadingButton>
              {filterActive && loadingDyn === false && (
                <IconButton
                  color="warning"
                  sx={{ padding: 0 }}
                  disabled={loadingDyn}
                  onClick={(e) => filterHandler(e, false)}
                  disableRipple
                >
                  <ClearButton />
                </IconButton>
              )}
            </div>
          </div>
          {/* <input
            name="bezeichnung"
            //placeholder="Rechnungen nach Unternehmen filtern"
            className="ml-10 w-1/3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="outlined"
            value={query || ""}
            onChange={(e) => setQuery(e.target.value)}
          /> */}
        </>
        <div className="pl-4">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={() =>
                    setEmailQueryMode((prior) =>
                      prior === "bounced" ? "future" : "bounced"
                    )
                  }
                  checked={emailQueryMode === "bounced"}
                />
              }
              label={`${
                emailQueryMode === "bounced" ? "Nur Bounces" : "All Emails"
              }`}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <div className="w-full px-6">
          <div className={`py-5`}>
            <div>
              <div className="sm:mt-0 text-sm text-left font-medium text-gray-700">
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form>
                      <div>
                        <div className="bg-white">
                          <div className="grid grid-cols-12 gap-x-6 gap-y-4">
                            <EmailPreviewGrid
                              token={token}
                              emailQueryResults={emailQueryResults}
                              notify={notify}
                              getFutureEmails={getFutureEmails}
                              emailQueryMode={emailQueryMode}
                              bulkEmailDeleteHandler={bulkEmailDeleteHandler}
                              selectedEmail={selectedEmail}
                              setSelectedEmail={setSelectedEmail}
                              deleteSelectedEmails={deleteSelectedEmails}
                              setDeleteSelectedEmails={setDeleteSelectedEmails}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EmailPreview;
