import { useEffect, useState, useRef, useCallback } from "react";

// import { Editor } from "@tinymce/tinymce-react";

import { AgGridReact } from "ag-grid-react";
import Fuse from "fuse.js";
// import sanitizeHtml from "sanitize-html";

import { validateMe, checkZip } from "../helpers/validation";

import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

import { HTMLEditor } from "./partials/HTMLEditor";

// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

export default function ClientApproveModule({
  setOpen,
  listofGermanStates,
  formClient,
  formErrorHandling,
  setFormErrorHandling,
  storedClientSelection,
  rawClientData,
  errorHandling,
  setErrorHandling,
  loadingDyn,
  setLoadingDyn,
}) {
  const [addClient, setAddClient] = useState(false);
  const [query, setQuery] = useState({ email: "", bezeichnung: "" });
  const [clientQueryResults, setClientQueryResults] = useState([]);
  const [clientInputs, setClientInputs] = useState({});

  const handleClientChange = (value, name, type) => {
    /*     formErrorHandling &&
      setFormErrorHandling((prevState) =>
        prevState.filter((e) => e.path[0] !== name)
      ); */
    setClientInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  useEffect(() => {
    if (addClient) {
      setClientInputs({
        bezeichnung: rawClientData.firme,
        bemerkung: rawClientData.bemerkung,
        telefon1: rawClientData.telefon,
        plz: rawClientData.plz,
        ort: rawClientData.ort,
        strasse: rawClientData.strasse,
        haus_num: rawClientData.haus_num,
        // fax: rawClientData.fax,
        email: rawClientData.email_besteller,
        amt_aktiv_status: true,
        bundes_land_id: rawClientData.bundesland,
      });
    }
  }, [addClient, rawClientData]);

  useEffect(() => {
    const options = {
      includeScore: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["attributes.email", "attributes.bezeichnung"],
    };
    const fuse = new Fuse(storedClientSelection, options);
    if (storedClientSelection?.length > 0) {
      let result;
      if (query.email !== "" || query.bezeichnung !== "") {
        result = fuse.search({
          $and: [
            ...(query.email && [
              {
                $path: ["attributes.email"],
                $val: query.email,
              },
            ]),
            ...(query.bezeichnung && [
              {
                $path: ["attributes.bezeichnung"],
                $val: query.bezeichnung,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);

        setClientQueryResults(destructitems);
      } else {
        setClientQueryResults(storedClientSelection);
      }
    }

    // eslint-disable-next-line
  }, [query, storedClientSelection]);

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const gridRefSeminar = useRef();
  const onClientSelectionChanged = (event) => {
    if (event.node.isSelected()) {
      event.node.setSelected(false);
      setClientInputs({});
    } else {
      event.node.setSelected(true, true);
      setClientInputs({
        ...event.data.attributes,
        id_linked_client: event.data.id,
      });
    }
    event.api.onFilterChanged();
  };

  const isExternalFilterPresent = useCallback(() => {
    return clientInputs.id_linked_client !== undefined;
  }, [clientInputs.id_linked_client]);

  const [columnSeminarDefs] = useState([
    {
      checkboxSelection: true,
      width: 50,
      cellStyle: { pointerEvents: "none" },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bezeichnung",
      headerName: "Bezeichnung",
      sortable: true,
      width: 200,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bezeichnung2",
      headerName: "Bezeichnung 2",
      width: 120,
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.telefon1",
      headerName: "Telefon",
      sortable: true,
      width: 150,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.email",
      headerName: "E-Mail",
      sortable: true,
      width: 200,
      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const disabledButton =
    !clientInputs.bezeichnung ||
    !clientInputs.strasse ||
    !clientInputs.haus_num ||
    !clientInputs.bundes_land_id ||
    // !clientInputs.ansprechpartner ||
    !clientInputs.telefon1 ||
    !clientInputs.email ||
    !clientInputs.ort ||
    !clientInputs.plz ||
    errorHandling?.telefon1 === false ||
    errorHandling?.email === false ||
    !checkZip(clientInputs.plz);

  return (
    <div className="bg-white relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-full overflow-auto max-h-full customized-scrollbar">
      <div className="bg-gray-50 p-4 flex">
        <div className="flex w-full justify-between">
          <h3>Seminaranmeldung verwalten</h3>
          <button
            className={`
                  ${
                    (clientInputs.id_linked_client || addClient) &&
                    "pointer-events-none opacity-50"
                  }
                  "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  `}
            onClick={() => {
              setAddClient(true);
              setErrorHandling({});
            }}
          >
            Hinzufügen
          </button>
        </div>
      </div>

      <div className="grid grid-cols-9 gap-3 text-sm text-left font-medium text-gray-700 p-4">
        <>
          <div className="col-span-3">
            <div className="flex font-bold text-xl pb-4">
              Informationen zur Seminaranmeldung <br />
              (eingetragen durch Kunde)
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Ansprechpartner:</div>
              <div className="w-2/3 ">{rawClientData.ansprechpartner}</div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Bemerkungen:</div>
              <div className="w-2/3 break-words">{rawClientData.bemerkung}</div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">E-Mail (Besteller):</div>
              <div className="w-2/3">{rawClientData.email_besteller}</div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">E-Mail (Rechnung):</div>
              <div className="w-2/3">{rawClientData.email_rechnung}</div>
            </div>
            {/* <div className="flex pb-2">
              <div className="w-1/3 font-bold">Fax:</div>
              <div className="w-2/3">{rawClientData.fax}</div>
            </div> */}
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Firme:</div>
              <div className="w-2/3">{rawClientData.firme}</div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Adresse:</div>
              <div className="w-2/3">
                {rawClientData.strasse} {rawClientData.haus_num}
                <br />
                {rawClientData.plz} {rawClientData.ort}
              </div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Bundesland:</div>
              <div className="w-2/3">{rawClientData.bundesland}</div>
            </div>
            <div className="flex pb-2">
              <div className="w-1/3 font-bold">Telefon:</div>
              <div className="w-2/3">{rawClientData.telefon}</div>
            </div>
          </div>

          <div className="col-span-6 overflow-y-scroll customized-scrollbar">
            {addClient !== true && (
              <>
                {!clientInputs.id_linked_client && (
                  <div className="flex gap-3">
                    <input
                      name="email"
                      placeholder="E-Mail filter"
                      className={`${
                        clientInputs.id_linked_client &&
                        "opacity-25 pointer-events-none cursor-not-allowed"
                      } flex flex-1 p-1 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm`}
                      variant="outlined"
                      value={query.email}
                      onChange={(e) =>
                        setQuery((prior) => ({
                          ...prior,
                          email: e.target.value,
                        }))
                      }
                    />
                    <input
                      name="bezeichnung"
                      placeholder="Bezeichnung filter"
                      className={`${
                        clientInputs.id_linked_client &&
                        "opacity-25 pointer-events-none cursor-not-allowed"
                      } flex flex-1 p-1 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm`}
                      variant="outlined"
                      value={query.bezeichnung}
                      onChange={(e) =>
                        setQuery((prior) => ({
                          ...prior,
                          bezeichnung: e.target.value,
                        }))
                      }
                    />
                  </div>
                )}

                <div className="ag-theme-alpine w-full py-2 col-span-6">
                  <div
                    style={
                      clientInputs?.id_linked_client
                        ? { height: 107 }
                        : { height: 300 }
                    }
                  >
                    <AgGridReact
                      enableCellTextSelection
                      rowHeight={33}
                      defaultColDef={{
                        filter: false,
                      }}
                      // localeText={localeText}
                      // domLayout={"autoHeight"}
                      rowData={clientQueryResults}
                      columnDefs={columnSeminarDefs}
                      rowSelection={"single"}
                      onRowClicked={onClientSelectionChanged}
                      isExternalFilterPresent={isExternalFilterPresent}
                      doesExternalFilterPass={(e) =>
                        e.data.id === clientInputs.id_linked_client
                      }
                      suppressRowClickSelection
                      ref={gridRefSeminar}
                      onGridSizeChanged={(e) => {
                        e.api.sizeColumnsToFit();
                      }}
                    ></AgGridReact>
                  </div>
                </div>
              </>
            )}

            <div className="grid grid-cols-12 gap-3 mt-4 items-center">
              {(addClient === true || clientInputs.id_linked_client) && (
                <>
                  <span
                    className={`col-span-1 ${
                      !clientInputs.bezeichnung ? "text-red-600" : ""
                    }`}
                  >
                    Bezeichnung:
                  </span>
                  <input
                    name="bezeichnung"
                    className={`col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      !clientInputs.bezeichnung ? "border-red-600" : ""
                    }`}
                    variant="outlined"
                    value={clientInputs.bezeichnung || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("bezeichnung") ? true : false}
                    //helperText={determineIfError("bezeichnung")}
                  />

                  <span className="col-span-1">
                    Nr.: <strong>{clientInputs.id_linked_client || ""}</strong>
                  </span>

                  <span className="col-span-2">Bundesland:</span>
                  <div
                    className={`col-span-3 p-1 outline-none sm:text-sm ${
                      determineIfError("bundes_land_id")
                        ? "border-red-900"
                        : "border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                    } `}
                  >
                    <select
                      name="bundes_land_id"
                      value={clientInputs.bundes_land_id || ""}
                      onChange={(e) =>
                        handleClientChange(e.target.value, e.target.name)
                      }
                      className="w-full background-transparant border-0 sm:text-sm focus-within:ring-0 p-0 bg-transparent"
                    >
                      {listofGermanStates.map((state) => (
                        <option value={state.province} key={state.id}>
                          {state.province}
                        </option>
                      ))}
                    </select>
                  </div>

                  <span className="col-span-1">Bezeichnung 2:</span>
                  <input
                    name="bezeichnung2"
                    className="col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    variant="outlined"
                    value={clientInputs.bezeichnung2 || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("bezeichnung2") ? true : false}
                    //helperText={determineIfError("bezeichnung2")}
                  />
                  <>
                    <span className="col-span-1">Amtstatus:</span>
                    <div
                      className={`col-span-5 flex items-center relative border ${
                        determineIfError("amt_aktiv_status")
                          ? "border-red-900"
                          : "border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1"
                      } rounded-md px-3 py-2 shadow-sm outline-none`}
                    >
                      <div className="flex place-content-evenly w-full">
                        <div className="flex items-center">
                          <input
                            name="amt_aktiv_status"
                            type="checkbox"
                            checked={
                              clientInputs.amt_aktiv_status === true
                                ? true
                                : false
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange(
                                e.target.checked ? true : false,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Aktuell
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_aktiv_status"
                            type="checkbox"
                            checked={
                              clientInputs.amt_aktiv_status === true
                                ? false
                                : true
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange(
                                e.target.checked ? false : true,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Veraltet
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  <span
                    className={`col-span-1 ${
                      !checkZip(clientInputs.plz) || !clientInputs.plz
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    PLZ:
                  </span>
                  <input
                    name="plz"
                    className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      !checkZip(clientInputs.plz) || !clientInputs.plz
                        ? "border-red-600"
                        : ""
                    }`}
                    type="number"
                    variant="outlined"
                    value={clientInputs.plz || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("plz") ? true : false}
                    //helperText={determineIfError("plz")}
                  />
                  <span
                    className={`col-span-1 ${
                      !clientInputs.ort ? "text-red-600" : ""
                    }`}
                  >
                    Ort:
                  </span>
                  <input
                    name="ort"
                    className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      !clientInputs.ort ? "border-red-600" : ""
                    }`}
                    label="Ort"
                    variant="outlined"
                    value={clientInputs.ort || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("ort") ? true : false}
                    //helperText={determineIfError("ort")}
                  />

                  {/*   <div className="col-span-6"></div> */}

                  <span
                    className={`col-span-1 ${
                      !clientInputs.strasse ? "text-red-600" : ""
                    }`}
                  >
                    Straße:
                  </span>
                  <input
                    name="strasse"
                    className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      !clientInputs.strasse ? "border-red-600" : ""
                    }`}
                    label="Straẞe"
                    variant="outlined"
                    value={clientInputs.strasse || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("strasse" ? true : false)}
                    //helperText={determineIfError("strasse")}
                  />

                  <span
                    className={`col-span-1 ${
                      !clientInputs.haus_num ? "text-red-600" : ""
                    }`}
                  >
                    Nr.:
                  </span>
                  <input
                    name="haus_num"
                    className={`${
                      !clientInputs.haus_num ? "border-red-600" : ""
                    } "col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"`}
                    label="Haus-Nr."
                    variant="outlined"
                    value={clientInputs.haus_num || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                  />
                  <span
                    className={`col-span-1 ${
                      errorHandling?.telefon1 === false ||
                      !clientInputs.telefon1
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    Telefon:
                  </span>
                  <input
                    name="telefon1"
                    type="tel"
                    className={`col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      errorHandling?.telefon1 === false ||
                      !clientInputs.telefon1
                        ? "border-red-600"
                        : ""
                    }`}
                    label="Telefon"
                    variant="outlined"
                    value={clientInputs.telefon1 || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name, "phone")
                    }
                    //error={determineIfError("telefon1") ? true : false}
                    //helperText={determineIfError("telefon1")}
                  />

                  <span
                    className={`col-span-1 ${
                      errorHandling?.email === false || !clientInputs.email
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    E-Mail:
                  </span>
                  <input
                    name="email"
                    className={`col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      errorHandling?.email === false || !clientInputs.email
                        ? "border-red-600"
                        : ""
                    }`}
                    label="E-mail"
                    variant="outlined"
                    value={clientInputs.email || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name, "email")
                    }
                    //error={determineIfError("email")}
                    //helperText={determineIfError("email") ? true : false}
                  />

                  <span className="col-span-1">Standardamt:</span>
                  <input
                    name="standard_amt"
                    className="col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                    label="Standardamt"
                    variant="outlined"
                    value={clientInputs.standard_amt || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("standard_amt") ? true : false}
                    //helperText={determineIfError("standard_amt")}
                  />

                  <span
                    className={`col-span-1 ${
                      determineIfError("www") ? "text-red-600" : ""
                    }`}
                  >
                    Internet:
                  </span>
                  <input
                    name="www"
                    className={`col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                      determineIfError("www") ? "border-red-600" : ""
                    }`}
                    label="Internet"
                    variant="outlined"
                    value={clientInputs.www || ""}
                    onChange={(e) =>
                      handleClientChange(e.target.value, e.target.name)
                    }
                    error={determineIfError("www")}
                    //helperText={determineIfError("www")}
                  />

                  <div className="col-span-7 relative rounded-md pt-8 shadow-sm text-lg font-bold">
                    <label className="absolute -top-2">Bemerkung</label>

                    <HTMLEditor
                      value={clientInputs.bemerkung || ""}
                      onChange={(value) =>
                        handleClientChange(value, "bemerkung")
                      }
                      readOnly={false}
                    />
                  </div>

                  <div className="col-span-5">
                    {/* <div className="flex">
                      <span
                        className={`flex items-center ${
                          !clientInputs.ansprechpartner ? "text-red-600" : ""
                        }`}
                      >
                        Ansprechpartner:
                      </span>
                      <input
                        name="ansprechpartner"
                        className={`ml-5 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          !clientInputs.ansprechpartner ? "border-red-600" : ""
                        }`}
                        label="Ansprechpartner"
                        variant="outlined"
                        value={clientInputs.ansprechpartner || ""}
                        onChange={(e) =>
                          handleClientChange(e.target.value, e.target.name)
                        }
                      />
                    </div> */}

                    <div className="font-bold mb-2 mt-5">Werbestatus</div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-12" />
                      <div className="col-span-3 flex items-center">
                        E-Mail:
                      </div>
                      <div className="col-span-9 flex place-content-evenly w-full mb-2 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                        <div className="flex">
                          <input
                            name="amt_werb_status_email"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_email === "e"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_email || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("e", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            egal
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_werb_status_email"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_email === "n"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_email || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("n", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            nur
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_werb_status_email"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_email === "k"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_email || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("k", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            kein
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-span-3 flex items-center">Brief:</div>
                      <div className="col-span-9 flex place-content-evenly w-full mb-2 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                        <div className="flex">
                          <input
                            name="amt_werb_status_brief"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_brief === "e"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_brief || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("e", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            egal
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_werb_status_brief"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_brief === "n"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_brief || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("n", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            nur
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_werb_status_brief"
                            type="checkbox"
                            checked={
                              clientInputs.amt_werb_status_brief === "k"
                                ? true
                                : false
                            }
                            value={clientInputs.amt_werb_status_brief || ""}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleClientChange("k", e.target.name)
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            kein
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </div>

      <div className="bg-gray-50 p-4 flex justify-end gap-3">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-900"
          onClick={() => {
            if (addClient) {
              setAddClient(false);
              setErrorHandling({});
            } else {
              // setClientInputs({});
              setOpen(false);
              setFormErrorHandling(null);
              setErrorHandling({});
            }
          }}
        >
          {"Abbrechen"}
        </button>
        <LoadingButton
          style={{ textTransform: "none" }}
          disabled={
            (clientInputs.id_linked_client || addClient) && !disabledButton
              ? false
              : true
          }
          onClick={(event) => {
            formClient(event, addClient ? "add" : "edit", clientInputs);
            setLoadingDyn("linkclient");
          }}
          loadingIndicator={<CircularProgress color="primary" size={20} />}
          className="inline-flex disabled:bg-primary-500 disabled:opacity-25 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          loading={loadingDyn === "linkclient" ? true : false}
        >
          Kunde speichern
        </LoadingButton>
      </div>
    </div>
  );
}
