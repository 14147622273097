import { useEffect, useState, useRef, useCallback } from "react";

import { AgGridReact } from "ag-grid-react";
import Fuse from "fuse.js";

import { validateMe, checkZip } from "../helpers/validation";

import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

import { HTMLEditor } from "./partials/HTMLEditor";

export default function ParticipantApproveModule({
  setOpen,
  participantSelection,
  listofGermanStates,
  formParticipant,
  formErrorHandling,
  setFormErrorHandling,
  storedParticipantSelection,
  storedClientId,
  email_besteller,
  loadingDyn,
  setLoadingDyn,
  adhocGenPdfHandler,
  setSeminarInputs,
  setSecondaryErrorHandling,
  addParticipant,
  setAddParticipant,
  secondaryErrorHandling,
  setParticipantInputs,
  participantInputs,
  setRawClientData,
  setAllowLinkToExternalSeminar,
  rawClientData,
}) {
  const [query, setQuery] = useState({ email: "", vorname: "", name: "" });
  const [participantQueryResults, setParticipantQueryResults] = useState([]);

  const [rawParticipantData, setRawParticipantData] = useState({
    left_to_process: "",
  });

  // console.log(rawParticipantData);

  useEffect(() => {
    // filter by new participant registrations that are not linked to the database
    const filterByNoLink = participantSelection.filter(
      (participant) => participant.attributes.id_linked_participant === null
      //  &&
      // rawClientData.id === participant.attributes.id_amt_pre_approval
    );

    // console.log({ filterByNoLink });

    // console.log({ participantSelection });

    setRawParticipantData({
      ...(filterByNoLink.length > 0 && {
        ...filterByNoLink[0].attributes,
        stored_participant_id: filterByNoLink[0].id,
      }),
      left_to_process: filterByNoLink.length,
    });
  }, [participantSelection, setOpen]);

  const handleParticipantChange = (value, name, type) => {
    /*     formErrorHandling &&
      setFormErrorHandling((prevState) =>
        prevState.filter((e) => e.path[0] !== name)
      ); */
    setParticipantInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setSecondaryErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  useEffect(() => {
    const options = {
      includeScore: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["attributes.email", "attributes.vorname", "attributes.name"],
    };
    const fuse = new Fuse(storedParticipantSelection, options);
    if (storedParticipantSelection?.length > 0) {
      let result;
      if (query.email !== "" || query.vorname !== "" || query.name !== "") {
        result = fuse.search({
          $and: [
            ...(query.email && [
              {
                $path: ["attributes.email"],
                $val: query.email,
              },
            ]),
            ...(query.vorname && [
              {
                $path: ["attributes.vorname"],
                $val: query.vorname,
              },
            ]),
            ...(query.name && [
              {
                $path: ["attributes.name"],
                $val: query.name,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);

        setParticipantQueryResults(destructitems);
      } else {
        setParticipantQueryResults(storedParticipantSelection);
      }
    }

    // eslint-disable-next-line
  }, [query, storedParticipantSelection]);

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const gridRef = useRef();
  const onParticipantSelectionChanged = (event) => {
    if (event.node.isSelected()) {
      event.node.setSelected(false);
      setParticipantInputs({});
      event.api.onFilterChanged();
    } else {
      event.node.setSelected(true, true);
      setParticipantInputs({
        ...event.data.attributes,
        id_linked_participant: event.data.id,
      });
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return participantInputs.id_linked_participant !== undefined;
  }, [participantInputs]);

  useEffect(() => {
    if (participantInputs.id_linked_participant && gridRef?.current?.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [participantInputs.id_linked_participant, gridRef]);

  const [columnDefs] = useState([
    {
      checkboxSelection: true,
      width: 50,
      cellStyle: { pointerEvents: "none" },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.name",
      headerName: "Name",
      sortable: true,
      sort: "asc",
      width: 225,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.vorname",
      headerName: "Vorname",
      sortable: true,
      width: 225,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.telefon",
      headerName: "Telefon",
      sortable: true,
      width: 150,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.telefon2",
      headerName: "Telefon 2",
      sortable: true,
      width: 150,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.email",
      headerName: "E-Mail",
      sortable: true,
      width: 200,
      minWidth: 100,
      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const disablebutton =
    secondaryErrorHandling?.telefon === false ||
    secondaryErrorHandling?.email === false ||
    !participantInputs.name ||
    !participantInputs.vorname ||
    !participantInputs.email ||
    !participantInputs.telefon ||
    !participantInputs.geschlecht ||
    !checkZip(participantInputs.plz);

  return (
    <div className="bg-white relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-full overflow-auto max-h-full customized-scrollbar">
      {rawParticipantData.left_to_process !== 0 ? (
        <>
          <div className="bg-gray-50 p-4 flex">
            <div className="flex w-full justify-between">
              <h3>
                Teilnehmer verknüpfen:
                <span className="pl-2">
                  ({rawParticipantData.left_to_process} verblieben)
                </span>
              </h3>
              <button
                className={`
                              ${
                                (participantInputs.id_linked_participant ||
                                  addParticipant) &&
                                "pointer-events-none opacity-50"
                              } 
                               "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"

                                            `}
                onClick={() => {
                  setAddParticipant(true);
                  setParticipantInputs({
                    bundes_land: "Berlin",
                    ist_ausgeschieden: false,
                    persoenlich: "n",
                    abteilung: rawParticipantData.abteilung,
                    title: rawParticipantData.title,
                    position: rawParticipantData.position,
                    geschlecht:
                      rawParticipantData.anrede === "Herr" ? "m" : "f",
                    name: rawParticipantData.name,
                    vorname: rawParticipantData.vorname,
                    telefon: rawParticipantData.telefon,
                    // fax: rawParticipantData.fax,
                    email: rawParticipantData.email,
                  });
                }}
              >
                Hinzufügen
              </button>
            </div>
          </div>
          <div className="grid grid-cols-9 gap-3 text-sm text-left font-medium text-gray-700 p-4">
            <>
              <div className="col-span-3">
                <div className="flex font-bold text-xl pb-4">
                  Informationen zur Seminaranmeldung <br />
                  (eingetragen durch Kunde)
                </div>

                <div className="flex pb-2">
                  <div className="w-1/3 font-bold">Abteilung:</div>
                  <div className="w-2/3">{rawParticipantData.abteilung}</div>
                </div>
                <div className="flex pb-2">
                  <div className="w-1/3 font-bold">Titel:</div>
                  <div className="w-2/3"> {rawParticipantData.title}</div>{" "}
                </div>
                <div className="flex pb-2">
                  <div className="w-1/3 font-bold">Position:</div>
                  <div className="w-2/3">{rawParticipantData.position}</div>
                </div>
                <div className="pb-2 flex flex-wrap">
                  <div className="w-1/3 font-bold">Anrede:</div>
                  <span className="w-2/3">{rawParticipantData.anrede}</span>
                </div>
                <div className="pb-2 flex flex-wrap">
                  <div className="w-1/3 font-bold">Name:</div>
                  <span className="w-2/3">{rawParticipantData.name}</span>
                </div>
                <div className="pb-2 flex flex-wrap">
                  <div className="w-1/3 font-bold">Vorname:</div>
                  <span className="w-2/3">{rawParticipantData.vorname}</span>
                </div>
                <div className="pb-2 flex flex-wrap">
                  <div className="w-1/3 font-bold">E-mail:</div>
                  <span className="w-2/3">{rawParticipantData.email}</span>
                </div>
                <div className="pb-2 flex flex-wrap">
                  <div className="w-1/3 font-bold">Telefon:</div>
                  <span className="w-2/3">{rawParticipantData.telefon}</span>
                </div>
                {/* <div className="pb-10 flex flex-wrap">
                  <div className="w-1/3 font-bold">fax:</div>
                  <span className="w-2/3">{rawParticipantData.fax}</span>
                </div> */}
              </div>

              <div className="col-span-6 overflow-y-scroll customized-scrollbar">
                {addParticipant !== true && (
                  <>
                    {!participantInputs.id_linked_participant && (
                      <div className="w-full flex">
                        <input
                          name="email"
                          placeholder="E-Mail filter"
                          className={`${
                            participantInputs.id_linked_participant &&
                            "opacity-25 pointer-events-none cursor-not-allowed"
                          } flex  flex-1 p-1 mr-5 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm`}
                          variant="outlined"
                          value={query.email}
                          onChange={(e) =>
                            setQuery((prior) => ({
                              ...prior,
                              email: e.target.value,
                            }))
                          }
                        />
                        <input
                          name="vorname"
                          placeholder="filter bei vorname"
                          className={`${
                            participantInputs.id_linked_participant &&
                            "opacity-25 pointer-events-none cursor-not-allowed"
                          } flex flex-1 p-1 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm`}
                          variant="outlined"
                          value={query.vorname}
                          onChange={(e) =>
                            setQuery((prior) => ({
                              ...prior,
                              vorname: e.target.value,
                            }))
                          }
                        />

                        <input
                          name="name"
                          placeholder="filter bei name"
                          className={`${
                            participantInputs.id_linked_participant &&
                            "opacity-25 pointer-events-none cursor-not-allowed"
                          } flex flex-1 ml-5 p-1 border-b border-gray-300 focus-within:border-primary-600 bg-gray-50 focus:border-primary-600 focus:ring-0 sm:text-sm`}
                          variant="outlined"
                          value={query.name}
                          onChange={(e) =>
                            setQuery((prior) => ({
                              ...prior,
                              name: e.target.value,
                            }))
                          }
                        />
                      </div>
                    )}

                    <div
                      className="ag-theme-alpine w-full py-2 col-span-6 "
                      style={
                        participantInputs.id_linked_participant
                          ? { height: 125 }
                          : { height: 300 }
                      }
                    >
                      <AgGridReact
                        enableCellTextSelection
                        defaultColDef={{
                          filter: false,
                        }}
                        rowHeight={33}
                        rowData={participantQueryResults}
                        columnDefs={columnDefs}
                        rowSelection={"single"}
                        onRowClicked={onParticipantSelectionChanged}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={(e) =>
                          e.data.id === participantInputs.id_linked_participant
                        }
                        suppressRowClickSelection
                        ref={gridRef}
                        // localeText={localeText}
                        onGridSizeChanged={(e) => {
                          e.api.sizeColumnsToFit();
                        }}
                      ></AgGridReact>
                    </div>
                  </>
                )}
                <div className="grid grid-cols-12 gap-3 pt-4">
                  {(addParticipant === true ||
                    participantInputs.id_linked_participant) && (
                    <>
                      <span
                        className={`col-span-1 whitespace-nowrap flex items-center ${
                          determineIfError("name") || !participantInputs.name
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        Name:
                      </span>
                      <input
                        name="name"
                        className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          determineIfError("name") || !participantInputs.name
                            ? "border-red-600"
                            : ""
                        }`}
                        variant="outlined"
                        value={participantInputs.name || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("name")}
                      />
                      <span
                        className={`col-span-1 whitespace-nowrap flex items-center ${
                          determineIfError("vorname") ||
                          !participantInputs.vorname
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        Vorname:
                      </span>
                      <input
                        name="vorname"
                        variant="outlined"
                        className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          determineIfError("vorname") ||
                          !participantInputs.vorname
                            ? "border-red-600"
                            : ""
                        }`}
                        value={participantInputs.vorname || ""}
                        onChange={(e) => {
                          handleParticipantChange(
                            e.target.value,
                            e.target.name
                          );
                        }}
                        error={determineIfError("vorname")}
                      />
                      <span
                        className={`"col-span-1 flex items-center whitespace-nowrap" ${
                          !participantInputs.geschlecht ? "text-red-600" : ""
                        }`}
                      >
                        Anrede:
                      </span>
                      <div className="col-span-3 flex items-center justify-between">
                        <div
                          className={`"flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-1 shadow-sm outline-none" ${
                            !participantInputs.geschlecht
                              ? "border-red-600"
                              : ""
                          }`}
                        >
                          <div className="flex place-content-evenly w-full">
                            <div className="flex items-center">
                              <input
                                name="geschlecht"
                                type="checkbox"
                                checked={
                                  participantInputs.geschlecht === "f"
                                    ? true
                                    : false
                                }
                                value={participantInputs.geschlecht || ""}
                                className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                                onChange={(e) =>
                                  handleParticipantChange("f", e.target.name)
                                }
                              />
                              <div className="ml-3 text-xs font-medium text-gray-900">
                                Frau
                              </div>
                            </div>
                            <div className="flex items-center">
                              <input
                                name="geschlecht"
                                type="checkbox"
                                checked={
                                  participantInputs.geschlecht === "m"
                                    ? true
                                    : false
                                }
                                value={participantInputs.geschlecht || ""}
                                className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                                onChange={(e) =>
                                  handleParticipantChange("m", e.target.name)
                                }
                              />
                              <div className="ml-3 text-xs font-medium text-gray-900">
                                Herr
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="col-span-1 flex items-center">
                        Titel:
                      </span>
                      <input
                        name="title"
                        variant="outlined"
                        className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.title || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("title")}
                      />
                      <span className="col-span-1 flex items-center">
                        Position:
                      </span>
                      <input
                        name="position"
                        variant="outlined"
                        className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.position || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("position")}
                      />

                      <span className="col-span-1 flex items-center">
                        B-land:
                      </span>
                      <div
                        className={`col-span-3 p-1 outline-none sm:text-sm ${
                          determineIfError("bundes_land")
                            ? "border-red-900"
                            : "border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                        } `}
                      >
                        <select
                          name="bundes_land"
                          value={participantInputs.bundes_land || ""}
                          onChange={(e) =>
                            handleParticipantChange(
                              e.target.value,
                              e.target.name
                            )
                          }
                          className="w-full border-0 bg-white rounded-md sm:text-sm focus-within:ring-0 p-0"
                        >
                          {listofGermanStates.map((state) => (
                            <option value={state.province} key={state.id}>
                              {state.province}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span className="col-span-2 flex items-center whitespace-nowrap">
                        Pers. anschreib.:
                      </span>
                      <div className="col-span-2 flex items-center justify-between">
                        <div className="flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-1 shadow-sm outline-none">
                          <div className="flex items-center">
                            <input
                              name="persoenlich"
                              type="checkbox"
                              checked={
                                participantInputs.persoenlich === "j"
                                  ? true
                                  : false
                              }
                              value={participantInputs.persoenlich || ""}
                              className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                              onChange={(e) =>
                                handleParticipantChange("j", e.target.name)
                              }
                            />
                            <div className="ml-3 text-xs font-medium text-gray-900">
                              Ja
                            </div>
                          </div>
                          <div className="flex items-center">
                            <input
                              name="persoenlich"
                              type="checkbox"
                              checked={
                                participantInputs.persoenlich === "n"
                                  ? true
                                  : false
                              }
                              value={participantInputs.persoenlich || ""}
                              className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                              onChange={(e) =>
                                handleParticipantChange("n", e.target.name)
                              }
                            />
                            <div className="ml-3 text-xs font-medium text-gray-900">
                              Nein
                            </div>
                          </div>
                        </div>
                      </div>

                      <span className="col-span-1 flex items-center">
                        Abteilung:
                      </span>
                      <input
                        name="abteilung"
                        variant="outlined"
                        className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.abteilung || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("abteilung")}
                      />

                      <span
                        className={`col-span-1 flex items-center ${
                          secondaryErrorHandling?.telefon === false ||
                          !participantInputs.telefon
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        Telefon:
                      </span>
                      <input
                        name="telefon"
                        variant="outlined"
                        type="tel"
                        className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          secondaryErrorHandling?.telefon === false ||
                          !participantInputs.telefon
                            ? "border-red-600"
                            : ""
                        }`}
                        value={participantInputs.telefon || ""}
                        onChange={(e) =>
                          handleParticipantChange(
                            e.target.value,
                            e.target.name,
                            "phone"
                          )
                        }
                        error={determineIfError("telefon")}
                      />

                      <span
                        className={`col-span-1 flex items-center ${
                          secondaryErrorHandling?.email === false ||
                          !participantInputs.email
                            ? "text-red-600"
                            : ""
                        }`}
                      >
                        E-Mail:
                      </span>
                      <input
                        name="email"
                        variant="outlined"
                        className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          secondaryErrorHandling?.email === false ||
                          !participantInputs.email
                            ? "border-red-600"
                            : ""
                        }`}
                        value={participantInputs.email || ""}
                        onChange={(e) =>
                          handleParticipantChange(
                            e.target.value,
                            e.target.name,
                            "email"
                          )
                        }
                        error={determineIfError("email")}
                      />
                      <span
                        className={`col-span-1 flex items-center ${
                          !checkZip(participantInputs.plz) ? "text-red-600" : ""
                        }`}
                      >
                        PLZ:
                      </span>
                      <input
                        name="plz"
                        variant="outlined"
                        className={`col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                          !checkZip(participantInputs.plz)
                            ? "border-red-600"
                            : ""
                        }`}
                        type="number"
                        value={participantInputs.plz || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("plz")}
                      />

                      <span className="col-span-1 flex items-center">Ort:</span>
                      <input
                        name="ort"
                        variant="outlined"
                        className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.ort || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("ort")}
                      />

                      <span className="col-span-1 flex items-center">
                        Straẞe:
                      </span>
                      <input
                        name="strasse"
                        variant="outlined"
                        className="col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.strasse || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("strasse")}
                      />

                      <span className="col-span-1 flex items-center">
                        Haus-Nr.:
                      </span>
                      <input
                        name="haus_num"
                        variant="outlined"
                        className="col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                        value={participantInputs.haus_num || ""}
                        onChange={(e) =>
                          handleParticipantChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("haus_num")}
                      />

                      <div className="col-span-12 text-lg font-bold">
                        <label>Info</label>

                        <HTMLEditor
                          value={participantInputs.bemerkung || ""}
                          onChange={(value) =>
                            handleParticipantChange(value, "bemerkung")
                          }
                          readOnly={false}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>

          <div className="bg-gray-50 py-3 px-6 flex">
            <div className="flex items-center flex-1">
              {email_besteller && (
                <>
                  <div className="font-bold pr-4">E-Mail (Besteller):</div>
                  <div>{email_besteller}</div>
                </>
              )}
            </div>
            {(participantInputs?.id_linked_participant || addParticipant) && (
              <>
                <span className="col-span-2 flex items-center whitespace-nowrap pr-2">
                  Teilnehmer ist der Besteller:
                </span>
                <div className="col-span-2 flex items-center justify-between pr-4">
                  <div className="flex w-full gap-x-2 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-1 shadow-sm outline-none">
                    <div className="flex items-center">
                      <input
                        name="ist_besteller"
                        type="checkbox"
                        checked={participantInputs.ist_besteller === true}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) =>
                          handleParticipantChange(true, e.target.name)
                        }
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        Ja
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="ist_besteller"
                        type="checkbox"
                        checked={!participantInputs.ist_besteller}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) =>
                          handleParticipantChange(false, e.target.name)
                        }
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        Nein
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              type="button"
              className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
              onClick={() => {
                if (addParticipant) {
                  setAddParticipant(false);
                } else {
                  // setParticipantInputs({});
                  setOpen(false);
                }
                setSecondaryErrorHandling({});
              }}
            >
              {"Abbrechen"}
            </button>
            <LoadingButton
              style={{ textTransform: "none" }}
              disabled={
                disablebutton ||
                (participantInputs.id_linked_participant || addParticipant
                  ? false
                  : true)
              }
              onClick={(event) => {
                formParticipant(event, addParticipant ? "add" : "edit", {
                  ...participantInputs,
                  stored_participant_id:
                    rawParticipantData.stored_participant_id,
                  id_amt: storedClientId,
                });

                setLoadingDyn("linkparticipant");
              }}
              loadingIndicator={<CircularProgress color="primary" size={20} />}
              className="disabled:pointer-events-none disabled:bg-primary-500 disabled:opacity-25 ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              loading={loadingDyn === "linkparticipant" ? true : false}
            >
              Teilnehmer hinzufügen
            </LoadingButton>
          </div>
        </>
      ) : (
        <div className="bg-gray-50 py-3 px-6 flex flex-col">
          <div className="flex justify-center h-20 w-80">
            <>
              <div className="font-bold pt-4">Alle Teilnehmer verknüpft!</div>
            </>
          </div>
          <div className="flex justify-between">
            {" "}
            <button
              name="newregdownload"
              disabled={loadingDyn === "newregdownload"}
              onClick={(e) => {
                setLoadingDyn(e.target.name);
                adhocGenPdfHandler(e, "tnlistbyclient");
              }}
              className="disabled:pointer-events-none disabled:bg-primary-500 disabled:opacity-25 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Neuanmeldungen Drucken
            </button>
            <button
              onClick={() => {
                setOpen(false);
                setSeminarInputs({});
                setRawClientData({});
                setAllowLinkToExternalSeminar(false);
                setSecondaryErrorHandling({});
              }}
              className="disabled:pointer-events-none disabled:bg-primary-500 disabled:opacity-25 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Fertig
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
