import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import CircularProgress from "@mui/material/CircularProgress";

import { checkZip } from "../helpers/validation";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { HTMLEditor } from "./partials/HTMLEditor";

export default function ReferentenModal({
  open,
  setOpen,
  referentInputs,
  setReferentInputs,
  handleChange,
  formHandler,
  formErrorHandling,
  setFormErrorHandling,
  loading,
  errorHandling,
  setErrorHandling,
}) {
  const [openTab, setOpenTab] = useState(1);

  const [openInfoTab, setOpenInfoTab] = useState(1);

  useEffect(
    () =>
      open === "add" &&
      setReferentInputs({
        ist_dienstanschrift: false,
        ist_aktuell: true,
        ist_mehrwert_steuer: true,
      }),
    [open, setReferentInputs]
  );

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const disablebutton =
    errorHandling?.telefon_privat === false ||
    errorHandling?.telefon_privat2 === false ||
    errorHandling?.mobiltelefon === false ||
    errorHandling?.email === false ||
    !referentInputs.vorname ||
    !referentInputs.name ||
    !checkZip(referentInputs.priv_plz);

  return (
    <Modal
      open={
        ((open === "edit" || open === "copy") &&
          Object.keys(referentInputs).length > 0) ||
        open === "add"
          ? true
          : false
      }
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="customized-scrollbar h-screen flex items-center justify-center"
      >
        <div
          id="modal-modal-description"
          className="p-4 h-full customized-scrollbar flex items-center justify-center"
        >
          <div className="modalContainerOuter">
            <div className="modalContainerMiddle">
              <div className="modalContainerInner">
                <h3>
                  {open === "edit"
                    ? "Referenten aktualisieren"
                    : "Referenten hinzufügen"}
                </h3>
              </div>

              <span
                className={`col-span-1  ${
                  determineIfError("name") || !referentInputs.name
                    ? "text-red-600"
                    : ""
                }`}
              >
                Name:
              </span>
              <input
                name="name"
                type="text"
                maxLength={25}
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("name") || !referentInputs.name
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={referentInputs.name || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("name")}
              />
              <span className="col-span-1">Titel:</span>
              <input
                name="titel"
                variant="outlined"
                className="col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                value={referentInputs.titel || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("titel")}
              />

              <div className="col-span-2 flex items-center justify-between">
                <span>Anrede:</span>
                <div className="flex place-content-evenly w-2/3 col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                  <div className="flex">
                    <input
                      name="anrede"
                      type="checkbox"
                      checked={referentInputs.anrede === "f" ? true : false}
                      value={referentInputs.anrede || ""}
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleChange("f", e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Frau
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      name="anrede"
                      type="checkbox"
                      checked={referentInputs.anrede === "m" ? true : false}
                      value={referentInputs.anrede || ""}
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleChange("m", e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Herr
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2" />
              <span
                className={`col-span-1  ${
                  determineIfError("vorname") || !referentInputs.vorname
                    ? "text-red-600"
                    : ""
                }`}
              >
                Vorname:
              </span>
              <input
                name="vorname"
                type="text"
                maxLength={25}
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("vorname") || !referentInputs.vorname
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={referentInputs.vorname || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("vorname")}
              />
              <span className={`col-span-1`}>Geburtsdatum</span>
              <div className="col-span-2">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={deLocale}
                >
                  <DatePicker
                    mask="__.__.____"
                    value={referentInputs.geburts_datum}
                    onChange={(newValue) => {
                      handleChange(newValue, "geburts_datum");
                    }}
                    disableOpenPicker
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          className={`flex w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                          ref={inputRef}
                          {...inputProps}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div
                className={`col-span-1 whitespace-nowrap ${
                  errorHandling?.telefon_privat === false ? "text-red-600" : ""
                }`}
              >
                Telefon 1 (privat):
              </div>
              <input
                name="telefon_privat"
                type="tel"
                className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("telefon_privat") ||
                  errorHandling?.telefon_privat === false
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={referentInputs.telefon_privat || ""}
                onChange={(e) =>
                  handleChange(e.target.value, e.target.name, "phone")
                }
                error={determineIfError("telefon_privat")}
              />
              <div
                className={`col-span-1 whitespace-nowrap ${
                  errorHandling?.telefon_privat2 === false ? "text-red-600" : ""
                }`}
              >
                Telefon 2 (privat):
              </div>
              <input
                name="telefon_privat2"
                type="tel"
                className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("telefon_privat2") ||
                  errorHandling?.telefon_privat2 === false
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={referentInputs.telefon_privat2 || ""}
                onChange={(e) =>
                  handleChange(e.target.value, e.target.name, "phone")
                }
                error={determineIfError("telefon_privat2")}
              />

              <span
                className={`col-span-1 
                    ${!checkZip(referentInputs.priv_plz) ? "text-red-600" : ""}
                `}
              >
                PLZ:
              </span>
              <input
                name="priv_plz"
                type="number"
                className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  !checkZip(referentInputs.priv_plz) ? "border-red-600" : ""
                }`}
                variant="outlined"
                value={referentInputs.priv_plz || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("priv_plz")}
              />
              <span className="col-span-1">Ort:</span>
              <input
                name="priv_ort"
                className="col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                variant="outlined"
                value={referentInputs.priv_ort || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("priv_ort")}
              />

              <div
                className={`col-span-1 whitespace-nowrap ${
                  errorHandling?.mobiltelefon === false ? "text-red-600" : ""
                }`}
              >
                Mobiltelefon:
              </div>
              <input
                name="mobiltelefon"
                type="tel"
                className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("mobiltelefon") ||
                  errorHandling?.mobiltelefon === false
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={referentInputs.mobiltelefon || ""}
                onChange={(e) =>
                  handleChange(e.target.value, e.target.name, "phone")
                }
                error={determineIfError("mobiltelefon")}
              />
              <div className="col-span-8 grid grid-cols-8 gap-x-6 gap-y-4">
                <span className="col-span-1 h-min">Straẞe:</span>
                <input
                  name="priv_strasse"
                  className="h-min col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                  variant="outlined"
                  value={referentInputs.priv_strasse || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("priv_strasse")}
                />
                <span className="col-span-1 h-min">Haus-Nr.:</span>
                <input
                  name="priv_haus_nr"
                  className="h-min col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                  variant="outlined"
                  value={referentInputs.priv_haus_nr || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("priv_haus_nr")}
                />

                <span
                  className={`col-span-1 h-min ${
                    determineIfError("internet") ? "text-red-600" : ""
                  }`}
                >
                  Internet:
                </span>
                <input
                  name="internet"
                  type="text"
                  maxLength={50}
                  className={`h-min col-span-7 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                    determineIfError("internet") ? "border-red-600" : ""
                  }`}
                  variant="outlined"
                  value={referentInputs.internet || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("internet")}
                />

                <span
                  className={`col-span-1 h-min ${
                    errorHandling?.email === false || determineIfError("email")
                      ? "text-red-600"
                      : ""
                  }`}
                >
                  E-Mail:
                </span>
                <input
                  name="email"
                  className={`h-min col-span-7 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                    determineIfError("email") || errorHandling?.email === false
                      ? "border-red-600"
                      : ""
                  }`}
                  variant="outlined"
                  value={referentInputs.email || ""}
                  onChange={(e) =>
                    handleChange(e.target.value, e.target.name, "email")
                  }
                  error={determineIfError("email")}
                />
                {determineIfError("email") ===
                "This attribute must be unique" ? (
                  <>
                    <div className="col-span-1 h-min"></div>
                    <div className="col-span-7 text-red-600 text-sm h-min">
                      Dieser E-Mail wird bereits verwendet
                    </div>
                  </>
                ) : null}
                <div className="col-span-1 flex items-center whitespace-nowrap">
                  Welche Anschrift:
                </div>
                <div className="h-min flex place-content-evenly  col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                  <div className="flex items-center">
                    <input
                      name="ist_dienstanschrift"
                      type="checkbox"
                      checked={
                        referentInputs.ist_dienstanschrift === true
                          ? false
                          : true
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) =>
                        handleChange(
                          e.target.checked ? false : true,
                          e.target.name
                        )
                      }
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Privatanschrift
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      name="ist_dienstanschrift"
                      type="checkbox"
                      checked={
                        referentInputs.ist_dienstanschrift === true
                          ? true
                          : false
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) =>
                        handleChange(
                          e.target.checked ? true : false,
                          e.target.name
                        )
                      }
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Dienstanschrift
                    </div>
                  </div>
                </div>

                <div className="col-span-8 border-t border-lightgray mt-3"></div>

                <div className="col-span-4 whitespace-nowrap flex items-center">
                  <div
                    className="flex mb-0 list-none flex-row w-full text-center"
                    role="tablist"
                  >
                    <div className="px-5 flex items-center justify-start w-min">
                      <div
                        className={
                          "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap  " +
                          (openInfoTab === 1
                            ? "bg-primary-900 text-white"
                            : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenInfoTab(1);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        Interne Informationen
                      </div>
                    </div>
                    <div className="px-5 flex items-center justify-start w-min">
                      <div
                        className={
                          "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                          (openInfoTab === 2
                            ? "bg-primary-900 text-white"
                            : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenInfoTab(2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        Beschreibung auf Webseite
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 whitespace-nowrap flex items-center">
                  Aktiver Referenten?:
                </div>
                <div className=" h-min flex place-content-evenly  col-span-2 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                  <div className="flex items-center">
                    <input
                      name="ist_aktuell"
                      type="checkbox"
                      checked={
                        referentInputs?.ist_aktuell === true ? true : false
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) =>
                        handleChange(
                          e.target.checked ? true : false,
                          e.target.name
                        )
                      }
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Ja
                    </div>
                  </div>
                  <div className="flex items-center ml-5">
                    <input
                      name="ist_aktuell"
                      type="checkbox"
                      checked={
                        referentInputs.ist_aktuell === true ? false : true
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) =>
                        handleChange(
                          e.target.checked ? false : true,
                          e.target.name
                        )
                      }
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Nein
                    </div>
                  </div>
                </div>
                <div
                  className={`col-span-8 ${
                    openInfoTab === 1 ? "block" : "hidden"
                  }`}
                  id="link1"
                >
                  <div className="w-full">
                    <HTMLEditor
                      value={referentInputs.internal_info || ""}
                      onChange={(value) => {
                        handleChange(value, "internal_info");
                      }}
                      readOnly={false}
                    />
                  </div>
                </div>
                <div
                  className={`col-span-8 ${
                    openInfoTab === 2 ? "block" : "hidden"
                  }`}
                  id="link1"
                >
                  <div className="w-full">
                    <HTMLEditor
                      value={
                        referentInputs.bemerkung === null
                          ? ""
                          : referentInputs.bemerkung
                      }
                      onChange={(value) => {
                        handleChange(value, "bemerkung");
                      }}
                      readOnly={false}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-4 grid grid-cols-6">
                <div className="col-span-6 flex border-t border-lightgray ">
                  <div className="flex flex-wrap w-full">
                    <div className="w-full">
                      <div
                        className="flex mb-0 list-none flex-wrap pt-3 flex-row w-full text-center"
                        role="tablist"
                      >
                        <div className="px-5 py-2 flex items-center justify-start w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap  " +
                              (openTab === 1
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(1);
                            }}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                          >
                            Honorar / Konto
                          </div>
                        </div>
                        <div className="px-5 py-2 flex items-center justify-start w-min">
                          <div
                            className={
                              "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                              (openTab === 2
                                ? "bg-primary-900 text-white"
                                : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(2);
                            }}
                            data-toggle="tab"
                            href="#link2"
                            role="tablist"
                          >
                            Dienststelle
                          </div>
                        </div>
                      </div>
                      <div className="relative flex flex-col bg-white w-full mb-3 shadow-lg rounded">
                        <div className="p-3 flex-auto">
                          <div className="tab-content tab-space">
                            <div
                              className={openTab === 1 ? "block" : "hidden"}
                              id="link1"
                            >
                              <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Netto-Honorar:
                                </div>
                                <input
                                  name="brutto_honorar_euro"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={
                                    referentInputs.brutto_honorar_euro || ""
                                  }
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError(
                                    "brutto_honorar_euro"
                                  )}
                                />

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Ist MwSt?:
                                </div>
                                <div className="col-span-9 flex items-center border border-lightgray rounded-md px-3 py-2 shadow-sm">
                                  <div className="flex place-content-evenly w-full">
                                    <div className="flex items-center">
                                      <input
                                        name="ist_mehrwert_steuer"
                                        type="checkbox"
                                        checked={
                                          referentInputs.ist_mehrwert_steuer ===
                                          true
                                            ? true
                                            : false
                                        }
                                        value={
                                          referentInputs?.ist_mehrwert_steuer ||
                                          ""
                                        }
                                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.checked ? true : false,
                                            e.target.name
                                          )
                                        }
                                      />
                                      <div className="ml-3 text-xs font-medium text-gray-900">
                                        Ja
                                      </div>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        name="ist_mehrwert_steuer"
                                        type="checkbox"
                                        checked={
                                          referentInputs.ist_mehrwert_steuer ===
                                          true
                                            ? false
                                            : true
                                        }
                                        value={
                                          referentInputs.ist_mehrwert_steuer ||
                                          ""
                                        }
                                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                                        onChange={(e) =>
                                          handleChange(
                                            e.target.checked ? false : true,
                                            e.target.name
                                          )
                                        }
                                      />
                                      <div className="ml-3 text-xs font-medium text-gray-900">
                                        Nein
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Steuer-Num.:
                                </div>
                                <input
                                  name="m_steuer_num"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  maxLength="17"
                                  variant="outlined"
                                  value={referentInputs.m_steuer_num || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("m_steuer_num")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Bank:
                                </div>
                                <input
                                  name="bank"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  maxLength="28"
                                  value={referentInputs.bank || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("bank")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Kontonummer:
                                </div>
                                <input
                                  name="konto_nummer"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  maxLength="14"
                                  value={referentInputs.konto_nummer || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("konto_nummer")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  BLZ:
                                </div>
                                <input
                                  name="marke_ma03"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  maxLength="8"
                                  value={referentInputs.marke_ma03 || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("marke_ma03")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  IBAN:
                                </div>
                                <input
                                  name="konto_iban"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  maxLength="22"
                                  value={referentInputs.konto_iban || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("konto_iban")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  BIC/SWIFT:
                                </div>
                                <input
                                  name="konto_bic"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  maxLength="11"
                                  value={referentInputs.konto_bic || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("konto_bic")}
                                />
                              </div>
                            </div>

                            <div
                              className={openTab === 2 ? "block" : "hidden"}
                              id="link2"
                            >
                              <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Bezeichnung 1:
                                </div>
                                <input
                                  name="marke_ma01"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.marke_ma01 || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  // error={determineIfError("marke_ma01")}
                                />
                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Bezeichnung 2:
                                </div>
                                <input
                                  name="marke_ma02"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.marke_ma02 || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  // error={determineIfError("marke_ma02")}
                                />

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Abteilung:
                                </div>
                                <input
                                  name="abteilung"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.abteilung || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  // error={determineIfError("abteilung")}
                                />

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Position:
                                </div>
                                <input
                                  name="position"
                                  className="h-min col-span-9 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.position || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  // error={determineIfError("position")}
                                />

                                <div
                                  className={`col-span-3 whitespace-nowrap flex items-center ${
                                    !checkZip(referentInputs.plz || "")
                                      ? "text-red-600"
                                      : ""
                                  }`}
                                >
                                  Plz:
                                </div>
                                <input
                                  name="plz"
                                  className={`h-min col-span-3 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                                    !checkZip(referentInputs.plz || "")
                                      ? "border-red-600"
                                      : ""
                                  }`}
                                  variant="outlined"
                                  value={referentInputs.plz || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  // error={determineIfError("plz")}
                                />

                                <div className="col-span-1 whitespace-nowrap flex items-center">
                                  Ort:
                                </div>
                                <input
                                  name="ort"
                                  className="h-min col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.ort || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("ort")}
                                />

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Straẞe:
                                </div>
                                <input
                                  name="strasse"
                                  className="h-min col-span-5 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.strasse || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("strasse")}
                                />
                                <div className="col-span-2 whitespace-nowrap flex items-center">
                                  Haus-Nr:
                                </div>
                                <input
                                  name="haus_nr"
                                  className="h-min col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.haus_nr || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("haus_nr")}
                                />

                                <div className="col-span-3 whitespace-nowrap flex items-center">
                                  Tel 1 (Dienst):
                                </div>
                                <input
                                  name="telefon_dienst"
                                  className="h-min col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.telefon_dienst || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("telefon_dienst")}
                                />

                                <div className="col-span-1 whitespace-nowrap flex items-center">
                                  Tel 2:
                                </div>
                                <input
                                  name="telefon_dients2"
                                  className="h-min col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                  variant="outlined"
                                  value={referentInputs.telefon_dients2 || ""}
                                  onChange={(e) =>
                                    handleChange(e.target.value, e.target.name)
                                  }
                                  error={determineIfError("telefon_dients2")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 py-3 px-6 flex justify-end">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setOpen(false);
                  setFormErrorHandling(null);
                  setErrorHandling({});
                }}
              >
                Abbrechen
              </button>
              <button
                disabled={disablebutton}
                onClick={(event) => formHandler(event, open)}
                //className="ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loading === true
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-40"
                } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              >
                {open === "edit" ? "Aktualisieren" : "Hinzufügen"}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
