import React, { useState, useRef, useMemo } from "react";

import { AgGridReact } from "ag-grid-react";

import AG_GRID_LOCALE_DE from "./locale.de";

import PreviewIcon from "@mui/icons-material/Preview";
import EmailPreviewModal from "./EmailPreviewModal";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const EmailPreviewGrid = ({
  emailQueryResults,
  token,
  notify,
  getFutureEmails,
  emailQueryMode,
  bulkEmailDeleteHandler,
  selectedEmail,
  setSelectedEmail,
  deleteSelectedEmails,
  setDeleteSelectedEmails,
}) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const gridRefSeminar = useRef();

  const [previewEmail, setPreviewEmail] = useState(null);

  const convertIsoToLocal = (params) => {
    if (params.value !== null) {
      let isoDate = params.value;

      var d = new Date(isoDate);
      return (
        d.toLocaleDateString("de-DE") + " @ " + d.toLocaleTimeString("de-DE")
      );
    } else {
      return "";
    }
  };

  const columnSeminarDefs = useMemo(
    () => [
      {
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <div className="flex h-full items-center">
              <input
                type="checkbox"
                defaultChecked={selectedEmail?.some(
                  (id) => params.node.data.id === id
                )}
              />
            </div>
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: true,
      },
      {
        field: "attributes.from",
        headerName: "Von",
        maxWidth: 200,
        sortable: true,
        headerClass: "header-blue",
      },
      {
        field: "attributes.to",
        headerName: "Zu",
        minWidth: 150,
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        // field: "attributes.to",
        headerName: "CC",
        minWidth: 150,
        sortable: true,
        cellRenderer: (params) => {
          const cc = params.data.attributes.cc || null;
          const optional_cc_email =
            params.data.attributes.optional_cc_email || null;

          return `${cc || ""}${cc && optional_cc_email ? ", " : ""}${
            optional_cc_email || ""
          }`;
        },
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "attributes.subject",
        headerName: "Betreff",
        sortable: true,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "attributes.body",
        headerName: "Nachrichtentext",
        sortable: true,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "attributes.attachments.data",
        headerName: "Anhang",
        sortable: true,
        maxWidth: 75,
        minWidth: 75,
        headerClass: "header-blue",
        cellRenderer: (params) => {
          return params?.value?.length ? "Ja" : "Nein";
        },
        resizable: true,
      },
      {
        field: "attributes.time_to_send",
        headerName: "Abgehend",
        sortable: true,
        minWidth: 100,
        cellRenderer: (params) => convertIsoToLocal(params),
        headerClass: "header-blue",
      },
      {
        field: "attributes.send_status",
        headerName: "Status",
        maxWidth: 100,
        headerClass: "header-blue",
        cellRenderer: (params) => {
          const status = params?.value || "";

          const convertStatusToGerman = (status) => {
            switch (status) {
              case "Send":
                return "Gesendet";
              case "Reject":
                return "Abgelehnt";
              case "Delivery":
                return "Zugestellt";
              case "Bounce":
                return "Bounce";
              case "Complaint":
                return "In Spam gelandet";
              case "DeliveryDelay":
                return "Zustellungsverzögerung";
              case "Open":
                return "Geöffnet";
              default:
                return status; // Return the status as-is if not found in the switch
            }
          };

          const germanStatus = convertStatusToGerman(status);

          return germanStatus;
        },
      },
      {
        headerName: "Vorschau",
        maxWidth: 100,
        headerClass: "header-blue",
        cellRenderer: (params) => (
          <PreviewIcon
            onClick={() => setPreviewEmail(params.data)}
            className="cursor-pointer flex h-full"
          />
        ),
      },
    ],
    [selectedEmail]
  );

  const defaultColDef = useMemo(() => {
    return {
      filter: false,
    };
  }, []);

  return (
    <>
      <div
        style={{ height: "50vh" }}
        className="ag-theme-alpine w-full col-span-12"
      >
        <AgGridReact
          rowMultiSelectWithClick
          enableCellTextSelection
          defaultColDef={defaultColDef}
          rowHeight={33}
          rowData={emailQueryResults}
          columnDefs={columnSeminarDefs}
          rowSelection={"multiple"}
          ref={gridRefSeminar}
          localeText={localeText}
          paginationPageSize={14}
          pagination={true}
          onGridSizeChanged={(e) => {
            e.api.sizeColumnsToFit();
          }}
          onSelectionChanged={(event) => {
            if (
              event?.api?.getFocusedCell()?.column?.colDef?.headerName !==
              "Vorschau"
            ) {
              setSelectedEmail(
                event.api.getSelectedNodes().map((node) => node.data.id)
              );
            }
          }}
        ></AgGridReact>
        <div className={`flex justify-end pt-5 w-full`}>
          {" "}
          {selectedEmail?.length > 0 &&
            (emailQueryMode === "future" || emailQueryMode === "bounced") && (
              <>
                <button
                  name="deleteall"
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteSelectedEmails(true);
                  }}
                  className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                >
                  {"ausgewählte löschen"}
                </button>
              </>
            )}
        </div>
      </div>

      <EmailPreviewModal
        getFutureEmails={getFutureEmails}
        previewEmail={previewEmail}
        setPreviewEmail={setPreviewEmail}
        emailQueryMode={emailQueryMode}
        notify={notify}
        token={token}
      />

      <>
        <Modal
          open={deleteSelectedEmails === true ? true : false}
          aria-describedby="modal-modal-other"
        >
          <Box
            sx={{ overflowY: "scroll", maxHeight: "100%" }}
            className="customized-scrollbar h-screen flex items-center justify-center"
          >
            <div
              id="modal-modal-other"
              className="p-4 h-full customized-scrollbar flex items-center justify-center w-1/3"
            >
              <div className="p-4 relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full">
                <div className="pb-4">
                  Bitte bestätigen Sie, dass Sie die ausgewählten Teilnehmer
                  löschen möchten
                </div>
                <div className="flex w-full justify-end">
                  <button
                    onClick={() => setDeleteSelectedEmails(false)}
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 mr-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Nein
                  </button>
                  <button
                    onClick={() => bulkEmailDeleteHandler(selectedEmail)}
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                  >
                    Ja
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    </>
  );
};

export default EmailPreviewGrid;
