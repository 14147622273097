import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useOutletContext } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_DE from "./locale.de";
import Fuse from "fuse.js";

import { axiosDownloadPdfRoute } from "../helpers/axios";

import CircularProgress from "@mui/material/CircularProgress";

import DownloadIcon from "@mui/icons-material/Download";

const Fileviewer = ({
  mode,
  setFileSelector,
  selectedFiles,
  setSelectedFiles,
}) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const { token, notify, seminarInputs, fileSelection } = useOutletContext();

  const [loadingDyn, setLoadingDyn] = useState(false);

  const gridRefFileviewer = useRef();

  const [query, setQuery] = useState({ name: "" });
  const [fileQueryResults, setFileQueryResults] = useState([]);

  useEffect(() => {
    const options = {
      includeScore: false,
      useExtendedSearch: true,
      threshold: 0.4,
      keys: ["name"],
    };

    const fuse = new Fuse(fileSelection, options);
    if (fileSelection?.length > 0) {
      let result;
      if (query.name !== "") {
        result = fuse.search({
          $and: [
            ...(query.name && [
              {
                $path: ["name"],
                $val: query.name,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);
        setFileQueryResults(destructitems);
      } else {
        setFileQueryResults(fileSelection);
      }
    } else {
      setFileQueryResults([]);
    }
  }, [query, fileSelection]);

  const rowClass = "my-row-class";
  const rowHeight = 33;

  const convertIsoToLocal = (params) => {
    if (params.value !== null) {
      let isoDate = params.value;

      var d = new Date(isoDate);
      return d.toLocaleString("de-DE");
    } else {
      return "";
    }
  };

  const downloadFile = useCallback(
    async (type, idselection = [], mode) => {
      type === "getfiles" && setLoadingDyn(idselection[0]);
      // e.preventDefault();
      const data = {
        type,
        mode,
        idselection,
        searchbyseminarid:
          type === "listfilesbyseminar" ? seminarInputs.id : null,
      };

      try {
        let result;

        result = await axiosDownloadPdfRoute.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          if (type === "getfiles" || mode === "downloadall") {
            await (async () => {
              for await (let file of result?.data) {
                const newfile = new Blob([
                  new Uint8Array(file.binary.data).buffer,
                ]);
                const url = window.URL.createObjectURL(newfile);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", file.name);
                document.body.appendChild(link);
                link.click();
              }
            })();
          }

          setLoadingDyn(false);
        }
      } catch (err) {
        notify("info", err.response.data.error.message);

        setLoadingDyn(false);
      }
    },
    [notify, seminarInputs.id, token]
  );

  const columnFileviewerDefs = useMemo(
    () => [
      {
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        hide: mode === "fileselector" ? false : true,
        cellRenderer: (params) => {
          return (
            <input
              type="checkbox"
              className={`p-0`}
              defaultChecked={selectedFiles?.some(
                (fileid) => params.node.data.id === fileid.id
              )}
            />
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: () => true,
      },
      {
        field: "name",
        width: 400,
        headerName: "Dateiname",
        sortable: true,
        headerClass: "header-blue",
      },
      {
        headerName: "Typ",
        width: 100,
        sortable: true,
        headerClass: "header-blue",

        valueGetter: (params) => {
          return params.node.data.name.startsWith("Rechnung")
            ? "Rechnung"
            : params.node.data.name.startsWith("TN")
            ? "Zertifikat"
            : "";
        },
      },
      {
        field: "createdAt",
        headerName: "Hergestellt am",
        sortable: true,
        headerClass: "header-blue",

        cellRenderer: (params) => convertIsoToLocal(params),
      },
      {
        headerName: "Download",
        sortable: true,
        width: 110,
        headerClass: "header-blue",
        hide: mode === "fileselector" ? true : false,
        cellRenderer: (params) => {
          return loadingDyn === params.node.data.id ? (
            <CircularProgress className="mt-1" size={25} />
          ) : (
            <DownloadIcon
              className="cursor-pointer"
              onClick={() => downloadFile("getfiles", [params.node.data.id])}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line
    [loadingDyn, fileSelection, selectedFiles, fileQueryResults]
  );

  return (
    <main className="flex flex-col gap-2">
      <div className="flex">
        {
          <>
            <div className="flex items-center whitespace-nowrap">
              Dateiname filtern:
            </div>
            <input
              name="bezeichnung"
              //placeholder="Rechnungen nach Unternehmen filtern"
              className="ml-10 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
              variant="outlined"
              value={query.name}
              onChange={(e) =>
                setQuery((prior) => ({
                  ...prior,
                  name: e.target.value,
                }))
              }
            />
          </>
        }
      </div>
      <div />
      <div style={{ height: 325 }} className="ag-theme-alpine">
        <AgGridReact
          enableCellTextSelection
          defaultColDef={{
            filter: false,
          }}
          rowMultiSelectWithClick
          rowHeight={rowHeight}
          rowClass={rowClass}
          rowData={fileQueryResults}
          columnDefs={columnFileviewerDefs}
          rowSelection={mode === "fileselector" ? "multiple" : "single"}
          ref={gridRefFileviewer}
          localeText={localeText}
          paginationPageSize={10}
          pagination={true}
          onSelectionChanged={(event) => {
            mode === "fileselector" &&
              setSelectedFiles(
                event.api.getSelectedNodes().map((node) => node.data)
              );
          }}
        ></AgGridReact>
      </div>
      <div className={`flex justify-end pb-10`}>
        <div>
          {" "}
          {mode === "fileselector" ? (
            <>
              <button
                name="cancel"
                onClick={() => {
                  setSelectedFiles([]);
                  setFileSelector(false);
                }}
                //className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className="mr-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
              >
                {"Abbrechen"}
              </button>
              <button
                name="confirmed"
                onClick={() => {
                  setFileSelector(false);
                }}
                //className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`bg-primary-900 disabled:opacity-25 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                  rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                  focus:ring-offset-2 focus:ring-primary-500`}
              >
                {"OK"}
              </button>
            </>
          ) : (
            <button
              name="genallinvoices"
              onClick={(e) => {
                e.preventDefault();
                setLoadingDyn(e.target.name);
                downloadFile("listfilesbyseminar", null, "downloadall");
              }}
              //className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              className={`${
                loadingDyn === "genallinvoices"
                  ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                  : "bg-primary-900 disabled:opacity-25"
              } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
            >
              {"Alle Herunterladen"}
              {loadingDyn === "genallinvoices" && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </button>
          )}
        </div>
      </div>
    </main>
  );
};

export default Fileviewer;
