import React, { useEffect, useState, useCallback } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { io } from "socket.io-client";
import {
  axiosSeminarRoute,
  axiosRaumeRoute,
  axiosReferentRoute,
  // axiosVb_SemRefRoute,
  axiosSeminarParticipantRoute,
  axiosCustomerPreApproveRoute,
  axiosParticipantPreApproveRoute,
  axiosTeilnehmRoute,
  axiosAemterRoute,
  axiosGetAllUsersData,
  axiosInvoiceRoute,
  axiosDownloadPdfRoute,
  axiosWordPressLoginRoute,
  axiosGetEmailListsRoute,
  axiosWordPressAudienceRoute,
  axiosGetUserData,
  axiosSendEmailRoute,
  axiosCustomQueryRoute,
} from "./helpers/axios";

import Cookies from "universal-cookie";

import PageLayout from "./PageLayout";

import listofGermanStates from "./modules/partials/listofGermanStates";

import { DateTime } from "luxon";

const ProtectedRoute = () => {
  const cookies = new Cookies();

  const [isAuth, setIsAuth] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [token, setToken] = useState(cookies.get("biteg-app-token"));
  const [searchParams] = useSearchParams();

  const [WPToken, setWPToken] = useState(null);

  const { pathname } = useLocation();

  const [seminarSelection, setSeminarSelection] = useState([]);
  const [raumeSelection, setRaumeSelection] = useState([]);
  const [referentSelection, setReferentSelection] = useState([]);
  const [seminarReferenteSelection, setSeminarReferenteSelection] = useState(
    []
  );
  const [clientSelection, setClientSelection] = useState([]);
  const [storedClientSelection, setStoredClientSelection] = useState([]);
  const [participantSelection, setParticipantSelection] = useState([]);
  const [storedParticipantSelection, setStoredParticipantSelection] = useState(
    []
  );

  const [allUsers, setAllUsers] = useState([]);

  const [invoiceSelection, setInvoiceSelection] = useState([]);

  const [seminarParticipantsList, setSeminarParticipantsList] = useState([]);
  const [seminarInputs, setSeminarInputs] = useState({});
  const [editSeminar, setEditSeminar] = useState(false);

  const [errorHandling, setErrorHandling] = useState(null);

  const [fileSelection, setFileSelection] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [emailQueryResults, setEmailQueryResults] = useState([]);
  const [emailQueryMode, setEmailQueryMode] = useState("future");

  const [editSeminarAltert, setEditSeminarAltert] = useState(false);

  const [selectSeminarOnFirstLoad, setSelectSeminarOnFirstLoad] =
    useState(true);

  const [triggerSeminarSelect, setTriggerSeminarSelect] = useState(false);

  const [idFilter, setIdFilter] = useState(false);
  const [exactDateFilter, setExactDateFilter] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState(false);

  const [wordPressAudienceOptions, setWordPressAudienceOptions] = useState([]);

  let navigate = useNavigate();

  const notify = (type, message, params = null) => {
    let defaultparams = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    };

    if (params) {
      defaultparams = { ...defaultparams, ...params };
    }

    if (type === "error") {
      toast.error(message, defaultparams);
    } else {
      toast(message, defaultparams);
    }
  };

  const getFutureEmails = useCallback(
    async (mode = "future", searchquery = null) => {
      let inputdate = new Date();
      inputdate.setDate(inputdate.getDate() - 15);

      const getParams = (mode, searchquery) => {
        if (mode === "bounced") {
          return {
            "filters[bounced]": true,
          };
        } else {
          return {
            "filters[email_sent]": mode === "future" ? false : true,
            ...(searchquery === null
              ? {
                  "filters[time_to_send][$gte]": inputdate,
                }
              : {
                  "filters[$or][0][to][$containsi]": searchquery,
                  "filters[$or][1][cc][$containsi]": searchquery,
                  "filters[$or][2][optional_cc_email][$containsi]": searchquery,
                  "filters[$or][4][body][$containsi]": searchquery,
                  "filters[$or][5][subject][$containsi]": searchquery,
                }),
            "filters[from]": userDetails.email,
          };
        }
      };

      const params = getParams(mode, searchquery);

      const emailResults = await axiosSendEmailRoute.get("/", {
        params: {
          ...params,
          populate: "*",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setEmailQueryResults([...emailResults.data.data]);
    },
    [token, userDetails]
  );

  const searchByClientOrSeminar = useCallback(
    async (query, queryDate, queryMode) => {
      try {
        const customqueryresults = await axiosCustomQueryRoute.post(
          `/${
            queryMode === "rechnungsnummern" ||
            queryMode === "mitarbeiter_kurse"
              ? "getbyseminar"
              : "getcustomerbyparam"
          }`,
          {
            data: { query, queryDate, queryMode },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (customqueryresults.data.length > 0) {
          return customqueryresults.data;
        } else {
          notify("info", "Keine Ergebnisse");
        }
      } catch (err) {
        notify("error", err.response.data.error.message);
      }
    },
    [token]
  );

  // get registration data
  const getClientData = useCallback(
    async (signal) => {
      const preCustomerResult = await axiosCustomerPreApproveRoute.get("/", {
        signal: signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      preCustomerResult?.data?.data &&
        setClientSelection([...preCustomerResult.data.data]);

      // const storedCustomer = await axiosAemterRoute.get("/", {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // storedCustomer?.data?.data &&
      //   setStoredClientSelection([...storedCustomer.data.data]);
    },
    [token]
  );

  // get stored client data
  const getStoredClientData = useCallback(
    async (query = null) => {
      const storedCustomer = await axiosAemterRoute.get("/", {
        params: {
          ...(query ? query : null),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      storedCustomer?.data?.data &&
        setStoredClientSelection([...storedCustomer.data.data]);
    },
    [token]
  );

  // get registration and stored participants
  const getParticipantData = useCallback(
    async (signal) => {
      const preParticipantResults = await axiosParticipantPreApproveRoute.get(
        "/",
        {
          signal: signal,
          params: { "filters[id_linked_participant][$null]": true },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // store participants from wordpress registration to state
      preParticipantResults?.data?.data &&
        setParticipantSelection([...preParticipantResults.data.data]);
    },
    [token]
  );

  const getAllUsersData = useCallback(async () => {
    const storedAllUsers = await axiosGetAllUsersData.get("/?populate=*", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    storedAllUsers?.data && setAllUsers([...storedAllUsers.data]);
  }, [token]);

  const getInvoiceData = useCallback(
    async (query = null) => {
      const invoiceResults = await axiosInvoiceRoute.get("/", {
        params: {
          ...(query === null
            ? { "filters[id_seminar]": seminarInputs.id, populate: "*" }
            : { ...query }),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      invoiceResults?.data?.data &&
        setInvoiceSelection([...invoiceResults.data.data]);
    },
    [token, seminarInputs.id]
  );

  const getWPToken = useCallback(async () => {
    const loginResults = await axiosWordPressLoginRoute.get("/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (loginResults.status === 200) {
      setWPToken(loginResults.data);
    }
  }, [token]);

  const getWPAudienceOptions = useCallback(async () => {
    const audienceResults = await axiosWordPressAudienceRoute.get(
      "?per_page=100",
      {
        headers: {
          Authorization: `Bearer ${WPToken}`,
        },
      }
    );
    if (audienceResults.status === 200 && audienceResults.data) {
      setWordPressAudienceOptions([...audienceResults.data]);
    }
  }, [WPToken]);

  useEffect(() => {
    if (WPToken) {
      getWPAudienceOptions();
    }
  }, [WPToken, getWPAudienceOptions]);

  const getSemPartData = useCallback(async () => {
    const seminarParticipantsListResult =
      await axiosSeminarParticipantRoute.get("/", {
        params: { "filters[id_seminar]": seminarInputs.id, populate: "*" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    seminarParticipantsListResult &&
      setSeminarParticipantsList([...seminarParticipantsListResult.data.data]);
  }, [token, seminarInputs.id]);

  const downloadHandler = useCallback(
    async (type, idselection = [], mode) => {
      const data = {
        type,
        mode,
        idselection,
        searchbyseminarid:
          type === "listfilesbyseminar" ? seminarInputs.id : null,
      };

      try {
        let result;

        result = await axiosDownloadPdfRoute.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          if (type === "listfilesbyseminar" && mode === "list") {
            setFileSelection(result.data);
          }
        }
      } catch (err) {
        notify("info", err.response.data.error.message);
      }
    },
    [seminarInputs.id, token]
  );

  const getData = useCallback(
    async (query = null, type /*signal*/) => {
      const setTime = (params, type = "time") => {
        if (params !== null) {
          let isoDate = params;

          var d = new Date(isoDate);

          if (type === "time") {
            const newtime = d.toUTCString();
            const time = newtime.split(" ")[4].split(":");
            return time[0] + ":" + time[1];
          }
          if (type === "date") {
            return d.toLocaleDateString("en-CA");
          }
        } else {
          return "";
        }
      };

      try {
        const raumeResult = await axiosRaumeRoute.get("/", {
          // signal: signal,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const raumeResultData = raumeResult?.data?.data;

        raumeResultData && setRaumeSelection([...raumeResultData]);

        const referentResult = await axiosReferentRoute.get("/", {
          // signal: signal,
          params: {
            "sort[0]": "name",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const referentResultData = referentResult?.data?.data;

        referentResultData && setReferentSelection([...referentResultData]);

        const seminarListResult = await axiosSeminarRoute.get("/", {
          // signal: signal,
          params: {
            ...((query === null || query === false) && {
              "filters[datum][$gte]": new Date().setHours(0, 0, 0, 0),
              "sort[0]": "datum",
            }),
            ...(query && query),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const seminarListResultData = seminarListResult?.data?.data;

        const seminarReferenteResultData =
          seminarListResult.data?.semRefResults;

        seminarReferenteResultData &&
          setSeminarReferenteSelection([
            ...seminarReferenteResultData.map((semref) => ({
              id: semref.id,
              attributes: {
                ...semref,
                id_referent: semref?.id_referent?.id,
                id_seminar: semref?.id_seminar?.id,
              },
            })),
          ]);

        const detailedseminarlist = seminarListResultData.map((seminar) => {
          const findraume = raumeResultData.find(
            (raume) => raume.id === seminar.attributes.id_raum
          );

          const findreferent = referentResultData.find((referent) =>
            seminarReferenteResultData.some((semref) => {
              return (
                Number(semref?.id_referent?.id) === Number(referent.id) &&
                Number(semref?.id_seminar?.id) === Number(seminar.id)
              );
            })
          );

          return {
            ...seminar,
            attributes: {
              ...seminar.attributes,
              datum_friendly_adjusted: DateTime.fromISO(
                seminar.attributes.datum
              ).toFormat("dd.MM.yyyy"),
              end_datum_friendly_adjusted: DateTime.fromISO(
                seminar.attributes.end_datum
              ).toFormat("dd.MM.yyyy"),
              anfangs_zeit: DateTime.fromISO(seminar.attributes.anfangs_zeit, {
                zone: "utc",
              }),
              end_zeit: DateTime.fromISO(seminar.attributes.end_zeit, {
                zone: "utc",
              }),
              anfangs_zeit_friendly_adjusted: setTime(
                seminar.attributes.anfangs_zeit
              ),
              end_zeit_friendly_adjusted: setTime(seminar.attributes.end_zeit),
              raum_details: findraume
                ? {
                    ...findraume.attributes,
                    bundesland_id: listofGermanStates.find(
                      (state) =>
                        state.province === findraume.attributes.bundesland
                    )?.id,
                  }
                : null,
              referent_details: findreferent
                ? {
                    ...findreferent.attributes,
                    id: findreferent.id,
                    full_name: `${findreferent.attributes.vorname} ${findreferent.attributes.name}`,
                  }
                : null,
            },
          };
        });

        if (type === "getresults" && seminarListResultData) {
          return detailedseminarlist;
        } else {
          seminarListResultData &&
            setSeminarSelection([...detailedseminarlist]);
        }
      } catch (e) {
        return e;
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const getSeminarData = useCallback(
    async (
      datefrom = null,
      dateto = null,
      referentids = [],
      tagoption = null,
      taggedseminars = []
    ) => {
      try {
        const seminarListResult = await axiosSeminarRoute.post(
          "/getbyreferent",
          {
            data: { datefrom, dateto, referentids, tagoption, taggedseminars },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const seminarListResultData = seminarListResult?.data;

        return seminarListResultData;
      } catch (e) {
        return e;
      }
    },
    // eslint-disable-next-line
    [token]
  );

  const getStoredParticipantDetails = useCallback(
    async (clientid) => {
      const storedParticipants = await axiosTeilnehmRoute.get("/", {
        params: {
          "filters[id_amt][$eq]": clientid,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return (
        storedParticipants?.data?.data &&
        // store participants in database to state
        setStoredParticipantSelection([...storedParticipants.data.data])
      );
    },
    [token]
  );

  const getUserDetails = useCallback(async () => {
    try {
      const result = await axiosGetUserData({
        params: { populate: "*" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        // const socket = io("http://localhost:1337", {
        //   auth: {
        //     token: token,
        //   },
        // });

        // socket.on("connect", () => {
        //   socket.on("events", (data) => {
        //     console.log(data);
        //   });
        // });

        setIsAuth(true);
        setUserDetails(result.data);
        getAllUsersData();
      }
    } catch (err) {
      setIsAuth(false);
      setUserDetails(null);
      console.log(err);
    }
  }, [token, getAllUsersData]);

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;

  // this function sets the timer that logs out the user after X seconds
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logOut();
    }, 86400000);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (isAuth && userDetails) {
      if (pathname === "/seminare/semverwaltung") {
        const seminarid = searchParams.get("seminarid");

        if (seminarid) {
          getData({ "filters[id]": seminarid });
          setIdFilter(true);
        } else {
          if (!idFilter && !exactDateFilter && !dateRangeFilter) {
            getData();
          }
        }
      }

      getWPToken();
    }
  }, [
    isAuth,
    pathname,
    userDetails,
    getData,
    searchParams,
    getWPToken,
    idFilter,
    exactDateFilter,
    dateRangeFilter,
  ]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => {
    userDetails && getFutureEmails(emailQueryMode);
  }, [token, getFutureEmails, emailQueryMode, userDetails]);

  useEffect(() => {
    pathname !== "/seminare/semverwaltung" &&
      firstLoad &&
      navigate("/seminare/semverwaltung", { replace: true });
    setFirstLoad(false);
  }, [pathname, firstLoad, navigate]);

  useEffect(() => {
    if (pathname !== "/seminare/semverwaltung") {
      if (editSeminar) {
        setEditSeminarAltert(true);
        navigate("/seminare/semverwaltung");
      }
    }
  }, [pathname, navigate, editSeminar]);

  const logOut = () => {
    cookies.remove("biteg-app-token", { path: "/" });
    setToken(null);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seminarInputs.id) {
      getSemPartData();
    }
  }, [seminarInputs.id, getSemPartData, pathname]);

  useEffect(() => {
    if (seminarInputs.id && pathname !== "/seminare/bestsemverwaltung") {
      getInvoiceData();
    }
  }, [seminarInputs.id, getInvoiceData, pathname]);

  // will define later. To be used Globally when calling updated data
  // const resetAndGet = useCallback(async () => {
  //   getFutureEmails(emailQueryMode);

  //   getData();
  //   getInvoiceData();
  //   // legacy logic for the approval admin
  //   // getParticipantData();
  //   getSemPartData();
  //   downloadHandler("listfilesbyseminar", null, "list");
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, [
  //   getData,
  //   getInvoiceData,
  //   downloadHandler,
  //   // getParticipantData,
  //   getSemPartData,
  //   getFutureEmails,
  //   emailQueryMode,
  // ]);

  return (
    <>
      {isAuth === true && userDetails && (
        <>
          <PageLayout
            logOut={logOut}
            getUserDetails={getUserDetails}
            userDetails={userDetails}
            token={token}
            notify={notify}
            seminarSelection={seminarSelection}
            raumeSelection={raumeSelection}
            referentSelection={referentSelection}
            seminarReferenteSelection={seminarReferenteSelection}
            seminarParticipantsList={seminarParticipantsList}
            seminarInputs={seminarInputs}
            setSeminarInputs={setSeminarInputs}
            getData={getData}
            clientSelection={clientSelection}
            storedClientSelection={storedClientSelection}
            participantSelection={participantSelection}
            storedParticipantSelection={storedParticipantSelection}
            getClientData={getClientData}
            getParticipantData={getParticipantData}
            invoiceSelection={invoiceSelection}
            getInvoiceData={getInvoiceData}
            getAllUsersData={getAllUsersData}
            allUsers={allUsers}
            editSeminar={editSeminar}
            setEditSeminar={setEditSeminar}
            errorHandling={errorHandling}
            setErrorHandling={setErrorHandling}
            downloadHandler={downloadHandler}
            fileSelection={fileSelection}
            emailQueryResults={emailQueryResults}
            getFutureEmails={getFutureEmails}
            getStoredParticipantDetails={getStoredParticipantDetails}
            getStoredClientData={getStoredClientData}
            getSemPartData={getSemPartData}
            setEmailQueryMode={setEmailQueryMode}
            emailQueryMode={emailQueryMode}
            WPToken={WPToken}
            selectSeminarOnFirstLoad={selectSeminarOnFirstLoad}
            setSelectSeminarOnFirstLoad={setSelectSeminarOnFirstLoad}
            searchByClientOrSeminar={searchByClientOrSeminar}
            triggerSeminarSelect={triggerSeminarSelect}
            setTriggerSeminarSelect={setTriggerSeminarSelect}
            idFilter={idFilter}
            dateRangeFilter={dateRangeFilter}
            exactDateFilter={exactDateFilter}
            setIdFilter={setIdFilter}
            setExactDateFilter={setExactDateFilter}
            setDateRangeFilter={setDateRangeFilter}
            getSeminarData={getSeminarData}
            axiosGetEmailListsRoute={axiosGetEmailListsRoute}
            wordPressAudienceOptions={wordPressAudienceOptions}
            axiosCustomQueryRoute={axiosCustomQueryRoute}
          />
          <ToastContainer />
          <Dialog
            open={editSeminarAltert}
            onClose={() => setEditSeminarAltert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Achtung!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Sie haben Ihre Arbeit nicht gespeichert. Bitte speichern Sie
                Ihre Daten, bevor Sie fortfahren.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditSeminarAltert(false)}>Ok</Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {isAuth === false && <Navigate to={{ pathname: "/login" }} />}
    </>
  );
};

export default ProtectedRoute;
