import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useOutletContext } from "react-router-dom";

import { AgGridReact } from "ag-grid-react";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AG_GRID_LOCALE_DE from "./locale.de";

import IconButton from "@mui/material/IconButton";
import SearchButton from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearButton from "@mui/icons-material/Clear";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { DatePicker } from "@mantine/dates";

import SchoolIcon from "@mui/icons-material/School";

const Search = () => {
  const { searchByClientOrSeminar } = useOutletContext();

  const [queryResults, setQueryResults] = useState([]);
  const [query, setQuery] = useState("");
  const [queryParticipant, setQueryParticipant] = useState(null);
  const [queryMode, setQueryMode] = useState("rechnungsnummern");
  const [queryDate, setQueryDate] = useState(null);
  const [loadingDyn, setLoadingDyn] = useState(null);
  const [filterActive, setFilterActive] = useState(false);
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const gridRefSeminar = useRef();

  const filterHandler = useCallback(
    async (e, filter = false) => {
      e && e.preventDefault();
      setLoadingDyn(true);
      setQueryResults([]);

      try {
        if (filter === false) {
          setQuery("");
          setFilterActive(false);
          setQueryDate(null);
          setQueryParticipant(null);
        } else {
          const results = await searchByClientOrSeminar(
            queryMode === "mitarbeiter_kurse" ? queryParticipant.id : query,
            queryDate,
            queryMode
          );

          setFilterActive(true);
          setQueryResults(results);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDyn(false);
      }
    },
    [searchByClientOrSeminar, query, queryDate, queryMode, queryParticipant]
  );

  const convertIsoToLocal = (params) => {
    if (params.value !== null) {
      let isoDate = params.value;

      var d = new Date(isoDate);
      return d.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return "";
    }
  };

  const columnSeminarDefs = useMemo(
    () => [
      {
        field: "id",
        headerName: "Seminar NR",
        sortable: true,
        width: 110,
        minWidth: 100,
        headerClass: "header-blue",
        suppressSizeToFit: true,
      },
      {
        field: "wp_link",
        headerName: "Web",
        width: 50,
        minWidth: 50,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) =>
          params.data.wp_published ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WP_SITE}/${params.value}`}
            >
              <LinkIcon
                fontSize="medium"
                className="cursor-pointer flex h-full"
              />
            </a>
          ) : (
            <LinkOffIcon
              fontSize="medium"
              className="pointer-events-none flex h-full opacity-10"
            />
          ),
        suppressSizeToFit: true,
      },
      {
        field: "datum",
        headerName: "Datum",
        sortable: true,
        sort: "asc",
        width: 110,
        minWidth: 110,
        headerClass: "header-blue",
        cellRenderer: (params) => convertIsoToLocal(params),
        resizable: true,
      },
      {
        field: "end_datum",
        headerName: "Enddatum",
        sortable: true,
        width: 110,
        minWidth: 110,
        headerClass: "header-blue",
        cellRenderer: (params) => convertIsoToLocal(params),
        resizable: true,
      },
      {
        field: "anfangs_zeit",
        headerName: "Anfang",
        sortable: true,
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "end_zeit",
        headerName: "Ende",
        sortable: true,
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "thema",
        headerName: "Seminar-Thema",
        sortable: true,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: (params) => {
          return params.value ? (
            <a href={`/seminare/semverwaltung?seminarid=${params.data.id}`}>
              {params.value}
            </a>
          ) : (
            ""
          );
        },
      },
      {
        field: "raum_details.ort",
        headerName: "Ort",
        sortable: true,
        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "raum_details.bezeichnung",
        headerName: "Räume",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "referent",
        headerName: "Referent/-in",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        valueGetter: (params) => {
          return `${
            params?.data?.referent?.id_referent?.name
              ? params.data.referent.id_referent.name + ","
              : ""
          } ${params?.data?.referent?.id_referent?.vorname || ""}`;
        },
      },
      {
        field: "max_teilnehmer",
        headerName: "TN",
        sortable: true,
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "max_tn_zahl",
        headerName: "Max TN",
        sortable: true,
        width: 70,
        minWidth: 50,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "anzahl_skripten",
        headerName: "Gedruckt",
        sortable: true,
        width: 120,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    []
  );

  const columnClientDefs = useMemo(
    () => [
      {
        sortable: true,
        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        hide: queryMode !== "ort" && queryMode !== "name",
        cellStyle: { display: "flex" },
        cellRenderer: (params) => {
          return params.data ? (
            <button
              className="flex items-center justify-center"
              onClick={() => {
                const { id_teilnehmer, name, vorname } = params.data;
                setQueryParticipant({ id: id_teilnehmer, name, vorname });
                setQueryMode("mitarbeiter_kurse");
              }}
            >
              <SchoolIcon />
            </button>
          ) : (
            ""
          );
        },
      },
      {
        field: "vorname",
        headerName: "Vorname",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
        hide: queryMode !== "ort" && queryMode !== "name",
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
        hide: queryMode !== "ort" && queryMode !== "name",
      },
      {
        field: "ort",
        headerName: "Ort",
        sortable: true,
        sort: "asc",
        width: 115,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bezeichnung",
        headerName: "Bezeichnung",
        sortable: true,

        width: 300,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bezeichnung2",
        width: 120,
        headerName: "Bezeichnung 2",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bundes_land_id",
        headerName: "Bundesland",
        sortable: true,
        width: 85,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "plz",
        headerName: "Plz",
        sortable: true,

        width: 85,
        minWidth: 85,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "strasse",
        headerName: "Straße",
        sortable: true,

        //width: 150,
        minWidth: 150,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "haus_num",
        headerName: "Haus-Nr.",
        sortable: true,
        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "telefon1",
        headerName: "Telefon",
        sortable: true,

        //width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email",
        headerName: "E-Mail",
        sortable: true,

        //width: 200,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    [queryMode]
  );

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  useEffect(() => {
    if (queryMode === "mitarbeiter_kurse" && queryParticipant) {
      filterHandler(null, true);
    }
  }, [queryMode, filterHandler, queryParticipant]);

  return (
    <main className="h-screen flex flex-col">
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Suche</h3>
        </div>
      </div>
      <div className="w-full px-6 py-5 text-sm text-left font-medium text-gray-700 flex-1 flex flex-col">
        <div className="col-span-12 flex">
          <div className="w-3/12 h-25 flex">
            {queryMode === "mitarbeiter_kurse" && queryParticipant ? (
              <div className="whitespace-nowrap flex items-center">
                Kursen von Mitarbeiter: {queryParticipant?.vorname}{" "}
                {queryParticipant?.name}
              </div>
            ) : (
              <>
                <div className="whitespace-nowrap flex items-center">
                  Suche:
                </div>
                <div className="relative w-full items-center flex overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                  <input
                    disabled={loadingDyn}
                    className="ml-5 w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && query.length > 2 && queryMode) {
                        filterHandler(e, true);
                      }
                    }}
                  />

                  <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                    <LoadingButton
                      sx={{ minWidth: "auto", padding: 0 }}
                      disableRipple
                      loading={loadingDyn}
                      disabled={query.length < 2 || !queryMode ? true : false}
                      onClick={(e) => filterHandler(e, true)}
                    >
                      {<SearchButton />}
                    </LoadingButton>
                    {filterActive && loadingDyn === false && (
                      <IconButton
                        color="warning"
                        sx={{ padding: 0 }}
                        disabled={loadingDyn}
                        onClick={(e) => filterHandler(e, false)}
                        disableRipple
                      >
                        <ClearButton />
                      </IconButton>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="ml-10 h-25 w-6/12 flex relative">
            <FormControl>
              <RadioGroup
                onChange={(e) => {
                  setQueryMode(e.target.value);
                  setQueryResults([]);
                  setFilterActive(false);
                  setQueryParticipant(null);
                }}
                row
                value={queryMode}
                className="grid grid-cols-3"
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="rechnungsnummern"
                  control={<Radio size="small" />}
                  label="Rechnungsnummern"
                />
                <FormControlLabel
                  value="telefon1"
                  control={<Radio size="small" />}
                  label="Telefonnummern"
                />
                <FormControlLabel
                  value="ort"
                  control={<Radio size="small" />}
                  label="Mitarbeiter (Ort)"
                />
                <FormControlLabel
                  value="name"
                  control={<Radio size="small" />}
                  label="Mitarbeiter (Name)"
                />
                <FormControlLabel
                  value="bezeichnung"
                  control={<Radio size="small" />}
                  label="Verwaltungsbezeichnung"
                />
                {queryMode === "mitarbeiter_kurse" && (
                  <FormControlLabel
                    value="mitarbeiter_kurse"
                    control={<Radio size="small" />}
                    label="Mitarbeiter (Kurse)"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="ml-10 h-25 w-3/12 flex items-center relative">
            <DatePicker
              disabled={queryMode !== "rechnungsnummern"}
              clearable={true}
              classNames={{ input: "placeholder:text-black" }}
              placeholder="Datum wählen"
              inputFormat="DD/MM/YYYY"
              className="w-full h-[29px] border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
              variant="unstyled"
              // size="xs"
              value={queryDate}
              onChange={(newValue) => {
                setQueryDate(newValue);
              }}
            />
          </div>
        </div>

        <div
          style={{ height: "79vh" }}
          className="ag-theme-alpine w-full col-span-12"
        >
          <AgGridReact
            getRowId={getRowId}
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowData={queryResults}
            rowHeight={33}
            columnDefs={
              queryMode === "rechnungsnummern" ||
              queryMode === "mitarbeiter_kurse"
                ? columnSeminarDefs
                : columnClientDefs
            }
            rowSelection={"single"}
            // onRowClicked={onSeminarSelectionChanged}
            ref={gridRefSeminar}
            localeText={localeText}
            paginationPageSize={90}
            pagination={true}
            // suppressRowClickSelection
            onGridSizeChanged={(e) => {
              e.api.sizeColumnsToFit();
            }}
          ></AgGridReact>
        </div>
      </div>
    </main>
  );
};

export default Search;
