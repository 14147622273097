export default function emailtemplates(
  seminarinputs,
  emailinputs,
  invoiceInputs,
  participants,
  userDetails
) {
  const convertToLocal = (datetype, dateinput) => {
    const date = new Date(dateinput);

    const isvalid = isNaN(Date.parse(dateinput));

    if (datetype === "time") {
      let time = date.toUTCString().split(" ")[4].split(":");
      return time[0] + ":" + time[1];
    } else if (datetype === "day" && isvalid === false) {
      return date.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return undefined;
    }
  };

  const invoiceNumber = invoiceInputs?.attributes?.rechnungsNum || "";

  const paymentduedate = seminarinputs?.zahl_termin
    ? convertToLocal("day", seminarinputs.zahl_termin)
    : "";

  const participantDetails = participants || [];

  const name = `${seminarinputs?.thema}` || "";
  const today = convertToLocal("day", new Date());

  const startdate = seminarinputs?.datum
    ? convertToLocal("day", seminarinputs?.datum)
    : "";

  const enddate = seminarinputs?.datum
    ? convertToLocal("day", seminarinputs?.end_datum)
    : "";

  const date = `${
    startdate < enddate ? `${startdate} bis ${enddate}` : `${startdate}`
  }`;

  const newdate = emailinputs?.neu_datum
    ? convertToLocal("day", emailinputs?.neu_datum)
    : "";

  const starttime = seminarinputs?.anfangs_zeit
    ? convertToLocal("time", seminarinputs?.anfangs_zeit)
    : "";
  const endtime = seminarinputs?.end_zeit
    ? convertToLocal("time", seminarinputs?.end_zeit)
    : "";
  const city =
    seminarinputs?.id_raum === 423
      ? "Online"
      : "in " + seminarinputs?.raum_details?.ort || "";

  const referentname = `${seminarinputs?.referent_details?.vorname || ""} ${
    seminarinputs?.referent_details?.name || ""
  }`;

  const username = userDetails;

  const referenttitle = `${seminarinputs?.referent_details?.titel || ""}`;

  const referentgender = `${seminarinputs?.referent_details?.anrede || ""}`;

  const newlocation =
    emailinputs?.neuRaume?.id === 423
      ? "Online"
      : `${emailinputs?.neuRaume?.attributes?.bezeichnung || ""}, ${
          emailinputs?.neuRaume?.attributes?.strasse || ""
        } ${emailinputs?.neuRaume?.attributes?.haus_nr || ""}, ${
          emailinputs?.neuRaume?.attributes?.plz || ""
        } ${emailinputs?.neuRaume?.attributes?.ort || ""}`;

  const oldlocation =
    seminarinputs?.id_raum === 423
      ? "Online"
      : `${seminarinputs?.raum_details?.bezeichnung || ""}, ${
          seminarinputs?.raum_details?.strasse || ""
        } ${seminarinputs?.raum_details?.haus_nr || ""}, ${
          seminarinputs?.raum_details?.plz || ""
        } ${seminarinputs?.raum_details?.ort || ""}`;

  let subject = "";
  let body = "";

  switch (emailinputs?.reason) {
    case "teilnahmebest":
      subject = `Informationen und Bestätigung Ihrer Teilnahme am BITEG-Seminar "${name}" am ${date}, ${city}`;
      body = `
      Sie sind zum BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. Das Seminar findet statt. 
      <br><br>
      Eine Rechnung zu Ihrer Seminarteilnahme haben wir separat an Ihre Verwaltung geschickt. Fragen zum Seminar können Sie uns bis spätestens 7 Tage vor dem Seminar an die E-Mail-Adresse: info@biteg.de schicken. 
      <br><br>
      Zugangsdaten für das Online-Seminar erhalten Sie wenige Tage vor dem Seminartermin.
      <br><br>
      Wir wünschen Ihnen ein informatives Seminar.
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "referenten":
      subject = `Verschiebung BITEG-Seminar "${name}" am ${date}, ${city}`;
      body = `
      Sie sind für das BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. 
      <br><br>
      Leider müssen wir das Seminar aufgrund terminlicher Probleme ${
        referentgender === "m"
          ? "des Dozenten,"
          : referentgender === "f"
          ? "der Dozentin,"
          : "der Dozent,"
      } ${referenttitle} ${referentname}, verschieben. Der neue Termin ${
        newdate ? `ist der ${newdate}` : "wird baldmöglichst bekanntgegeben"
      }. Seminarort, Seminardauer und die Seminargebühr ändern sich nicht. 
      <br><br>
      <b>Bitte geben Sie uns in jedem Fall eine Rückmeldung, ob Sie an dem neuen Termin teilnehmen können oder nicht.</b>
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "erkrankung":
      subject = `Absage BITEG-Seminar "${name}" am ${date}, ${city}`;
      body = `
      Sie sind für das BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. 
      <br><br>
      Leider müssen wir das Seminar aufgrund einer Erkrankung ${
        referentgender === "m"
          ? "des Dozenten,"
          : referentgender === "f"
          ? "der Dozentin,"
          : "der Dozent,"
      } ${referenttitle} ${referentname}, kurzfristig absagen. 
      <br><br>
      Sobald ein Ersatztermin feststeht, werden wir Sie informieren. 
      Bitte geben Sie uns in jedem Fall Bescheid, dass Sie die Absage des Seminars rechtzeitig erhalten haben. 
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}
    `;
      break;

    case "neuraum":
      subject = `Verlegung des Veranstaltungsortes "${name}" am ${date}, ${city} `;
      body = `
      Sie sind für das BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. 
      <br><br>
      Leider müssen wir aus organisatorischen Gründen den Veranstaltungsort für das Seminar verlegen. Der neue Veranstaltungsort ist jetzt: ${newlocation}. 
      <br><br>
      Der Seminarbeginn, die Seminardauer und die Seminargebühr ändern sich nicht. 
      <br><br>
      <b>Bitte geben Sie uns in jedem Fall Bescheid, dass Sie die Ortsverschiebung rechtzeitig erhalten haben.</b>
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "sonst":
      subject = `Verschiebung BITEG-Seminar "${name}" am ${date}, ${city} ${
        newdate ? `auf den ${newdate}` : ""
      }`;
      body = `
      Sie sind für das Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. 
      <br><br>
      Leider müssen wir das Seminar aus folgenden Gründen ... verschieben. Der neue Termin ${
        newdate ? `ist der ${newdate}` : "wird baldmöglichst bekanntgegeben"
      }. Seminarort, Seminardauer und Seminargebühr ändern sich nicht.
      <br><br>
      Bitte geben Sie uns in jedem Fall Bescheid, ob Sie an dem neuen Termin teilnehmen können oder nicht. 
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "zuwenig":
      subject = `Absage BITEG-Seminar "${name}" am ${date}, ${city} aufgrund zu geringer Nachfrage`;
      body = `
      Sie sind für das Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, angemeldet. 
      <br><br>
      Leider müssen wir das Seminar aufgrund zu geringer Nachfrage absagen. 
      <br><br>
      Falls wir es zu einem späteren Zeitpunkt erneut ausschreiben, teilen wir Ihnen gerne den neuen Termin mit. 
      <br><br>
      <b>Bitte geben Sie uns unbedingt eine Rückmeldung, dass Sie die Absage rechtzeitig erhalten haben.</b>
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "bestdurchreg":
      subject = `Informationen und Bestätigung über Durchführung des BITEG-Seminars: "${name}" (am ${date}, ${city})`;
      body = `
      Ihr BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, findet statt.
      <br><br>
      <b>Bitte schicken Sie die Seminarunterlagen bis spätestens 10 Tage vorm Seminartermin an folgende E-Mail-Adresse: druck@biteg.de</b>
      <br><br>
      Bitte denken Sie daran vor dem Seminar Ihren Laptop und evtl. benötigte Software (ZOOM, Powerpoint, etc.) auf deren Aktualität zu prüfen.
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}
    `;
      break;

    case "infoanrefsemabsage":
      subject = `Absage Ihres BITEG-Seminars "${name}" (am ${date}, ${city})`;
      body = `
      Leider müssen wir Ihr BITEG-Seminar <b>"${name}"</b> am <b>${date}</b>, von ${starttime} bis ${endtime} Uhr, <b>${oldlocation}</b>, absagen.
      <br><br>
      Wir haben nicht genügend Teilnehmer zusammen bekommen. Bitte nutzen Sie den Termin anderweitig.
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}`;
      break;

    case "paymentreminderparticipantyes":
      subject = `Zahlungserinnerung zur Rechnung ${invoiceNumber}`;
      body = `
      Sehr geehrte Damen und Herren,
      <br><br>
      am o.g. Seminar hat ${participantDetails
        .map(
          ({
            attributes: {
              id_teilnehmer: {
                data: {
                  attributes: { vorname, name },
                },
              },
            },
          }) => `${vorname} ${name}`
        )
        .join(
          ", "
        )} teilgenommen. Nach den mir per ${today} vorliegenden Informationen ist die Gebühr noch nicht auf dem BITEG-Konto eingegangen. Der Termin zur Überweisung war der ${paymentduedate}.
      <br><br>
      Ich schließe nicht aus, dass Ihre Verwaltung die Rechnung bereits bezahlt hat. Wenn dies jedoch ohne Angabe der Rechnungs-Nummer ${invoiceNumber} erfolgte, ist die Zahlung für unsere Buchhaltung nicht nachvollziehbar. Bitte achten Sie darauf, dass bei der Überweisung immer die Rechnungsnummer angegeben wird.
      <br><br>
      Wenn die Überweisung
      <br>
      - noch nicht erfolgt ist, bitte ich Sie um schnelle Veranlassung.<br>
      - bereits erfolgt ist, dann teilen Sie uns bitte das Überweisungsdatum mit.
      <br><br>
      Mit freundlichen Grüßen<br>
      ${username}
      `;
      break;

    case "storno_rechnung":
      subject = `Stornorechnung zum Seminar "${name}" (am ${date}, ${city})`;
      body = `
        Sehr geehrte Damen und Herren,
        <br><br>
        im Anhang finden Sie die Stornorechnung zum oben genannten Seminar.
        <br><br>
        Mit freundlichen Grüßen<br>
        ${username}`;

      break;

    case "zertifikat_nachsendung":
      subject = `Teilnahmezertifikat zum BITEG-Seminar "${name}" am ${date}, ${city}`;
      body = `
      vielen Dank für Ihre Teilnahme am Online-Seminar. Im Anhang finden Sie Ihr Teilnahme-Zertifikat.
      <br/><br/>
      Über folgenden Link können Sie das Seminar bewerten. Sie helfen uns damit, die Qualität der Seminare zu verbessern.
      <br/><br/>
      Bewertungs-Link: 
      <br/><br/>
      Unser Seminarprogramm auf unserer Homepage wird regelmäßig erweitert. Empfehlen Sie uns gerne weiter.
      <br/><br/>
      Bei Fragen stehen wir Ihnen gerne zur Verfügung.
      <br/><br/>
      Mit freundlichen Grüßen<br>
      ${username}`;

      break;

    case "paymentreminderparticipantno":
      subject = `Zahlungserinnerung zur Rechnung ${invoiceNumber}`;
      body = `
      Sehr geehrte Damen und Herren,
      <br><br>
      am o.g. Seminar hat ${participantDetails
        .map(
          ({
            attributes: {
              id_teilnehmer: {
                data: {
                  attributes: { vorname, name },
                },
              },
            },
          }) => `${vorname} ${name}`
        )
        .join(
          ", "
        )} nicht teilgenommen, ohne dass die Teilnahme vorher storniert wurde. Die Teilnahmebedingungen (s. Seminareinladung, letzter Absatz) sehen in dem Falle vor, dass die volle Seminargebühr zu zahlen ist.
        <br><br>
        Der Zahlungstermin für die Teilnahmegebühr war der ${paymentduedate} (s. Anlage). Nach den mir vorliegenden Informationen wurde sie noch nicht überwiesen (Stand ${today}). Ich schließe nicht aus, dass Ihre Verwaltung die Rechnung bereits bezahlt hat. Wenn dies jedoch ohne Angabe der Rechnungs-Nummer ${invoiceNumber} erfolgte, ist die Zahlung für unsere Buchhaltung nicht nachvollziehbar. Bitte achten Sie darauf, dass bei der Überweisung immer die Rechnungsnummer angegeben wird.
        <br><br>
        Wenn die Überweisung
        <br>
        - noch nicht erfolgt ist, bitte ich Sie um schnelle Veranlassung.<br>
        - bereits erfolgt ist, dann teilen Sie uns bitte das Überweisungsdatum mit.
        <br><br>
        Mit freundlichen Grüßen<br>
        ${username}`;
      break;
    default:
      subject = "";
      body = "";
  }
  return { subject, body };
}

// Betreff: Stornorechnung zum Seminar XXXXX am xx.xx.xxxx, Ort
// Sehr geehrte Damen und Herren,
// im Anhang finden Sie die Stornorechnung zum oben genannten Seminar.
// Mit freundlichen Grüßen
