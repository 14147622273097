import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";
import { axiosReferentRoute } from "../helpers/axios";
import EditIcon from "@mui/icons-material/Edit";
import ContentConpyIcon from "@mui/icons-material/ContentCopy";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Fuse from "fuse.js";

import { validateMe } from "../helpers/validation";

import { AgGridReact } from "ag-grid-react";

import ReferentenModal from "./ReferentenModal";

import AG_GRID_LOCALE_DE from "./locale.de";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [onlyActiveReferente, setOnlyActiveReferente] = useState(true);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const {
    token,
    notify,
    referentSelection,
    getData,
    errorHandling,
    setErrorHandling,
  } = useOutletContext();

  const [referentInputs, setReferentInputs] = useState({});
  const [formErrorHandling, setFormErrorHandling] = useState(null);
  const [query, setQuery] = useState("");

  const [referenteQueryResults, setReferenteQueryResults] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleChange = (value, name, type) => {
    setReferentInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  const formHandler = async (event, open) => {
    event.preventDefault();
    setLoading(true);
    try {
      let result;

      const data = {
        ...referentInputs,
      };

      if (open === "edit") {
        result = await axiosReferentRoute.put(
          `/${referentInputs.id}`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (open === "add" || open === "copy") {
        const { id, ...noid } = referentInputs;

        result = await axiosReferentRoute.post(
          "/",
          {
            data: {
              ...(open === "copy" ? noid : referentInputs),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (result?.data) {
        notify(
          "info",
          `${open === "edit" ? "Aktualisiert" : "Hinzugefügt"} Referenten`
        );

        setOpen(false);
        setLoading(false);
        setReferentInputs({});
        setFormErrorHandling(null);
        setErrorHandling({});
        getData();
      }
    } catch (err) {
      setFormErrorHandling(err.response.data.error.details.errors);
      console.log(err.response);
      setLoading(false);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "edit",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: () => (
          <EditIcon
            fontSize="small"
            className="cursor-pointer flex h-full"
            onClick={() => setOpen("edit")}
          />
        ),
      },
      {
        field: "copy",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: () => (
          <ContentConpyIcon
            fontSize="small"
            className="cursor-pointer h-full flex"
            onClick={() => setOpen("copy")}
          />
        ),
      },
      {
        field: "titel",
        headerName: "Titel",
        sortable: true,
        width: 75,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        width: 100,
        sort: "asc",
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "vorname",
        headerName: "Vorname",
        sortable: true,
        width: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "ist_aktuell",
        headerName: "Aktiv",
        sortable: true,
        //
        width: 70,
        minWidth: 70,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: (params) => <div>{params.value ? "Ja" : "Nein"}</div>,
        hide: onlyActiveReferente,
      },
      {
        field: "priv_ort",
        headerName: "Ort (Priv.)",
        sortable: true,
        width: 130,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "telefon_privat",
        headerName: "Telefon privat",
        sortable: true,
        width: 150,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "mobiltelefon",
        headerName: "Mobiltelefon",
        sortable: true,
        width: 150,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email",
        headerName: "E-Mail",
        sortable: true,
        width: 180,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    [onlyActiveReferente]
  );

  const gridRef = useRef();
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedReference = selectedRows.length === 1 ? selectedRows[0] : "";
    setReferentInputs(selectedReference);
  }, []);

  useEffect(() => {
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: [
        "attributes.vorname",
        "attributes.name",
        "attributes.email",
        "attributes.createdAt",
      ],
    };
    const fuse = new Fuse(referentSelection, options);
    if (referentSelection?.length > 0) {
      setReferenteQueryResults(
        fuse.search(
          query !== ""
            ? {
                $or: [
                  {
                    $path: ["attributes.vorname"],
                    $val: query,
                  },
                  {
                    $path: ["attributes.name"],
                    $val: query,
                  },
                  {
                    $path: ["attributes.email"],
                    $val: query,
                  },
                ],
              }
            : {
                "attributes.createdAt": "'2",
              }
        )
      );
    } else {
      setReferenteQueryResults([]);
    }

    // eslint-disable-next-line
  }, [query, referentSelection]);

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  return (
    <main className="h-screen flex flex-col">
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Referentenverwaltung</h3>
          <button
            onClick={() => {
              setOpen("add");
            }}
            className="addButton"
          >
            Hinzufügen
          </button>
        </div>
      </div>
      <div className="w-full px-6 py-5 text-sm text-left font-medium text-gray-700 flex-1 flex flex-col">
        <div className="col-span-12 flex mb-4 justify-between">
          <div className="w-3/12 flex mr-5">
            <div className="whitespace-nowrap flex items-center">Suche:</div>
            <input
              className="ml-5 h-min w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              label="Nur aktiv"
              control={
                <Switch
                  checked={onlyActiveReferente}
                  onChange={() =>
                    setOnlyActiveReferente((prior) => (prior ? false : true))
                  }
                />
              }
            />
          </FormGroup>
        </div>

        <div className="ag-theme-alpine w-full h-full">
          {
            <AgGridReact
              getRowId={getRowId}
              enableCellTextSelection
              rowHeight={33}
              defaultColDef={{
                filter: false,
              }}
              rowData={referenteQueryResults
                .filter((filterforactive) =>
                  onlyActiveReferente === false
                    ? true
                    : filterforactive.item.attributes.ist_aktuell
                )
                .map((referent) => ({
                  ...referent.item.attributes,
                  id: referent.item.id,
                }))}
              columnDefs={columnDefs}
              rowSelection={"single"}
              onRowClicked={onSelectionChanged}
              ref={gridRef}
              localeText={localeText}
              onModelUpdated={(e) => {
                e.api.sizeColumnsToFit();
              }}
            ></AgGridReact>
          }
        </div>
      </div>

      <ReferentenModal
        open={open}
        setOpen={setOpen}
        getData={getData}
        referentInputs={referentInputs}
        referentSelection={referentSelection}
        setReferentInputs={setReferentInputs}
        handleChange={handleChange}
        formHandler={formHandler}
        formErrorHandling={formErrorHandling}
        setFormErrorHandling={setFormErrorHandling}
        loading={loading}
        errorHandling={errorHandling}
        setErrorHandling={setErrorHandling}
      />
    </main>
  );
};

export default Dashboard;
