import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Raume from "./modules/Raume";
import Referenten from "./modules/Referenten";
import Kundenverwaltung from "./modules/Kundenverwaltung";
import ApprovalAdmin from "./modules/ApprovalAdmin";
// import Register from "./Register";
import Login from "./Login";
import Search from "./modules/Search";
import ProtectedRoute from "./ProtectedRoute";
import EditSeminar from "./modules/EditSeminar";
import Rechnungen from "./modules/Rechnungen";
import Options from "./modules/Options";
import EmailExport from "./modules/EmailExport";
import EmailPreview from "./modules/EmailPreview";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}

        <Route element={<ProtectedRoute />}>
          <Route
            path="/seminare/approvaladmin"
            element={<ApprovalAdmin filter={"approveadmin"} />}
          />
          <Route
            path="/seminare/semverwaltung"
            element={<EditSeminar filter={"currentmonth"} />}
          />
          <Route
            path="/seminare/bestsemverwaltung"
            element={<EditSeminar filter={"confirmed"} />}
          />
          <Route
            path="/seminare/seminarzusammenfassung"
            element={<EditSeminar filter={"all"} />}
          />

          <Route path="/seminare/raume/verwaltung" element={<Raume />} />
          <Route
            path="/seminare/referenten/verwaltung"
            element={<Referenten />}
          />
          <Route path="/suche" element={<Search />} />

          <Route
            path="/kunden/verwaltung"
            element={<Kundenverwaltung mode={"clientedit"} />}
          ></Route>

          <Route path="/rechnungen" element={<Rechnungen />} />

          <Route path="/emailexport" element={<EmailExport />} />

          <Route path="/emailpreview" element={<EmailPreview />} />

          <Route path="/options" element={<Options />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/seminare/semverwaltung" replace />}
        />
      </Routes>
    </Router>
  );
}

export default App;
