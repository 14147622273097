// List of States. The ID corresponds to the "Bundesländer" taxonomy on the WordPress end.

const listofGermanStates = [
  { province: "Berlin", id: "5" },
  { province: "Brandenburg", id: "45" },
  { province: "Bayern", id: "4" },
  { province: "Niedersachsen", id: "23" },
  { province: "Baden-Württemberg", id: "2" },
  { province: "Hessen", id: "14" },
  { province: "Nordrhein-Westfalen", id: "24" },
  { province: "Mecklenburg-Vorpommern", id: "22" },
  { province: "Hamburg", id: "12" },
  { province: "Bremen", id: "6" },
  { province: "Rheinland-Pfalz", id: "30" },
  { province: "Sachsen", id: "32" },
  { province: "Thüringen", id: "39" },
  { province: "Sachsen-Anhalt", id: "33" },
  { province: "Schleswig-Holstein", id: "34" },
  { province: "Saarland", id: "31" },
  { province: "online", id: "25" },
];

export default listofGermanStates;
