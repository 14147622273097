import { useEffect } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import CircularProgress from "@mui/material/CircularProgress";

import { checkZip } from "../helpers/validation";

import { HTMLEditor } from "./partials/HTMLEditor";

export default function RaumeModal({
  open,
  setOpen,
  raumeInputs,
  setRaumeInputs,
  handleChange,
  listofGermanStates,
  formHandler,
  formErrorHandling,
  setFormErrorHandling,
  loading,
  validateMe,
  errorHandling,
  setErrorHandling,
}) {
  useEffect(
    () =>
      open === "add" &&
      setRaumeInputs({
        bundesland: "Berlin",
        bundesland_id: "5",
        is_aktiv: true,
      }),
    [open, setRaumeInputs]
  );

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const disablebutton =
    errorHandling?.telefon === false ||
    errorHandling?.email === false ||
    !raumeInputs.bezeichnung ||
    !raumeInputs.ort ||
    !raumeInputs.strasse ||
    !raumeInputs.haus_nr ||
    !raumeInputs.telefon ||
    !raumeInputs.ansprechpartner ||
    !raumeInputs.plz ||
    !checkZip(raumeInputs.plz);

  return (
    <Modal
      open={
        ((open === "edit" || open === "copy") &&
          Object.keys(raumeInputs).length > 0) ||
        open === "add"
          ? true
          : false
      }
      aria-describedby="modal-modal-description"
      onClose={() => {
        //  setOpen(false);
        setFormErrorHandling(null);
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="h-screen flex items-center justify-center"
      >
        <div
          id="modal-modal-description"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="relative  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full">
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 px-10 py-5 pb-4 w-full">
              <div className="col-span-12 border-b border-lightgray pb-5 flex justify-between">
                <h3>
                  {open === "edit" ? "Raume aktualisieren" : "Raume hinzufügen"}
                </h3>
                {open === "edit" ? (
                  <div className="w-40 flex">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={raumeInputs.is_aktiv}
                            onChange={(e) =>
                              handleChange(e.target.checked, "is_aktiv")
                            }
                          />
                        }
                      />
                    </FormGroup>
                    <div className="flex items-center">
                      {raumeInputs.is_aktiv ? "Aktiv" : "Inaktiv"}
                    </div>
                  </div>
                ) : null}
              </div>
              <span
                className={`col-span-2 flex items-end ${
                  determineIfError("bezeichnung") || !raumeInputs.bezeichnung
                    ? "text-red-600"
                    : ""
                }`}
              >
                Bezeichnung:
              </span>
              <div className="col-span-4 flex items-end flex-col">
                <input
                  name="bezeichnung"
                  variant="outlined"
                  className={`h-min w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm 
                              ${
                                determineIfError("bezeichnung") ||
                                !raumeInputs.bezeichnung
                                  ? "border-red-600"
                                  : ""
                              }`}
                  value={raumeInputs.bezeichnung || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                />
              </div>
              <span className="col-span-2">Bundesland:</span>
              <div className="col-span-4 p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0">
                <select
                  name="bundesland"
                  value={raumeInputs.bundesland || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  className="w-full background-transparant border-0 sm:text-sm focus-within:ring-0 p-0 bg-transparent"
                >
                  {listofGermanStates.map((state) => (
                    <option value={state.province} key={state.id}>
                      {state.province}
                    </option>
                  ))}
                </select>
              </div>
              {determineIfError("bezeichnung") ===
              "This attribute must be unique" ? (
                <>
                  <div className="col-span-2 h-min"></div>
                  <div className="col-span-10 text-red-600 text-sm h-min">
                    Dieser Name wird bereits verwendet
                  </div>
                </>
              ) : null}
              <span
                className={`col-span-2 flex items-end 
                ${
                  !checkZip(raumeInputs.plz) ||
                  !raumeInputs.plz ||
                  determineIfError("plz")
                    ? "text-red-600"
                    : ""
                }
                `}
              >
                PLZ:
              </span>
              <input
                name="plz"
                variant="outlined"
                type="number"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  !checkZip(raumeInputs.plz) ||
                  !raumeInputs.plz ||
                  determineIfError("plz")
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.plz || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
              <span
                className={`col-span-2 flex items-end ${
                  determineIfError("ort") || !raumeInputs.ort
                    ? "text-red-600"
                    : ""
                }`}
              >
                Ort:
              </span>
              <input
                name="ort"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  !raumeInputs.ort ? "border-red-600" : ""
                }`}
                value={raumeInputs.ort || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
              <span
                className={`col-span-2 flex items-end ${
                  determineIfError("strasse") || !raumeInputs.strasse
                    ? "text-red-600"
                    : ""
                }`}
              >
                Straẞe:
              </span>
              <input
                name="strasse"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  determineIfError("strasse") || !raumeInputs.strasse
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.strasse || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
              <span
                className={`col-span-2 flex items-end ${
                  determineIfError("haus_nr") || !raumeInputs.haus_nr
                    ? "text-red-600"
                    : ""
                }`}
              >
                Haus-Nr.:
              </span>
              <input
                name="haus_nr"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  determineIfError("haus_nr") || !raumeInputs.haus_nr
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.haus_nr || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("haus_nr")}
              />
              <span className="col-span-2">Koordinaten:</span>
              <input
                name="coordinates"
                variant="outlined"
                className="col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                value={raumeInputs.coordinates || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
              <span className="col-span-2 whitespace-nowrap">
                Brutto-Raumpreis:
              </span>
              <input
                name="raumpreis_euro"
                type="number"
                variant="outlined"
                className="col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                value={raumeInputs.raumpreis_euro || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
              />
              <span
                className={`col-span-2 flex items-end ${
                  errorHandling?.telefon === false || !raumeInputs.telefon
                    ? "text-red-600"
                    : ""
                }`}
              >
                Telefon:
              </span>
              <input
                name="telefon"
                variant="outlined"
                type="tel"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("telefon") ||
                  errorHandling?.telefon === false ||
                  !raumeInputs.telefon
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.telefon || ""}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name, "phone");
                }}
                error={determineIfError("telefon")}
              />

              <span
                className={`col-span-2 flex items-end ${
                  errorHandling?.email === false ? "text-red-600" : ""
                }`}
              >
                E-Mail:
              </span>
              <input
                name="email"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm ${
                  determineIfError("email") || errorHandling?.email === false
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.email || ""}
                onChange={(e) => {
                  handleChange(e.target.value, e.target.name, "email");
                }}
                error={determineIfError("email")}
              />
              <span
                className={`col-span-2 flex items-end ${
                  determineIfError("ansprechpartner") ||
                  !raumeInputs.ansprechpartner
                    ? "text-red-600"
                    : ""
                }`}
              >
                Ansprechpartner:
              </span>
              <input
                name="ansprechpartner"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  determineIfError("ansprechpartner") ||
                  !raumeInputs.ansprechpartner
                    ? "border-red-600"
                    : ""
                }`}
                value={raumeInputs.ansprechpartner || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("ansprechpartner")}
              />

              <span
                className={`col-span-2 ${
                  determineIfError("internet") ? "text-red-600" : ""
                }`}
              >
                Internet:
              </span>
              <input
                name="internet"
                variant="outlined"
                className={`col-span-4 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm  ${
                  determineIfError("internet") ? "border-red-600" : ""
                }`}
                value={raumeInputs.internet || ""}
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                error={determineIfError("internet")}
              />
              <div
                className={`col-span-6 flex items-end text-lg font-bold h-10 ${
                  determineIfError("ausgabetext") ? "text-red-600" : ""
                }`}
              >
                Ausgabetext (bis 500 Zeichen)
              </div>
              <div
                className={`col-span-6 flex items-end text-lg font-bold h-10 ${
                  determineIfError("infotext") ? "text-red-600" : ""
                }`}
              >
                Informationen (bis 1000 Zeichen)
              </div>
              <div
                className={`col-span-6 relative rounded-lg shadow-sm ${
                  determineIfError("ausgabetext")
                    ? "border-red-600 border-2"
                    : ""
                }`}
              >
                <HTMLEditor
                  value={raumeInputs.ausgabetext || ""}
                  onChange={(value) => handleChange(value, "ausgabetext")}
                  readOnly={false}
                />
              </div>
              <div
                className={`col-span-6 relative rounded-lg shadow-sm ${
                  determineIfError("infotext") ? "border-red-600 border-2" : ""
                }`}
              >
                <HTMLEditor
                  value={raumeInputs.infotext || ""}
                  onChange={(value) => handleChange(value, "infotext")}
                  readOnly={false}
                />
              </div>
            </div>

            <div className="bg-gray-50 py-3 px-6 flex justify-end">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setOpen(false);
                  setFormErrorHandling(null);
                  setErrorHandling({});
                }}
              >
                Abbrechen
              </button>
              <button
                disabled={disablebutton}
                onClick={(event) => formHandler(event, open)}
                //className="ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loading === true
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-40"
                } ml-5 disabled:bg-primary-500 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              >
                {open === "edit" ? "Aktualisieren" : "Hinzufügen"}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
