import React, { useMemo, useState, useCallback, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_DE from "./locale.de";
import { axiosGetAllUsersData } from "../helpers/axios";

import EditIcon from "@mui/icons-material/Edit";

import UserModal from "./UserModal";

import { validateMe } from "../helpers/validation";

const Options = () => {
  const [open, setOpen] = useState(false);
  const [openTab, setOpenTab] = useState(1);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const {
    token,
    notify,
    getData,
    getAllUsersData,
    allUsers,
    errorHandling,
    setErrorHandling,
  } = useOutletContext();

  const [userInputs, setUserInputs] = useState({
    username: "",
    email: "",
    password: "",
    confirmed: true,
    role: 1,
    smtp_server: "",
    smtp_username: "",
    smtp_password: "",
    smtp_port: "",
  });
  const [formErrorHandling, setFormErrorHandling] = useState(null);

  const [tryDelete, setTryDelete] = useState(false);

  const currentUserHandler = (e) => {
    const id = Number(e.target.value);

    const filterUserById = allUsers.filter((user) => user.id === id)[0];

    setUserInputs({ ...filterUserById.attributes, id: filterUserById.id });
  };

  const handleChange = (value, name, type) => {
    setUserInputs((values) => ({ ...values, [name]: value }));

    const valid = validateMe(type, value);

    setErrorHandling((prevState) => {
      return { ...prevState, [name]: valid };
    });
  };

  const formHandler = async (event, open) => {
    event.preventDefault();

    try {
      let result;

      if (open === "edit") {
        result = await axiosGetAllUsersData.put(
          `/${userInputs.id}`,
          {
            // data: {
            ...(userInputs.username && { username: userInputs.username }),
            ...(userInputs.email && { email: userInputs.email }),
            ...(userInputs.phone && { phone: userInputs.phone }),
            ...(userInputs.password && { password: userInputs.password }),
            ...(userInputs.role && { role: userInputs.role.id }),
            ...(userInputs.smtp_server && {
              smtp_server: userInputs.smtp_server,
            }),
            ...(userInputs.smtp_username && {
              smtp_username: userInputs.smtp_username,
            }),
            ...(userInputs.smtp_password && {
              smtp_password: userInputs.smtp_password,
            }),
            ...(userInputs.smtp_port && { smtp_port: userInputs.smtp_port }),
            // },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (open === "add") {
        result = await axiosGetAllUsersData.post(
          "/",
          {
            // data: {
            username: userInputs.username,
            email: userInputs.email,
            phone: userInputs.phone,
            password: userInputs.password,
            confirmed: true,
            role: userInputs.role,
            smtp_server: userInputs.smtp_server,
            smtp_username: userInputs.smtp_username,
            smtp_password: userInputs.smtp_password,
            smtp_port: userInputs.smtp_port,
            // },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (result?.data) {
        notify(
          "info",
          `${open === "edit" ? "Aktualisiert" : "Hinzugefügt"} User`
        );

        setOpen(false);
        setUserInputs({});
        setOpenTab(1);
        setFormErrorHandling(null);
        getData();
        getAllUsersData();
        setErrorHandling({});
      }
    } catch (err) {
      setFormErrorHandling(err.response.data.error);
      console.log(err.response.data.error);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const deliteHandler = async () => {
    try {
      let result;

      result = await axiosGetAllUsersData.delete(`/${userInputs.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data) {
        notify("info", `Benutzer gelöscht`);

        setOpen(false);
        setUserInputs({});
        setFormErrorHandling(null);
        setOpenTab(1);
        getData();
        getAllUsersData();
        setTryDelete(false);
        setErrorHandling({});
      }
    } catch (err) {
      setFormErrorHandling(err.response.data.error.details.errors);
      console.log(err.response);
      notify("error", "Etwas ist schief gelaufen...");
    }
  };
  const [columnDefs] = useState([
    {
      field: "edit",
      headerName: "Bearbeiten",
      width: 90,
      minWidth: 90,
      headerClass: "header-blue",
      resizable: true,
      cellRenderer: () => (
        <EditIcon className="cursor-pointer" onClick={() => setOpen("edit")} />
      ),
    },
    {
      field: "id",
      headerName: "id",
      sortable: true,

      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "username",
      headerName: "Username",
      sortable: true,

      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "email",
      headerName: "E-Mail",
      sortable: true,

      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "role.name",
      headerName: "Rolle",
      sortable: true,

      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const gridRef = useRef();
  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedUser = selectedRows.length === 1 ? selectedRows[0] : "";
    setUserInputs(selectedUser);
  }, []);

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between mx-6 mt-4 h-min">
          <h3>Options</h3>
          <button
            className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            onClick={() => setOpen("add")}
          >
            Hinzufügen
          </button>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-x-6 gap-y-4 px-6 py-5 text-sm text-left font-medium text-gray-700">
        <div
          style={{ height: "85vh" }}
          className="ag-theme-alpine w-full col-span-6"
        >
          <AgGridReact
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowData={allUsers}
            columnDefs={columnDefs}
            rowSelection={"single"}
            onRowClicked={onSelectionChanged}
            ref={gridRef}
            localeText={localeText}
            onGridSizeChanged={(e) => {
              e.api.sizeColumnsToFit();
            }}
            //paginationPageSize={5}
            //pagination={true}

            //suppressRowClickSelection
          ></AgGridReact>
        </div>
      </div>
      <UserModal
        open={open}
        setOpen={setOpen}
        getData={getData}
        userInputs={userInputs}
        setUserInputs={setUserInputs}
        handleChange={handleChange}
        allUsers={allUsers}
        currentUserHandler={currentUserHandler}
        formHandler={formHandler}
        formErrorHandling={formErrorHandling}
        setFormErrorHandling={setFormErrorHandling}
        deliteHandler={deliteHandler}
        setTryDelete={setTryDelete}
        tryDelete={tryDelete}
        openTab={openTab}
        setOpenTab={setOpenTab}
        setErrorHandling={setErrorHandling}
        errorHandling={errorHandling}
        // profileAdmin={profileAdmin}
        // setProfileAdmin={setProfileAdmin}
        // role={role}
        // setRole={setRole}
      />
    </main>
  );
};

export default Options;
