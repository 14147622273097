import { useEffect, Fragment, useState } from "react";

import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import CircularProgress from "@mui/material/CircularProgress";

import { checkZip } from "../helpers/validation";

import { HTMLEditor } from "./partials/HTMLEditor";

export default function ClientModal({
  openClient,
  setOpenClient,
  clientInputs,
  setClientInputs,
  handleChange,
  employeeSelection,
  listofGermanStates,
  formClient,
  formErrorHandling,
  setFormErrorHandling,
  storedParticipantSelection,
  loading,
  errorHandling,
  setErrorHandling,
}) {
  const [employeeQuery, setEmployeeQuery] = useState("");
  useEffect(
    () =>
      openClient === "add" &&
      setClientInputs({
        amt_aktiv_status: true,
        bundes_land_id: "Berlin",
      }),
    [openClient, setClientInputs]
  );

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const [query, setQuery] = useState("");

  const updateListofGermanStates = listofGermanStates.filter(
    (item) => Number(item.id) !== 25
  );

  const filteredlistofGermanStates =
    query === ""
      ? updateListofGermanStates
      : updateListofGermanStates.filter((land) => {
          return land.province.toLowerCase().includes(query.toLowerCase());
        });

  const landValue = updateListofGermanStates
    .filter((state) => state.province === clientInputs.bundes_land_id)
    .map((item) => item.province)[0];

  const disablebutton =
    errorHandling?.telefon1 === false ||
    errorHandling?.email === false ||
    !clientInputs.bezeichnung ||
    !clientInputs.telefon1 ||
    !clientInputs.email ||
    !checkZip(clientInputs.plz);

  return (
    <Modal
      open={
        ((openClient === "edit" || openClient === "copy") &&
          Object.keys(clientInputs).length > 0) ||
        openClient === "add"
          ? true
          : false
      }
      aria-describedby="modal-modal-description"
      onClose={() => {
        //  setOpenClient(false);
        setFormErrorHandling(null);
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="h-screen flex items-center justify-center"
      >
        <div
          id="modal-modal-description"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="modalContainerOuter">
            <div className="modalContainerMiddle">
              <div className="modalContainerInner">
                <h3>
                  {openClient === "edit"
                    ? "Kunde aktualisieren"
                    : "Kunde hinzufügen"}
                </h3>
              </div>
              <div className="col-span-6 grid grid-cols-5 gap-x-5">
                <span
                  className={`col-span-1 flex items-center ${
                    determineIfError("bezeichnung") || !clientInputs.bezeichnung
                      ? "text-red-600"
                      : ""
                  }`}
                >
                  Bezeichnung:
                </span>
                <input
                  name="bezeichnung"
                  variant="outlined"
                  className={`addClientModalInput
                              ${
                                determineIfError("bezeichnung") ||
                                !clientInputs.bezeichnung
                                  ? "border-red-600"
                                  : ""
                              }`}
                  value={clientInputs.bezeichnung || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("bezeichnung")}
                />
                {determineIfError("bezeichnung") ===
                "This attribute must be unique" ? (
                  <>
                    <div className="col-span-1 h-min"></div>
                    <div className="col-span-5 text-red-600 text-sm h-min">
                      Dieser Name wird bereits verwendet
                    </div>
                  </>
                ) : null}
                <span className="addClientModalLabel">Bezeichnung 2:</span>
                <input
                  name="bezeichnung2"
                  variant="outlined"
                  className="addClientModalInput"
                  value={clientInputs.bezeichnung2 || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("bezeichnung2")}
                />
                <span
                  className={`col-span-1 flex items-center ${
                    !checkZip(clientInputs.plz) ? "text-red-600" : ""
                  }
                `}
                >
                  PLZ:
                </span>
                <input
                  name="plz"
                  variant="outlined"
                  className={`col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                    !checkZip(clientInputs.plz) ? "border-red-600" : ""
                  }`}
                  type="number"
                  value={clientInputs.plz || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("plz")}
                />
                <span className="col-span-1 flex items-center">Ort:</span>
                <input
                  name="ort"
                  variant="outlined"
                  className="col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                  value={clientInputs.ort || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("ort")}
                />
                <span className="col-span-1 flex items-center">Straẞe:</span>
                <input
                  name="strasse"
                  variant="outlined"
                  className="col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                  value={clientInputs.strasse || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("strasse")}
                />
                <span className="col-span-1 flex items-center">Haus-Nr.:</span>
                <input
                  name="haus_num"
                  variant="outlined"
                  className="col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                  value={clientInputs.haus_num || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("haus_num")}
                />

                <span
                  className={`addClientModalLabel ${
                    errorHandling?.telefon1 === false || !clientInputs.telefon1
                      ? "text-red-600"
                      : ""
                  }`}
                >
                  Telefon:
                </span>
                <input
                  name="telefon1"
                  variant="outlined"
                  type="tel"
                  className={`addClientModalInput ${
                    errorHandling?.telefon1 === false || !clientInputs.telefon1
                      ? "border-red-600"
                      : ""
                  }`}
                  value={
                    clientInputs.telefon1 === null ? "" : clientInputs.telefon1
                  }
                  onChange={(e) =>
                    handleChange(e.target.value, e.target.name, "phone")
                  }
                  error={determineIfError("telefon1")}
                />
                <span
                  className={`addClientModalLabel ${
                    determineIfError("email") ||
                    errorHandling?.email === false ||
                    !clientInputs.email
                      ? "text-red-600"
                      : ""
                  }`}
                >
                  Rechnungs-Email:
                </span>
                <input
                  name="email"
                  variant="outlined"
                  className={`addClientModalInput ${
                    determineIfError("email") ||
                    errorHandling?.email === false ||
                    !clientInputs.email
                      ? "border-red-600"
                      : ""
                  }`}
                  value={clientInputs.email || ""}
                  onChange={(e) =>
                    handleChange(e.target.value, e.target.name, "email")
                  }
                  error={determineIfError("email")}
                />
                {determineIfError("email") ===
                "This attribute must be unique" ? (
                  <>
                    <div className="col-span-3 h-min"></div>
                    <div className="col-span-3 text-red-600 text-sm h-min">
                      Dieser E-Mail wird bereits verwendet
                    </div>
                  </>
                ) : null}
                <span className="addClientModalLabel">Standardamt:</span>
                <input
                  name="standard_amt"
                  variant="outlined"
                  className="addClientModalInput"
                  value={clientInputs.standard_amt || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("standard_amt")}
                />
                <span
                  className={`addClientModalLabel ${
                    determineIfError("www") ? "text-red-600" : ""
                  }`}
                >
                  Internet:
                </span>
                <input
                  name="www"
                  variant="outlined"
                  className={`addClientModalInput ${
                    determineIfError("www") ? "border-red-600" : ""
                  }`}
                  value={clientInputs.www || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  error={determineIfError("www")}
                />
                {employeeSelection?.length > 0 && (
                  <>
                    <span className="addClientModalLabel">Ansprechpart.</span>
                    <div className="col-span-5 flex items-center ">
                      <Combobox
                        value={(() => {
                          const getparticipant =
                            storedParticipantSelection.find(
                              (participant) =>
                                Number(participant.id) ===
                                Number(clientInputs.id_ansprechpartner)
                            );

                          if (getparticipant) {
                            const {
                              attributes: { name, vorname },
                            } = getparticipant;
                            return vorname + " " + name;
                          } else {
                            return "";
                          }
                        })()}
                        onChange={(e) => handleChange(e, "id_ansprechpartner")}
                      >
                        <div className="relative mt-1 w-full">
                          <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                              displayValue={(person) => person}
                              onChange={(event) =>
                                setEmployeeQuery(event.target.value)
                              }
                            />
                            {employeeQuery === "" &&
                            clientInputs.id_ansprechpartner === "" ? null : (
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-7">
                                <HighlightOffIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleChange(null, "id_ansprechpartner")
                                  }
                                />
                              </Combobox.Button>
                            )}
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setEmployeeQuery("")}
                          >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {employeeSelection.length === 0 &&
                              employeeQuery !== "" ? (
                                <div className="relative cursor-default select-none py-2 px-4 ">
                                  Nichts gefunden.
                                </div>
                              ) : (
                                employeeSelection.map((person) => (
                                  <Combobox.Option
                                    key={person.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 px-2 ${
                                        active
                                          ? "bg-primary-900 text-white"
                                          : ""
                                      }`
                                    }
                                    value={person.id}
                                  >
                                    <span className="block truncate">
                                      {person.attributes.vorname}{" "}
                                      {person.attributes.name}
                                    </span>
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </div>
                  </>
                )}
              </div>

              <div className="col-span-6 grid grid-cols-6 gap-x-6 gap-y-4">
                <span className="col-span-1 flex items-center">
                  Bundesland:
                </span>
                <div className="col-span-2 z-10">
                  <Combobox
                    value={landValue}
                    onChange={(event) =>
                      handleChange(event.province, "bundes_land_id")
                    }
                  >
                    <div className="relative mt-1">
                      <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                          className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                          onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {filteredlistofGermanStates.map((land) => (
                            <Combobox.Option
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 px-2 ${
                                  active
                                    ? "bg-primary-900 text-white"
                                    : "bg-white"
                                }`
                              }
                              key={land.id}
                              value={land}
                            >
                              {land.province}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>
                </div>

                <span className="col-span-1 flex items-center justify-end">
                  E-mail:
                </span>
                <div className="col-span-2 flex items-center border border-lightgray rounded-md px-3 py-2 shadow-sm focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 outline-none">
                  <div className="flex place-content-evenly w-full">
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_email"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_email === "e"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_email || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("e", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        egal
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_email"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_email === "n"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_email || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("n", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        nur
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_email"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_email === "k"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_email || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("k", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        kein
                      </div>
                    </div>
                  </div>
                </div>

                <span className={`flex items-center col-span-1`}>
                  Leitweg ID:
                </span>
                <input
                  name="leitweg_id"
                  variant="outlined"
                  className={`addClientModalInput col-span-2`}
                  value={clientInputs.leitweg_id || ""}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                />

                <span className="col-span-1 flex items-center justify-end">
                  Brief:
                </span>
                <div className="col-span-2 flex items-center border border-lightgray rounded-md px-3 py-2 shadow-sm  focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 outline-none">
                  <div className="flex place-content-evenly w-full">
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_brief"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_brief === "e"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_brief || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("e", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        egal
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_brief"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_brief === "n"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_brief || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("n", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        nur
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        name="amt_werb_status_brief"
                        type="checkbox"
                        checked={
                          clientInputs.amt_werb_status_brief === "k"
                            ? true
                            : false
                        }
                        value={clientInputs.amt_werb_status_brief || ""}
                        className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                        onChange={(e) => handleChange("k", e.target.name)}
                      />
                      <div className="ml-3 text-xs font-medium text-gray-900">
                        kein
                      </div>
                    </div>
                  </div>
                </div>

                {openClient === "edit" ? (
                  <>
                    <span className="col-span-1 flex items-center">ID:</span>
                    <span className="col-span-2 py-2">{clientInputs?.id}</span>
                    <span className="col-span-1 flex items-center justify-end">
                      Amtstatus:
                    </span>

                    <div
                      className={`col-span-2 flex items-center relative border ${
                        determineIfError("amt_aktiv_status")
                          ? "border-red-900"
                          : "border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1"
                      } rounded-md px-3 py-2 shadow-sm outline-none`}
                    >
                      <div className="flex place-content-evenly w-full">
                        <div className="flex items-center">
                          <input
                            name="amt_aktiv_status"
                            type="checkbox"
                            checked={
                              clientInputs.amt_aktiv_status === true
                                ? true
                                : false
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleChange(
                                e.target.checked ? true : false,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Aktuell
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="amt_aktiv_status"
                            type="checkbox"
                            checked={
                              clientInputs.amt_aktiv_status === true
                                ? false
                                : true
                            }
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleChange(
                                e.target.checked ? false : true,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Veraltet
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-span-3"></div>
                )}

                <div className="col-span-6 row-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	mt-3">
                  <label className="absolute -top-2">Bemerkung</label>

                  <HTMLEditor
                    value={
                      clientInputs.bemerkung === null
                        ? ""
                        : clientInputs.bemerkung
                    }
                    onChange={(value) => handleChange(value, "bemerkung")}
                    readOnly={false}
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 py-3 px-6 flex justify-end">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setOpenClient(false);
                  setFormErrorHandling(null);
                  setErrorHandling({});
                  setClientInputs({});
                }}
              >
                Abbrechen
              </button>
              <button
                onClick={(event) => formClient(event, openClient)}
                disabled={disablebutton}
                //className="ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loading === "loadingClient"
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-40"
                } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              >
                {openClient === "edit" ? "Aktualisieren" : "Hinzufügen"}
                {loading === "loadingClient" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
