import React, { useState, useCallback } from "react";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { DateRangePicker } from "@mantine/dates";

import { axiosInvoiceExportRoute } from "../helpers/axios";

const ExportInvoiceModal = ({
  invoiceExport,
  setInvoiceExport,
  token,
  notify,
}) => {
  const [dateRange, setDateRange] = useState("");
  const [loadingDyn, setLoadingDyn] = useState(false);

  const exportInvoiceHandler = useCallback(async () => {
    setLoadingDyn(true);
    try {
      let result;

      result = await axiosInvoiceExportRoute.post(
        `/`,
        {
          data: {
            from: dateRange[0],
            to: dateRange[1],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result?.data) {
        const url = window.URL.createObjectURL(
          new Blob(["\uFEFF" + result.data], {
            type: "text/csv; charset=utf-8",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `biteg-rechnung-export.csv`);
        document.body.appendChild(link);
        link.click();
        setDateRange("");
        setLoadingDyn(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (err) {
      setDateRange("");
      setLoadingDyn(false);
      notify("info", err.response.data.error.message);
    }
  }, [notify, token, dateRange]);

  return (
    <Modal
      open={invoiceExport}
      aria-describedby="modal-modal-description"
      onClose={() => {
        setInvoiceExport(false);
        setDateRange("");
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="customized-scrollbar h-screen w-screen p-4"
      >
        <div
          id="email-send"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="bg-white p-4 relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-1/3 overflow-auto max-h-full customized-scrollbar">
            <div className="pb-4">
              {" "}
              <DateRangePicker
                dropdownPosition="flip"
                classNames={{
                  input: "placeholder:text-black",
                  dropdown: "zindexfix",
                }}
                placeholder="Datumsbereich auswählen"
                inputFormat="DD/MM/YYYY"
                className="h-[29px] relative border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                variant="unstyled"
                clearable={true}
                value={dateRange}
                onChange={(newValue) => {
                  setDateRange(newValue);
                }}
              />
            </div>
            <div className="bg-gray-50 flex justify-end">
              <button
                onClick={() => {
                  setInvoiceExport(false);
                  setDateRange("");
                }}
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
              >
                {"Fertig"}
              </button>

              <button
                disabled={
                  dateRange[0] && dateRange[1] && loadingDyn === false
                    ? false
                    : true
                }
                name="csvexport"
                onClick={exportInvoiceHandler}
                className={`
                bg-primary-900 disabled:opacity-40
                   ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
              >
                {"CSV export erstellen"}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ExportInvoiceModal;
