import React, { useState, useEffect, useRef, useMemo } from "react";

import Fuse from "fuse.js";
import { AgGridReact } from "ag-grid-react";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AG_GRID_LOCALE_DE from "./locale.de";

import IconButton from "@mui/material/IconButton";
import SearchButton from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearButton from "@mui/icons-material/Clear";

import { DateRangePicker, DatePicker } from "@mantine/dates";

import { useLocation } from "react-router-dom";

const SeminarConfirmedGrid = ({
  seminarSelection,
  query,
  setQuery,
  idFilter,
  exactDateFilter,
  loadingDyn,
  filterHandler,
  dateRangeFilter,
}) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);
  const location = useLocation();
  const gridRefSeminar = useRef();

  const [seminarQueryResults, setSeminarQueryResults] = useState([]);

  useEffect(() => {
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["attributes.thema", "attributes.datum"],
    };

    const fuse = new Fuse(seminarSelection, options);
    if (seminarSelection?.length >= 0) {
      setSeminarQueryResults(
        fuse.search(
          query.thema !== ""
            ? {
                $and: [
                  ...(query.thema && [
                    {
                      $path: ["attributes.thema"],
                      $val: query.thema,
                    },
                  ]),
                ],
              }
            : {
                "attributes.datum": "'2",
              }
        )
      );
    }

    // eslint-disable-next-line
  }, [query, seminarSelection]);

  const onSeminarSelectionChanged = (event) => {
    if (event.node.isSelected()) {
      if (
        event.api.getFocusedCell().column.getColId() !== "copy" &&
        event.api.getFocusedCell().column.getColId() !== "edit"
      ) {
        // setSeminarInputs({});
        // setQuery({
        //   semid: "",
        //   anfangsdatum: "",
        //   enddatum: "",
        //   thema: "",
        // });
      }
    } else {
      event.node.setSelected(true);
      // setSeminarInputs(event.data);
      // console.log(event.data);
    }
  };

  //let navigate = useNavigate();

  const columnSeminarDefs = useMemo(
    () => [
      {
        field: "id",
        headerName: "Seminar NR",
        sortable: true,
        width: 110,
        minWidth: 100,
        headerClass: "header-blue",
        suppressSizeToFit: true,
      },
      {
        field: "wp_link",
        headerName: "Web",
        width: 50,
        minWidth: 50,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) =>
          params.data.wp_published ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WP_SITE}/${params.value}`}
            >
              <LinkIcon
                fontSize="medium"
                className="cursor-pointer flex h-full"
              />
            </a>
          ) : (
            <LinkOffIcon
              fontSize="medium"
              className="pointer-events-none flex h-full opacity-10"
            />
          ),
        suppressSizeToFit: true,
        hide: location.pathname === "/seminare/approvaladmin",
      },
      {
        field: "datum_friendly_adjusted",
        headerName: "Datum",
        sort: "asc",
        width: 110,
        minWidth: 110,
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
        comparator: (date1, date2) => {
          // date format is "mm.dd.yyyy"

          const date1ISO = date1.split(".").reverse().join("-");

          const date2ISO = date2.split(".").reverse().join("-");

          // Compare ISO dates directly
          return date1ISO < date2ISO ? -1 : date1ISO > date2ISO ? 1 : 0;
        },
      },
      {
        field: "end_datum_friendly_adjusted",
        headerName: "Enddatum",
        sortable: true,
        width: 110,
        minWidth: 110,
        headerClass: "header-blue",
        resizable: true,
        comparator: (date1, date2) => {
          // date format is "mm.dd.yyyy"

          const date1ISO = date1.split(".").reverse().join("-");

          const date2ISO = date2.split(".").reverse().join("-");

          // Compare ISO dates directly
          return date1ISO < date2ISO ? -1 : date1ISO > date2ISO ? 1 : 0;
        },
      },
      {
        field: "anfangs_zeit_friendly_adjusted",
        headerName: "Anfang",
        sortable: true,
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "end_zeit_friendly_adjusted",
        headerName: "Ende",
        sortable: true,
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "thema",
        headerName: "Seminar-Thema",
        sortable: true,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "raum_details.ort",
        headerName: "Ort",
        sortable: true,
        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "raum_details.bezeichnung",
        headerName: "Räume",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "referent_details",
        headerName: "Referent/-in",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        valueGetter: (params) => {
          return `${
            params?.data.referent_details?.name
              ? params?.data?.referent_details?.name + ","
              : ""
          } ${params?.data?.referent_details?.vorname || ""}`;
        },
      },
      {
        field: "max_teilnehmer",
        headerName: "TN",
        sortable: true,
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "max_tn_zahl",
        headerName: "Max TN",
        sortable: true,
        width: 70,
        minWidth: 50,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "anzahl_skripten",
        headerName: "Gedruckt",
        sortable: true,
        width: 120,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    [location.pathname]
  );

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  return (
    <>
      <div className="col-span-12 flex">
        <div className="w-3/12 flex">
          <div className="whitespace-nowrap flex items-center">semID:</div>
          <div className="relative w-full overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <input
              disabled={loadingDyn}
              className="ml-5 w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
              value={query.semid}
              onChange={(e) =>
                setQuery((prior) => ({
                  ...prior,
                  semid: e.target.value,
                }))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" && query.semid) {
                  filterHandler(e, "semid", true);
                }
              }}
            />

            <div className="absolute inset-y-0 right-0 flex items-center justify-center">
              <LoadingButton
                sx={{ minWidth: "auto", padding: 0 }}
                disableRipple
                loading={loadingDyn === "semid"}
                disabled={query?.semid ? false : true}
                onClick={(e) => filterHandler(e, "semid", true)}
              >
                {<SearchButton />}
              </LoadingButton>
              {idFilter && loadingDyn === false && (
                <IconButton
                  color="warning"
                  sx={{ padding: 0 }}
                  disabled={loadingDyn === "semid"}
                  onClick={(e) => filterHandler(e, "semid", false)}
                  disableRipple
                >
                  <ClearButton />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <div className="ml-5 w-3/12 flex">
          <div className="whitespace-nowrap flex items-center">Thema:</div>
          <input
            className="h-min ml-5 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="outlined"
            value={query.thema}
            onChange={(e) =>
              setQuery((prior) => ({
                ...prior,
                thema: e.target.value,
              }))
            }
          />
        </div>

        <div className="ml-10 w-3/12 flex relative">
          <DatePicker
            clearable={false}
            classNames={{ input: "placeholder:text-black" }}
            placeholder="Datum wählen"
            inputFormat="DD/MM/YYYY"
            className="w-full h-[29px] border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="unstyled"
            // size="xs"
            value={query.anfangsdatum === "" ? null : query.anfangsdatum}
            onChange={(newValue) => {
              setQuery((prior) => ({
                ...prior,
                anfangsdatum: newValue,
              }));
            }}
          />
          <div className="absolute inset-y-0 right-0 flex items-center justify-center">
            <LoadingButton
              sx={{ minWidth: "auto", padding: 0 }}
              disableRipple
              loading={loadingDyn === "exactdate"}
              disabled={query?.anfangsdatum ? false : true}
              onClick={(e) => filterHandler(e, "exactdate", true)}
            >
              {<SearchButton />}
            </LoadingButton>
            {exactDateFilter && loadingDyn === false && (
              <IconButton
                color="warning"
                sx={{ padding: 0 }}
                disabled={loadingDyn === "exactdate"}
                onClick={(e) => filterHandler(e, "exactdate", false)}
                disableRipple
              >
                <ClearButton />
              </IconButton>
            )}
          </div>
        </div>
        <div className="ml-10 w-3/12 flex relative">
          <DateRangePicker
            classNames={{ input: "placeholder:text-black" }}
            placeholder="Datumsbereich auswählen"
            inputFormat="DD/MM/YYYY"
            className="w-full h-[29px] relative border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="unstyled"
            clearable={false}
            value={query.daterange}
            onChange={(newValue) => {
              setQuery((prior) => ({
                ...prior,
                daterange: newValue,
              }));
            }}
          />
          <div className="absolute inset-y-0 right-0 flex items-center justify-center">
            <LoadingButton
              sx={{ minWidth: "auto", padding: 0 }}
              disableRipple
              loading={loadingDyn === "daterange"}
              disabled={query?.daterange ? false : true}
              onClick={(e) => filterHandler(e, "daterange", true)}
            >
              {<SearchButton />}
            </LoadingButton>
            {dateRangeFilter && loadingDyn === false && (
              <IconButton
                color="warning"
                sx={{ padding: 0 }}
                disabled={loadingDyn === "daterange"}
                onClick={(e) => filterHandler(e, "daterange", false)}
                disableRipple
              >
                <ClearButton />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <div
        style={{ height: "79vh" }}
        className="ag-theme-alpine w-full col-span-12"
      >
        <AgGridReact
          // alwaysMultiSort
          getRowId={getRowId}
          enableCellTextSelection
          defaultColDef={{
            filter: false,
          }}
          rowData={seminarQueryResults?.map((client) => ({
            ...client.item.attributes,
            id: client.item.id,
          }))}
          rowHeight={33}
          columnDefs={columnSeminarDefs}
          rowSelection={"single"}
          onRowClicked={onSeminarSelectionChanged}
          ref={gridRefSeminar}
          localeText={localeText}
          paginationPageSize={90}
          pagination={true}
          suppressRowClickSelection
          onGridSizeChanged={(e) => {
            e.api.sizeColumnsToFit();
          }}
          rowClassRules={{
            "bg-gradient-to-b from-red-100 to-red-300": (params) => {
              return params.data.anzahl_skripten < params.data.max_teilnehmer;
            },
          }}
        ></AgGridReact>
      </div>
    </>
  );
};

export default SeminarConfirmedGrid;
