import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// if (process.env.NODE_ENV === "production") {
Sentry.init({
  dsn: "https://2f23581a2a6e97726fd5b8d2b80c7416@o4507508246839297.ingest.de.sentry.io/4507889370660944",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: [/^https:\/\/database\.biteg\.de/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
// } else {
//   console.log("Sentry is disabled in development mode");
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
