import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function MultiSelect({
  options,
  selectedReferent,
  setSelectedReferent,
  resetState,
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      multiple
      limitTags={2}
      value={selectedReferent}
      onChange={(_event, newValue) => {
        resetState("referent");
        setSelectedReferent(newValue);
      }}
      disableCloseOnSelect
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="tags-standard"
      options={options}
      getOptionLabel={({ attributes: { vorname, name } }) =>
        `${vorname || ""} ${name || ""}`
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option?.attributes?.vorname || ""} {option?.attributes?.name || ""}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" fullWidth />
      )}
    />
  );
}
