import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useOutletContext } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import AG_GRID_LOCALE_DE from "./locale.de";
import Fuse from "fuse.js";
import { Link } from "react-router-dom";
import * as EmailValidator from "email-validator";

import {
  axiosInvoiceRoute,
  axiosSendEmailRoute,
  axiosDownloadPdfRoute,
  axiosGenerateInvoice,
} from "../helpers/axios";

import CircularProgress from "@mui/material/CircularProgress";

import DownloadIcon from "@mui/icons-material/Download";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import deLocale from "date-fns/locale/de";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import NoDownloadIcon from "@mui/icons-material/FileDownloadOff";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

import EmailModal from "./EmailModal";
import ExportInvoiceModal from "./ExportInvoiceModal";

const Rechnungen = () => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const {
    userDetails,
    token,
    notify,
    raumeSelection,
    seminarInputs,
    getData,
    seminarParticipantsList,
    storedClientSelection,
    // getParticipantData,
    invoiceSelection,
    getInvoiceData,
    downloadHandler,
    getFutureEmails,
    getStoredParticipantDetails,
    getStoredClientData,
    getSemPartData,
    emailQueryMode,
  } = useOutletContext();

  const [formErrorHandling, setFormErrorHandling] = useState(null);

  const [validationIssues, setValidationIssues] = useState(false);

  const [openTab, setOpenTab] = useState(1);
  const [invoiceInputs, setInvoiceInputs] = useState({});

  const [numberPerson, setNumberPerson] = useState([]);

  const gridRefInvoices = useRef();
  const gridRefParticipant = useRef();

  const [loadingDyn, setLoadingDyn] = useState(false);
  const [emailDelayTimer, setEmailDelayTimer] = useState(30);
  const [query, setQuery] = useState({ bezeichnung: "" });
  const [clientQueryResults, setClientQueryResults] = useState([]);

  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);

  const [editInvoice, setEditInvoice] = useState(false);

  const [sendEmail, setSendEmail] = useState(false);

  const [invoiceExport, setInvoiceExport] = useState(false);

  const convertIsoToLocal = (params) => {
    if (params !== null) {
      let isoDate = params;

      var d = new Date(isoDate);
      return d.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (seminarInputs.id) {
      getStoredClientData({
        seminar_id: seminarInputs.id,
      });
    }
  }, [seminarInputs.id, getStoredClientData]);

  useEffect(() => {
    if (invoiceInputs?.attributes?.id_amt) {
      getStoredParticipantDetails(invoiceInputs?.attributes?.id_amt);
    }
  }, [invoiceInputs, getStoredParticipantDetails]);

  useEffect(() => {
    const filterCompany = storedClientSelection.filter((client) =>
      invoiceSelection.some(
        (invoice) => Number(invoice.attributes.id_amt) === Number(client.id)
      )
    );

    const options = {
      includeScore: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["attributes.bezeichnung"],
    };
    const fuse = new Fuse(filterCompany, options);
    if (filterCompany?.length > 0) {
      let result;
      if (query.bezeichnung !== "") {
        result = fuse.search({
          $and: [
            ...(query.bezeichnung && [
              {
                $path: ["attributes.bezeichnung"],
                $val: query.bezeichnung,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);

        setClientQueryResults(destructitems);
      } else {
        setClientQueryResults(filterCompany);
      }
    } else {
      setClientQueryResults([]);
    }
  }, [query, invoiceSelection, storedClientSelection]);

  const rowClass = "my-row-class";
  const rowHeight = 33;

  const invoiceParticipantConnection = useCallback(
    (invoiceIds = []) => {
      let participantsByInvoices = [];
      if (seminarParticipantsList?.length > 0 && Array.isArray(invoiceIds)) {
        participantsByInvoices = seminarParticipantsList.filter(
          (sempart) =>
            invoiceIds.includes(
              Number(sempart.attributes.rechnung_id.data.id)
            ) && sempart.attributes.status === "active"
        );
      }

      return participantsByInvoices;
    },
    [seminarParticipantsList]
  );

  const columnInvoiceDefs = useMemo(
    () => [
      {
        width: 50,
        minWidth: 50,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <input
              type="checkbox"
              className={`${
                params.node.data.id === invoiceInputs?.id &&
                "opacity-25 pointer-events-none"
              } p-0`}
              defaultChecked={selectedInvoiceIds.some(
                (invid) => params.node.data.id === invid
              )}
            />
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: () => {
          return invoiceInputs?.id ? false : true;
        },
      },
      {
        field: "edit",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) => {
          return (
            <ButtonUnstyled
              className={`${
                params.node.selected === false &&
                "pointer-events-none opacity-25"
              }`}
              onClick={() => {
                if (params.node.data.id === invoiceInputs.id) {
                  setSelectedParticipantIds([]);
                  setInvoiceInputs({});
                  setEditInvoice(false);
                } else {
                  params.node.setSelected(true, true);
                  setInvoiceInputs(params.node.data);
                  setEditInvoice(true);
                }
                params.api.redrawRows();
              }}
              aria-label="edit"
            >
              {params.node.data.id === invoiceInputs.id && editInvoice ? (
                <EditOffIcon />
              ) : (
                <EditIcon />
              )}
            </ButtonUnstyled>
          );
        },
        cellClass: "my-cell-class",
      },
      {
        field: "attributes.rechnungsNum",
        headerName: "Rechnungs Nr.",
        width: 130,
        minWidth: 120,
        sortable: true,
        sort: "asc",
        sortIndex: 1,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        resizable: true,
        cellClass: "my-cell-class",
      },
      {
        headerName: "Kunde",
        width: 420,
        minWidth: 220,
        sortable: true,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        resizable: true,
        cellClass: "my-cell-class",
        cellRenderer: (params) => {
          return (
            <Link
              to={`/kunden/verwaltung?clientid=${params.data.attributes.clientDetails.id}`}
            >
              {params.data.attributes.clientDetails.attributes.bezeichnung}
            </Link>
          );
        },
      },
      {
        field: "attributes.sent_to_customer",
        headerName: "Gesendet",
        width: 120,
        sortable: true,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        resizable: true,
        cellClass: "my-cell-class",
        cellRenderer: (params) => {
          return params.data.attributes.sent_to_customer ? "ja" : "nein";
        },
      },
      {
        field: "attributes.datum",
        headerName: "Rechnungsdatum",
        minWidth: 100,
        sortable: true,
        sort: "asc",
        sortIndex: 2,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: (params) => convertIsoToLocal(params.value),
        cellClass: "my-cell-class",
      },
      {
        field: "attributes id",
        headerName: "Anzahl Teilnehmer",
        headerTooltip: "Es werden nur aktive Teilnehmer angezeigt",
        //width: 150,
        minWidth: 100,
        sortable: true,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        resizable: true,
        cellClass: "my-cell-class",
        cellRenderer: (params) => {
          return invoiceParticipantConnection([params.data.id]).length;
        },
      },
      {
        field: "attributes.netto_betrag",
        headerName: "Netto Betrag",
        sortable: true,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        cellClass: "my-cell-class",
        cellRenderer: (params) => (
          <div>{params.value.toFixed(2).replace(".", ",")} €</div>
        ),
      },
      {
        field: "attributes.brutto_betrag",
        headerName: "Brutto Betrag",
        sortable: true,

        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        cellClass: "my-cell-class",
        cellRenderer: (params) => (
          <div>{params.value.toFixed(2).replace(".", ",")} €</div>
        ),
      },
    ],

    [
      selectedInvoiceIds,
      invoiceInputs,
      editInvoice,
      invoiceParticipantConnection,
      setSelectedParticipantIds,
    ]
  );

  const onInvoiceSelectionChanged = (event) => {
    if (event.node.isSelected()) {
      if (
        event.api.getFocusedCell().column.getColId() !== "edit" &&
        !invoiceInputs.id
      ) {
        event.node.setSelected(false);
        setQuery({ bezeichnung: "" });
      }
    } else {
      event.node.setSelected(true);
    }
  };

  const onParticipantSelectionChanged = (event) => {
    if (
      event.api.getFocusedCell().column.getColId() !==
      "attributes.id_certificate"
    ) {
      if (event.node.isSelected()) {
        event.node.setSelected(false);
      } else {
        event.node.setSelected(true);
      }
    }
  };

  const emailValidation = (email = "") => {
    if (email === "" || email === null) {
      return true;
    } else {
      const validEmail = EmailValidator.validate(email);
      if (validEmail) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleInvoiceChange = (value, name, validate = false) => {
    formErrorHandling &&
      setFormErrorHandling((prevState) =>
        prevState.filter((e) => e.path[0] !== name)
      );

    // if invoice type is manual, select all participants
    if (invoiceInputs?.attributes?.type === "manual") {
      // get all participants for the seminar
      const seminarParticipants = seminarParticipantsList.filter(
        (sempart) =>
          Number(sempart.attributes.rechnung_id.data.id) ===
          Number(invoiceInputs.id)
      );

      setSelectedParticipantIds(
        seminarParticipants.map((sempart) => sempart.id)
      );
    }

    if (validate) {
      if (emailValidation(value)) {
        setValidationIssues(false);
      } else {
        setValidationIssues(true);
      }
    }

    setInvoiceInputs((values) => ({
      ...values,
      attributes: { ...values.attributes, [name]: value },
    }));
  };

  console.log(selectedParticipantIds);

  const checkForActiveParticipants = seminarParticipantsList.filter(
    (sempart) =>
      sempart.attributes.status === "active" &&
      sempart.attributes.invoiced === true &&
      Number(sempart.attributes.id_seminar.data.id) === Number(seminarInputs.id)
  );

  useEffect(() => {
    setNumberPerson(
      seminarParticipantsList.filter(
        (sempart) =>
          Number(sempart.attributes.id_teilnehmer.data.attributes.id_amt) ===
            Number(invoiceInputs?.attributes?.id_amt) &&
          Number(sempart.attributes.id_seminar.data.id) ===
            Number(invoiceInputs?.attributes?.id_seminar)
      )
    );
  }, [invoiceInputs, seminarParticipantsList]);

  const resetAndGet = useCallback(async () => {
    getFutureEmails(emailQueryMode);
    setInvoiceInputs({});
    setEditInvoice(false);
    setLoadingDyn(false);
    getData();
    getInvoiceData();
    // legacy logic for the approval admin
    // getParticipantData();
    getSemPartData();
    setSelectedInvoiceIds([]);
    setSelectedParticipantIds([]);
    downloadHandler("listfilesbyseminar", null, "list");
    gridRefParticipant.current.api.deselectAll();
    gridRefInvoices.current.api.deselectAll();
    gridRefInvoices.current.api.redrawRows();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [
    getData,
    getInvoiceData,
    downloadHandler,
    // getParticipantData,
    getSemPartData,
    getFutureEmails,
    emailQueryMode,
  ]);

  const updateInvoiceHandler = useCallback(async () => {
    try {
      let result;

      result = await axiosInvoiceRoute.put(
        `/${invoiceInputs.id}`,
        {
          data: {
            id: invoiceInputs.id,
            ...invoiceInputs.attributes,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result?.data) {
        notify("info", "Invoice Updated");
        resetAndGet();
      }
    } catch (err) {
      console.log(err);
      setFormErrorHandling(err.response.data.error.details.errors);
      setLoadingDyn(false);
    }
  }, [invoiceInputs, notify, token, resetAndGet]);

  const emailInvoiceHandler = useCallback(
    async (type, mode, flag = null, manuelinputinvid = null) => {
      try {
        let result;

        const data = {
          type,
          mode,
          flag,
          emaildelay: emailDelayTimer || 30,
          ...(mode === "genselected"
            ? {
                ...(((type === "invoice" || type === "reminder") && {
                  invoiceids:
                    manuelinputinvid !== null
                      ? [manuelinputinvid]
                      : selectedInvoiceIds,
                }) ||
                  (type === "certificate" && {
                    sempartids: selectedParticipantIds,
                  })),
              }
            : { searchbyseminarid: seminarInputs.id }),
        };

        result = await axiosSendEmailRoute.post(
          `/`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          resetAndGet();
          notify(
            "info",
            (type === "invoice" && "Invoice E-Mail Sent") ||
              (type === "certificate" && "certificate(s) sent") ||
              (type === "reminder" && "Payment Reminder sent")
          );
          return;
        }
      } catch (err) {
        setLoadingDyn(false);

        notify("error", err.response.data.error.message, { autoClose: false });
        setFormErrorHandling(err.response.data.error.details.errors);
      }
      setEditInvoice(false);
    },
    [
      notify,
      token,
      selectedInvoiceIds,
      seminarInputs.id,
      selectedParticipantIds,
      emailDelayTimer,
      resetAndGet,
    ]
  );

  const downloadDocHandler = async (e, type, mode, idselection = []) => {
    e.preventDefault();
    const data = {
      type,
      mode,
      idselection,
      searchbyseminarid: mode === "genall" ? seminarInputs.id : null,
    };

    try {
      let result;

      result = await axiosDownloadPdfRoute.post(
        `/`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result?.data) {
        await (async () => {
          for await (let file of result?.data) {
            const newfile = new Blob(
              [new Uint8Array(file.binary.data).buffer],
              { type: "application/pdf" }
            );
            const url = window.URL.createObjectURL(newfile);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.name);
            document.body.appendChild(link);
            link.click();
          }
        })();

        setLoadingDyn(false);
      }
    } catch (err) {
      notify("info", err.response.data.error.message);

      setLoadingDyn(false);
    }
  };

  const columnParticipantsDefs = useMemo(
    () => [
      {
        width: 50,
        minWidth: 50,
        cellStyle: { padding: 6 },
        headerClass: "header-blue",
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return (
            <div className="flex h-full items-center">
              <input type="checkbox" defaultChecked={params.node.selected} />
            </div>
          );
        },
        cellClass: "my-cell-class",
        headerCheckboxSelection: true,
      },
      {
        field: "attributes.id_teilnehmer.data.attributes.vorname",
        headerName: "Vorname",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "attributes.id_teilnehmer.data.attributes.name",
        headerName: "Name",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "attributes.id_teilnehmer.data.attributes.email",
        headerName: "E-Mail",
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
        cellRenderer: (params) => {
          const check = emailValidation(params.value);

          if (!check) {
            setValidationIssues(true);
          } else {
            setValidationIssues(false);
          }

          return (
            <div
              className={`${
                emailValidation(params.value) ? "" : "text-red-500"
              }`}
            >
              {params.value}
            </div>
          );
        },
      },
      {
        field: "attributes.id_certificate",
        headerName: "Zertifikat",
        sortable: true,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) =>
          params.node.data.attributes.id_certificate ? (
            <DownloadIcon
              className="cursor-pointer"
              onClick={(e) =>
                downloadDocHandler(e, "certificate", "genselected", [
                  params.node.data.id,
                ])
              }
            />
          ) : (
            <NoDownloadIcon className="pointer-events-none opacity-10" />
          ),
      },
    ],
    // eslint-disable-next-line
    [selectedParticipantIds, downloadDocHandler]
  );

  const adhocGenPdfHandler = useCallback(
    async (e, type) => {
      e.preventDefault();
      const data = {
        searchbyseminarid: seminarInputs.id,
        type,
      };

      try {
        let result;

        result = await axiosGenerateInvoice.post(
          `/`,
          {
            data: data,
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Biteg-${
              (type === "placeholder" && "Tischkarten") ||
              (type === "tnlist" && "Teilnehmerlist") ||
              (type === "courserating" && "Bewertungsbogen")
            }-SemID-${seminarInputs.id}.pdf`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoadingDyn(false);
        }
      } catch (err) {
        notify("error", "Etwas ist schief gelaufen...");
        setLoadingDyn(false);
      }
    },
    [token, seminarInputs.id, notify]
  );

  const createInvoiceHandler = useCallback(
    async (e, mode, type = "rabatt") => {
      e.preventDefault();
      const data = {
        ...(mode === "genselected" && {
          idselection:
            (type === "rabatt" || type === "storno") && selectedInvoiceIds,
        }),
        ...(mode === "genall" && {
          searchbyseminarid: seminarInputs.id,
        }),
        ...(type === "storno" && {
          storno_saz: invoiceInputs.attributes.storno_saz,
          participantidselection: selectedParticipantIds,
        }),
        type: type,
      };

      try {
        let result;

        result = await axiosGenerateInvoice.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          if (type === "tncertificate") {
            notify(
              result.data === "none" ? "error" : "info",
              result.data === "none"
                ? "Keine Zertifikat Erstellt"
                : "Zertifikate Erstellt"
            );
          } else {
            notify("info", "Rechnung Erstellt");
          }

          await resetAndGet();
        }
      } catch (err) {
        notify("error", err.response.data.error.message);
        setFormErrorHandling(err.response.data.error.details.errors);
        setLoadingDyn(false);
      }
    },
    [
      notify,
      token,
      selectedInvoiceIds,
      seminarInputs.id,
      invoiceInputs?.attributes?.storno_saz,
      selectedParticipantIds,
      resetAndGet,
      // emailInvoiceHandler,
      // getSemPartData,
    ]
  );

  const GreyButtonElement = ({
    name,
    text,
    onClick,
    disabled,
    download,
    width,
  }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        name={name}
        className={`${
          loadingDyn === name
            ? "bg-white hover:bg-white disabled:ring-gray-300 disabled:ring-offset-2 disabled:ring-2 text-white focus:ring-orange-900"
            : "bg-gray-200 disabled:opacity-25 hover:bg-gray-300 hover:text-orange-900 focus:ring-gray-300"
        } relative ${
          width ? width : "w-10/12"
        } whitespace-nowrap inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
  focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none`}
      >
        {text}
        {loadingDyn === name && (
          <div className="absolute">
            <div className="relative">
              <CircularProgress
                size={36}
                sx={{
                  color: "#F4A305",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-7.5px",
                  marginLeft: "-15px",
                }}
              />
              {download && (
                <DownloadIcon
                  size={24}
                  sx={{
                    color: "#F4A305",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginLeft: "-9px",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </button>
    );
  };

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  const invstatus = invoiceInputs?.attributes?.status;

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between m-6 ">
          <h3>Rechnungen</h3>
        </div>
      </div>
      <div className="grid grid-cols-6 gap-x-6 gap-y-4 px-6 py-5 text-sm text-left font-medium text-gray-700">
        <div className="col-span-6 flex">
          Seminar:
          <div className="font-bold pl-1">
            {`${convertIsoToLocal(seminarInputs.datum)} | ${
              seminarInputs?.raum_details?.ort
            } | ${seminarInputs.thema} `}
          </div>
        </div>
        <div className="col-span-4 flex">
          {
            <>
              <div className=" flex items-center whitespace-nowrap">
                Rechnungen nach Unternehmen filtern:
              </div>
              <input
                name="bezeichnung"
                //placeholder="Rechnungen nach Unternehmen filtern"
                className="ml-10 w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                variant="outlined"
                disabled={invoiceInputs.id}
                value={query.bezeichnung || ""}
                onChange={(e) =>
                  setQuery((prior) => ({
                    ...prior,
                    bezeichnung: e.target.value,
                  }))
                }
              />
            </>
          }
        </div>

        <div
          style={{ height: 500 }}
          className="ag-theme-alpine col-span-6 rechnungenGrid"
        >
          <AgGridReact
            getRowId={getRowId}
            tooltipShowDelay={0}
            enableCellTextSelection
            defaultColDef={{
              filter: false,
            }}
            rowHeight={rowHeight}
            rowClass={rowClass}
            rowData={invoiceSelection
              .filter((invoice) =>
                clientQueryResults.some(
                  (client) =>
                    client.id === Number(invoice.attributes.id_amt) &&
                    Number(invoice.attributes.id_seminar) ===
                      Number(seminarInputs.id)
                )
              )
              .map((invo) => {
                return {
                  ...invo,
                  attributes: {
                    ...invo.attributes,
                    clientDetails: clientQueryResults.find(
                      (client) => client.id === invo.attributes.id_amt
                    ),
                  },
                };
              })}
            onSelectionChanged={(event) => {
              setSelectedInvoiceIds(
                event.api.getSelectedNodes().map((node) => node.data.id)
              );
            }}
            rowMultiSelectWithClick
            suppressRowClickSelection={true}
            columnDefs={columnInvoiceDefs}
            rowSelection={"multiple"}
            onRowClicked={onInvoiceSelectionChanged}
            ref={gridRefInvoices}
            localeText={localeText}
            paginationPageSize={50}
            pagination={true}
            rowClassRules={{
              "opacity-25 pointer-events-none": (params) => {
                return invoiceInputs?.id && params.data.id !== invoiceInputs.id;
              },
              "bg-gradient-to-b from-orange-100 to-orange-300": (params) => {
                var status = params.data.attributes.status;
                var newfromold = params.data.attributes.new_from_storno;
                return status === "angelegt" && newfromold === true;
              },
              "bg-gradient-to-b from-red-100 to-red-300": (params) => {
                var status = params.data.attributes.status;
                var newfromold = params.data.attributes.new_from_storno;
                return status === "angelegt" && !newfromold;
              },
              "bg-gradient-to-b from-cyan-100 to-cyan-300": (params) => {
                var status = params.data.attributes.status;
                return status === "bezahlt";
              },
              "bg-gradient-to-b from-gray-100 to-gray-300": (params) => {
                var status = params.data.attributes.status;
                return status === "storno";
              },
              "bg-gradient-to-b from-blue-100 to-blue-300": (params) => {
                var status = params.data.attributes.status;
                return status === "storniert";
              },
              "bg-gradient-to-b from-green-100 to-green-300": (params) => {
                var status = params.data.attributes.status;
                var type = params?.data?.attributes?.type || "auto";
                return status === "offen" && type !== "manual";
              },
              "bg-gradient-to-b from-purple-100 to-purple-300": (params) => {
                var status = params.data.attributes.status;
                var type = params?.data?.attributes?.type || "auto";
                return status === "offen" && type === "manual";
              },
            }}
            onGridSizeChanged={(e) => {
              e.api.sizeColumnsToFit();
            }}
          ></AgGridReact>
        </div>

        <div className={`col-span-6 flex pb-10 justify-between`}>
          <div className="flex">
            <div>
              <button
                name="genselectedinvoice"
                disabled={
                  selectedInvoiceIds.length === 0 ||
                  (selectedInvoiceIds.length > 0 &&
                    selectedInvoiceIds.filter((invoiceid) =>
                      invoiceSelection.some(
                        (invoice) =>
                          invoice.attributes.status === "offen" &&
                          invoice.id === invoiceid
                      )
                    ).length === 0) ||
                  editInvoice
                }
                onClick={(e) => {
                  e.preventDefault();
                  setLoadingDyn(e.target.name);
                  createInvoiceHandler(e, "genselected", "rabatt");
                }}
                //className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loadingDyn === "genselectedinvoice"
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-25"
                } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
              >
                Ausgewählte Rechnung erstellen
                {loadingDyn === "genselectedinvoice" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
            <div className="pl-4">
              {" "}
              <button
                name="genallinvoices"
                disabled={
                  invoiceSelection.filter(
                    (invoice) =>
                      invoice.attributes.id_seminar === seminarInputs.id &&
                      invoice.attributes.status === "offen"
                  ).length === 0
                }
                onClick={(e) => {
                  e.preventDefault();
                  setLoadingDyn(e.target.name);
                  createInvoiceHandler(e, "genall", "rabatt");
                }}
                //className="disabled:opacity-25 disabled:pointer-events-none whitespace-nowrap inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loadingDyn === "genallinvoices"
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-25"
                } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 
                focus:ring-offset-2 focus:ring-primary-500`}
              >
                {"Alle neuen Rechnungen erstellen"}
                {loadingDyn === "genallinvoices" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
          <div className="font-bold pr-4">
            {
              seminarParticipantsList?.filter(
                (sempart) =>
                  Number(sempart.attributes.id_seminar.data.id) ===
                    seminarInputs.id && sempart.attributes.status === "active"
              )?.length
            }{" "}
            Teilnehmer
          </div>
        </div>

        <div className={`bg-white col-span-6 grid grid-cols-12`}>
          <div
            className={`bg-white w-full col-span-12 " ${
              invoiceInputs.id
                ? ""
                : "opacity-30 pointer-events-none" /* opacity-30 pointer-events-none*/
            } `}
          >
            <h3 className="pb-5">Teilnehmer</h3>
          </div>
          <div
            style={{ height: 650 }}
            className={`${
              invoiceInputs.id
                ? ""
                : "opacity-30 pointer-events-none" /* opacity-30 pointer-events-none*/
            } ag-theme-alpine w-full col-span-5`}
          >
            <AgGridReact
              enableCellTextSelection
              defaultColDef={{
                filter: false,
              }}
              isRowSelectable={(rowNode) =>
                invoiceInputs?.attributes?.type === "manual" ||
                rowNode.data.attributes.status === "cancelled"
                  ? false
                  : true
              }
              rowData={
                invoiceInputs.id &&
                seminarParticipantsList.filter(
                  (sempart) =>
                    Number(sempart.attributes.rechnung_id.data.id) ===
                    Number(invoiceInputs.id)
                )
              }
              rowClassRules={{
                "opacity-25 pointer-events-none": (params) => {
                  return (
                    params.data.attributes.status === "cancelled" ||
                    invoiceInputs.attributes.status === "offen" ||
                    invoiceInputs.attributes.status === "storniert" ||
                    invoiceInputs.attributes.type === "manual"
                  );
                },
              }}
              ref={gridRefParticipant}
              columnDefs={columnParticipantsDefs}
              rowSelection={"multiple"}
              onRowClicked={onParticipantSelectionChanged}
              localeText={localeText}
              paginationPageSize={10}
              pagination={true}
              suppressRowClickSelection
              onGridSizeChanged={(e) => {
                e.api.sizeColumnsToFit();
              }}
              onSelectionChanged={(event) => {
                setSelectedParticipantIds(
                  event.api.getSelectedNodes().map((node) => node.data.id)
                );
              }}
            ></AgGridReact>
          </div>

          <div className="ml-5 col-span-7">
            <div
              className={`grid grid-cols-6 h-min gap-x-6 gap-y-4 ${
                invoiceInputs?.id ? "" : "pointer-events-none opacity-25"
              }`}
            >
              <div className="col-span-6 flex">
                <div
                  className={`${
                    editInvoice === true ? "" : "hidden"
                  } flex justify-end`}
                >
                  <button
                    name="refreshing"
                    className={`${
                      loadingDyn === "refreshing"
                        ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                        : "bg-primary-900 disabled:opacity-25"
                    } relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                    // className="bg-primary-900 disabled:opacity-25 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    onClick={(event) => {
                      if (validationIssues) {
                        notify(
                          "error",
                          "Eine oder mehrere Email Adressen sind ungültig"
                        );
                        return;
                      }

                      updateInvoiceHandler(event);
                      setLoadingDyn(event.target.name);
                    }}
                  >
                    Speichern
                    {loadingDyn === "refreshing" && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={`${
                    editInvoice === true ? "" : "hidden"
                  } flex justify-end ml-10`}
                >
                  <button
                    disabled={loadingDyn}
                    onClick={(event) => {
                      event.preventDefault();
                      setFormErrorHandling(null);
                      setInvoiceInputs({});
                      setEditInvoice(false);
                    }}
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 disabled:opacity-25 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                  >
                    Bearbeiten ausschalten
                  </button>
                </div>
              </div>
              <>
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Besteller Email:
                </div>
                <input
                  className={`${
                    invstatus !== "angelegt" &&
                    invstatus !== "offen" &&
                    "pointer-events-none opacity-50"
                  } ${
                    emailValidation(invoiceInputs?.attributes?.besteller_email)
                      ? "border-gray-300"
                      : "border-red-500"
                  } col-span-3 p-1 border-0 border-b bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="besteller_email"
                  disabled={editInvoice ? false : true}
                  value={invoiceInputs?.attributes?.besteller_email || ""}
                  onChange={(e) =>
                    handleInvoiceChange(
                      e.target.value,
                      e.target.name,
                      true /*validate*/
                    )
                  }
                />
                <div className={`col-span-2`}>
                  <div>
                    <button
                      name="besteller_email"
                      onClick={(e) => handleInvoiceChange(null, e.target.name)}
                      disabled={
                        editInvoice &&
                        invoiceInputs?.attributes?.besteller_email !== null
                          ? false
                          : true
                      }
                      className="disabled:pointer-events-none disabled:opacity-50 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Besteller entfernen
                    </button>
                  </div>
                </div>
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Rechnungs Email:
                </div>
                <div className="col-span-5">
                  {invoiceInputs?.attributes?.clientDetails?.attributes?.email}
                </div>
                <div className="col-span-1 whitespace-nowrap flex items-center">
                  Besteller CC Email:
                </div>
                <div className="col-span-3 relative group">
                  <input
                    className={`${
                      invstatus !== "offen" &&
                      invstatus !== "angelegt" &&
                      invstatus !== "bezahlt" &&
                      "pointer-events-none opacity-50"
                    } ${
                      emailValidation(
                        invoiceInputs?.attributes?.besteller_cc_email
                      )
                        ? "border-gray-300"
                        : "border-red-500"
                    } w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                    name="besteller_cc_email"
                    disabled={!editInvoice}
                    value={invoiceInputs?.attributes?.besteller_cc_email || ""}
                    onChange={(e) =>
                      handleInvoiceChange(
                        e.target.value,
                        e.target.name,
                        true /*validate*/
                      )
                    }
                  />
                  <div className="absolute left-0 top-full mt-1 w-64 text-xs text-gray-700 bg-white border border-gray-300 rounded-md p-2 shadow-lg hidden group-hover:block z-10">
                    Verwenden Sie dieses Feld, um eine CC-E-Mail-Adresse für den
                    Besteller einzugeben (z. B. für rechnungsbezogene E-Mails).
                  </div>
                </div>
                <div className="col-span-2" />
                <div className="col-span-1 whitespace-nowrap flex items-center">
                  Teilnem. CC Email:
                </div>
                <div className="col-span-3 relative group">
                  <input
                    className={`${
                      invstatus !== "offen" &&
                      invstatus !== "angelegt" &&
                      invstatus !== "bezahlt" &&
                      "pointer-events-none opacity-50"
                    } ${
                      emailValidation(
                        invoiceInputs?.attributes?.optional_cc_email
                      )
                        ? "border-gray-300"
                        : "border-red-500"
                    } w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                    name="optional_cc_email"
                    disabled={!editInvoice}
                    value={invoiceInputs?.attributes?.optional_cc_email || ""}
                    onChange={(e) =>
                      handleInvoiceChange(
                        e.target.value,
                        e.target.name,
                        true /*validate*/
                      )
                    }
                  />
                  <div className="absolute left-0 top-full mt-1 w-64 text-xs text-gray-700 bg-white border border-gray-300 rounded-md p-2 shadow-lg hidden group-hover:block z-10">
                    Verwenden Sie dieses Feld, um eine CC-E-Mail-Adresse für
                    teilnehmerbezogene E-Mails einzugeben (z. B.
                    Kursbestätigung, Stornierung usw.).
                  </div>
                </div>
                <div className="col-span-2" />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Textfeld Rechnung:
                </div>
                <input
                  className={`${
                    invstatus !== "offen" &&
                    invstatus !== "angelegt" &&
                    "pointer-events-none opacity-50"
                  } col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="extern_zeichnung"
                  disabled={editInvoice ? false : true}
                  value={invoiceInputs?.attributes?.extern_zeichnung || ""}
                  onChange={(e) =>
                    handleInvoiceChange(e.target.value, e.target.name)
                  }
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Angebotsdatum:
                </div>
                <div
                  className={`${
                    invoiceInputs?.attributes?.type !== "manual" &&
                    "pointer-events-none opacity-50"
                  } col-span-2 p-1 bg-gray-50 sm:text-sm`}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={deLocale}
                  >
                    <DatePicker
                      mask="__.__.____"
                      value={
                        invoiceInputs?.attributes?.angebots_datum
                          ? new Date(invoiceInputs.attributes.angebots_datum)
                          : ""
                      }
                      onChange={(newValue) =>
                        handleInvoiceChange(newValue, "angebots_datum")
                      }
                      disabled={editInvoice ? false : true}
                      disableOpenPicker
                      renderInput={({ inputRef, inputProps, inInputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="w-full"
                            ref={inputRef}
                            {...inputProps}
                          />
                          {inInputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Bestelldatum:
                </div>
                <div
                  className={`${
                    invstatus !== "offen" && "pointer-events-none opacity-50"
                  } col-span-2 p-1 bg-gray-50 sm:text-sm`}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={deLocale}
                  >
                    <DatePicker
                      mask="__.__.____"
                      value={
                        invoiceInputs?.attributes?.bestell_datum
                          ? new Date(invoiceInputs.attributes.bestell_datum)
                          : ""
                      }
                      onChange={(newValue) =>
                        handleInvoiceChange(newValue, "bestell_datum")
                      }
                      disabled={editInvoice ? false : true}
                      disableOpenPicker
                      renderInput={({ inputRef, inputProps, inInputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          //  disabled={editSeminar ? false : true}
                        >
                          <input
                            className="w-full"
                            ref={inputRef}
                            {...inputProps}
                          />
                          {inInputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Rechnungs-Dat.:
                </div>
                <div
                  className={`${
                    invstatus !== "offen" && "pointer-events-none opacity-50"
                  } col-span-2 p-1 bg-gray-50 sm:text-sm`}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={deLocale}
                    className="w-full"
                  >
                    <DatePicker
                      mask="__.__.____"
                      value={
                        invoiceInputs?.attributes?.datum
                          ? new Date(invoiceInputs?.attributes?.datum)
                          : ""
                      }
                      onChange={(newValue) =>
                        handleInvoiceChange(newValue, "datum")
                      }
                      disableOpenPicker
                      disabled={editInvoice ? false : true}
                      renderInput={({ inputRef, inputProps, inInputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          //  disabled={editSeminar ? false : true}
                        >
                          <input
                            className="w-full"
                            ref={inputRef}
                            {...inputProps}
                          />
                          {inInputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Teilnehmerzahl:
                </div>
                <div
                  className={`${
                    invstatus !== "offen" && "pointer-events-none opacity-50"
                  } col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 outline-none sm:text-sm`}
                  defaultValue={numberPerson.length || ""}
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Netto ohne Rabatt:
                </div>
                <input
                  className={`${
                    invstatus !== "offen" && "pointer-events-none opacity-50"
                  } col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="netto_betrag"
                  type="number"
                  disabled={editInvoice ? false : true}
                  value={invoiceInputs?.attributes?.netto_betrag || ""}
                  onChange={(e) =>
                    handleInvoiceChange(e.target.value, e.target.name)
                  }
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  TN.-Rabattanzahl:
                </div>
                <input
                  className={`${
                    (invstatus !== "offen" ||
                      invoiceInputs?.attributes?.type === "manual") &&
                    "pointer-events-none opacity-50"
                  } col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="rabattanzahl"
                  type="number"
                  disabled={editInvoice ? false : true}
                  value={invoiceInputs?.attributes?.rabattanzahl || ""}
                  onChange={(e) =>
                    handleInvoiceChange(e.target.value, e.target.name)
                  }
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Rabatte eintrag:
                </div>
                <input
                  className={`${
                    (invstatus !== "offen" ||
                      invoiceInputs?.attributes?.type === "manual") &&
                    "pointer-events-none opacity-50"
                  } col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="eintrag_rabatte"
                  type="number"
                  disabled={editInvoice ? false : true}
                  value={invoiceInputs?.attributes?.eintrag_rabatte || ""}
                  onChange={(e) =>
                    handleInvoiceChange(e.target.value, e.target.name)
                  }
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Netto-Rabattsum:
                </div>
                <input
                  className={`pointer-events-none opacity-50 col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="rabattsumme"
                  type="number"
                  disabled={true}
                  value={invoiceInputs?.attributes?.rabattsumme || ""}
                  onChange={(e) =>
                    handleInvoiceChange(e.target.value, e.target.name)
                  }
                />
                <div
                  className={`col-span-1 whitespace-nowrap flex items-center`}
                >
                  Rechnungs-Brutto:
                </div>
                <input
                  className={`pointer-events-none opacity-50 col-span-2 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm`}
                  name="brutto_betrag"
                  disabled
                  value={
                    invoiceInputs.attributes
                      ? invoiceInputs?.attributes?.brutto_betrag
                      : ""
                  }
                />
              </>

              <div className="col-span-3">Rechnungsstatus:</div>
              <div className="col-span-3">Stornosätze:</div>
              <div className="cursor-not-allowed h-min grid grid-rows-3 grid-cols-2 col-span-3 gap-1 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-red-100 to-red-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "angelegt"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Angelegt
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-cyan-100 to-cyan-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "bezahlt"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Bezahlt
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-blue-100 to-blue-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "storniert"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Storniert
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-green-100 to-green-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "offen" &&
                      invoiceInputs?.attributes?.type !== "manual"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Offen
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-purple-100 to-purple-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "offen" &&
                      invoiceInputs?.attributes?.type === "manual"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Offen-Inhouse
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-2 bg-gradient-to-b from-gray-200 to-gray-300 h-full w-2"></div>
                  <input
                    name="status"
                    type="checkbox"
                    disabled
                    className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                    checked={
                      invoiceInputs?.attributes?.status === "storno"
                        ? true
                        : false
                    }
                  />
                  <div className="ml-3 text-xs font-medium text-gray-900">
                    Storno-Re.
                  </div>
                </div>
              </div>

              <div
                className={`${
                  invstatus !== "angelegt" && "pointer-events-none opacity-50"
                } col-span-2 flex-col h-min flex`}
              >
                <div className="border mb-4 flex justify-evenly border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                  <div className="flex items-center">
                    <input
                      name="storno_saz"
                      type="checkbox"
                      disabled={
                        editInvoice &&
                        invoiceInputs?.attributes?.status === "angelegt"
                          ? false
                          : true
                      }
                      checked={
                        invoiceInputs?.attributes?.storno_saz === 100
                          ? true
                          : false
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleInvoiceChange(100, e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Storno 100%
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      name="storno_saz"
                      type="checkbox"
                      disabled={
                        editInvoice &&
                        invoiceInputs?.attributes?.status === "angelegt" &&
                        invoiceInputs?.attributes?.type !== "manual"
                          ? false
                          : true
                      }
                      checked={
                        invoiceInputs?.attributes?.storno_saz === 50
                          ? true
                          : false
                      }
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleInvoiceChange(50, e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Storno 50%
                    </div>
                  </div>
                </div>
                <GreyButtonElement
                  name={"stornocreate"}
                  text={"Stornorechnung erstellen"}
                  disabled={
                    editInvoice &&
                    invoiceInputs?.attributes?.status === "angelegt" &&
                    invoiceInputs?.attributes?.storno_saz &&
                    (selectedParticipantIds.length > 0 ||
                      invoiceInputs?.attributes?.type === "manual")
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    createInvoiceHandler(e, "genselected", "storno");
                    setLoadingDyn(e.target.name);
                  }}
                  download={false}
                />
              </div>
            </div>
            <div className={`col-span-6 `}>
              <div className="col-span-6 flex items-center pt-4">
                <div className="whitespace-nowrap">
                  Zeitverzögerung (in Minuten):
                </div>
                <input
                  name="emaildelay"
                  type="number"
                  value={emailDelayTimer}
                  onChange={(e) => {
                    setEmailDelayTimer(e.target.value);
                  }}
                  className="ml-6 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                />
              </div>
              <div className="col-span-6 flex flex-col items-center pt-5 border-lightgray">
                <div className="flex flex-wrap w-full">
                  <div className="w-full">
                    <nav className="mb-0 list-none flex px-2" role="tablist">
                      <div className="pr-5 py-2 flex items-center w-min">
                        <div
                          className={
                            "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                            (openTab === 1
                              ? "bg-primary-900 text-white"
                              : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle="tab"
                          href="#link1"
                          role="tablist"
                        >
                          Rechnungen
                        </div>
                      </div>
                      <div className="px-5 py-2 flex items-center w-min">
                        <div
                          className={
                            "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                            (openTab === 2
                              ? "bg-primary-900 text-white"
                              : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                        >
                          Emails
                        </div>
                      </div>

                      {/* <div className="px-5 py-2 flex items-center w-min">
                        <div
                          className={
                            "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                            (openTab === 3
                              ? "bg-primary-900 text-white"
                              : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                        >
                          Storno
                        </div>
                      </div> */}
                      <div className="px-5 py-2 flex items-center w-min">
                        <div
                          className={
                            "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap " +
                            (openTab === 3
                              ? "bg-primary-900 text-white"
                              : "text-gray-500 hover:  hover:bg-navbargray")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link4"
                          role="tablist"
                        >
                          TN.-Bescheinig.
                        </div>
                      </div>
                    </nav>

                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded">
                      <div className="px-2 py-5 flex-auto">
                        <div className="tab-content tab-space">
                          <div
                            className={openTab === 1 ? "block" : "hidden"}
                            id="link1"
                          >
                            <div className="grid grid-cols-2 grid-rows-2 gap-3 px-5">
                              <div className="col-span-1">
                                <GreyButtonElement
                                  disabled={selectedInvoiceIds?.length === 0}
                                  name={"selectinvdownload"}
                                  text={"selektierte Rechnung Drucken"}
                                  onClick={(e) => {
                                    downloadDocHandler(
                                      e,
                                      "invoice",
                                      "genselected",
                                      selectedInvoiceIds
                                    );
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  disabled={
                                    selectedInvoiceIds?.length === 0 ||
                                    invstatus !== "angelegt"
                                  }
                                  name={"selectinvxrechnungdownload"}
                                  text={"XRechnung Ausdrucken"}
                                  onClick={(e) => {
                                    downloadDocHandler(
                                      e,
                                      "xrechnung",
                                      "genselected",
                                      selectedInvoiceIds
                                    );
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  name={"allinvoicedownload"}
                                  text={"angelegte Rechnungen Drucken"}
                                  onClick={(e) => {
                                    downloadDocHandler(e, "invoice", "genall");
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  disabled
                                  name={"selectinvcopycreate"}
                                  text={"selektierte rechnungskopie erst."}
                                  onClick={(e) => {
                                    downloadDocHandler(
                                      e,
                                      invoiceInputs?.attributes?.pdf_id
                                    );
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={false}
                                />
                              </div>
                              {userDetails.role.id === 1 && (
                                <div className="col-span-1">
                                  <GreyButtonElement
                                    name={"invoiceexport"}
                                    text={"buchhaltung export (csv)"}
                                    onClick={() => {
                                      setInvoiceExport(true);
                                      // downloadDocHandler(
                                      //   e,
                                      //   invoiceInputs?.attributes?.pdf_id
                                      // );
                                      // setLoadingDyn(e.target.name);
                                    }}
                                    download={false}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={openTab === 2 ? "block" : "hidden"}
                            id="link2"
                          >
                            <div className="grid grid-cols-2 gap-4 pb-8">
                              <GreyButtonElement
                                width={"w-full"}
                                name={"allinvoicedemail"}
                                text={"Rechnungs-Emails Generieren"}
                                onClick={(e) => {
                                  emailInvoiceHandler("invoice", "genall");
                                  setLoadingDyn(e.target.name);
                                }}
                                download={false}
                              />
                              <GreyButtonElement
                                width={"w-full"}
                                name={"selectedinvoicedemail"}
                                disabled={selectedInvoiceIds?.length === 0}
                                onClick={(e) => {
                                  emailInvoiceHandler("invoice", "genselected");
                                  setLoadingDyn(e.target.name);
                                }}
                                text={"Selek. Rech.-Emails Generieren"}
                                download={false}
                              />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                              <GreyButtonElement
                                width={"w-full"}
                                name={"paymentreminder"}
                                disabled={!invoiceInputs?.id ? true : false}
                                onClick={() => {
                                  setSendEmail("paymentreminder");
                                }}
                                text={"Zahlungserinnerungen/Storno"}
                                download={false}
                              />

                              <GreyButtonElement
                                width={"w-full"}
                                name={"genall"}
                                disabled={false}
                                onClick={() => {
                                  setSendEmail("genall");
                                }}
                                text={"Emails direkt an alle"}
                                download={false}
                              />

                              <GreyButtonElement
                                width={"w-full"}
                                name={"genselected"}
                                disabled={
                                  selectedParticipantIds?.length === 0
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  setSendEmail("genselected");
                                }}
                                text={"Emails direkt an Ausgewählte Teilnehmer"}
                                download={false}
                              />

                              <GreyButtonElement
                                width={"w-full"}
                                name={"genselectedkunden"}
                                disabled={
                                  selectedInvoiceIds?.length === 0
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  setSendEmail("genselectedkunden");
                                }}
                                text={"Emails direkt an Ausgewählte Kunden"}
                                download={false}
                              />
                            </div>
                          </div>
                          <div
                            className={openTab === 3 ? "block" : "hidden"}
                            id="link4"
                          >
                            <div className="grid grid-cols-2 grid-rows-23 gap-3 px-5">
                              <div className="col-span-1">
                                <GreyButtonElement
                                  name={"tnlist"}
                                  text={"Seminar-Teilnehmerliste"}
                                  onClick={(e) => {
                                    adhocGenPdfHandler(e, e.target.name);
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  disabled={!checkForActiveParticipants.length}
                                  name={"placeholder"}
                                  text={"Tischkarten erstellen"}
                                  onClick={(e) => {
                                    adhocGenPdfHandler(e, e.target.name);
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  disabled={!checkForActiveParticipants.length}
                                  name={"surveycreate"}
                                  text={"Bewertungsbogen"}
                                  onClick={(e) => {
                                    adhocGenPdfHandler(e, "courserating");
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={true}
                                />
                              </div>
                              <div className="col-span-1">
                                <GreyButtonElement
                                  name={"genpartcert"}
                                  text={"Zertifikat erstellen"}
                                  onClick={(e) => {
                                    createInvoiceHandler(
                                      e,
                                      "genall",
                                      "tncertificate"
                                    );
                                    setLoadingDyn(e.target.name);
                                  }}
                                  download={false}
                                  disabled={validationIssues}
                                />
                                {validationIssues && (
                                  <div className="text-xs mt-t text-red-600">
                                    Ungültige E-Mail-Adresse in der
                                    Teilnehmerliste.
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailModal
        activeParticipantCheck={
          seminarParticipantsList?.filter((sempart) =>
            Number(
              sempart?.attributes?.id_seminar?.data?.id === seminarInputs.id &&
                sempart?.attributes?.status === "active"
            )
          )?.length > 0
        }
        resetAndGet={resetAndGet}
        userDetails={userDetails}
        getFutureEmails={getFutureEmails}
        emailDelayTimer={emailDelayTimer}
        invoiceParticipantConnection={invoiceParticipantConnection(
          sendEmail === "genselectedkunden"
            ? selectedInvoiceIds
            : [invoiceInputs.id]
        )}
        invoiceInputs={invoiceInputs}
        GreyButtonElement={GreyButtonElement}
        notify={notify}
        token={token}
        selectedParticipantIds={selectedParticipantIds}
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        seminarInputs={seminarInputs}
        raumeSelection={raumeSelection}
      />
      {userDetails.role.id === 1 && (
        <ExportInvoiceModal
          notify={notify}
          token={token}
          invoiceExport={invoiceExport}
          setInvoiceExport={setInvoiceExport}
        />
      )}
    </main>
  );
};

export default Rechnungen;
