import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";

import { ProSidebar, Menu, MenuItem, SidebarFooter } from "react-pro-sidebar";

import Logo from "./img/biteg-logo.png";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CreateIcon from "@mui/icons-material/Create";
import Badge from "@mui/material/Badge";
import ListIcon from "@mui/icons-material/List";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";

import { useNavigate } from "react-router-dom";

const PageLayout = ({
  getUserDetails,
  userDetails,
  token,
  notify,
  logOut,
  seminarSelection,
  raumeSelection,
  referentSelection,
  seminarReferenteSelection,
  seminarParticipantsList,
  seminarInputs,
  setSeminarInputs,
  getData,
  clientSelection,
  storedClientSelection,
  participantSelection,
  storedParticipantSelection,
  getClientData,
  getParticipantData,
  invoiceSelection,
  getInvoiceData,
  getAllUsersData,
  allUsers,
  editSeminar,
  setEditSeminar,
  errorHandling,
  setErrorHandling,
  downloadHandler,
  fileSelection,
  emailQueryResults,
  getFutureEmails,
  getStoredParticipantDetails,
  getStoredClientData,
  getSemPartData,
  setEmailQueryMode,
  emailQueryMode,
  WPToken,
  selectSeminarOnFirstLoad,
  setSelectSeminarOnFirstLoad,
  searchByClientOrSeminar,
  triggerSeminarSelect,
  setTriggerSeminarSelect,
  idFilter,
  dateRangeFilter,
  exactDateFilter,
  setIdFilter,
  setExactDateFilter,
  setDateRangeFilter,
  getSeminarData,
  axiosGetEmailListsRoute,
  wordPressAudienceOptions,
  axiosCustomQueryRoute,
}) => {
  const [sidebarClose, setSidebarClose] = useState(false);

  const { pathname } = useLocation();

  let navigate = useNavigate();

  const navMenu = () => {
    return (
      <ProSidebar
        width={"100%"}
        collapsedWidth={"35%"}
        collapsed={sidebarClose}
        className="justify-between bg-navbargray relative"
      >
        <Menu className="">
          <MenuItem
            className="flex"
            icon={
              <div>
                <ArrowForwardIosIcon
                  className={`${
                    sidebarClose ? "" : "rotate-180"
                  } h-10 hover:text-orange-900`}
                  onClick={() =>
                    setSidebarClose((prev) => (prev === false ? true : false))
                  }
                />
              </div>
            }
          >
            <img
              className={`${sidebarClose ? "hidden" : ""} h-8`}
              src={Logo}
              alt="BITEG"
            />
          </MenuItem>

          {userDetails.id === 1 ||
            (userDetails.id === 2 && (
              <MenuItem
                className={
                  pathname === "/seminare/approvaladmin" &&
                  sidebarClose === false
                    ? "bg-white text-orange-900 rounded-xl m-1"
                    : "rounded-xl m-1"
                }
                icon={
                  <div
                    className={
                      pathname === "/seminare/approvaladmin"
                        ? "bg-white rounded-xl p-2"
                        : ""
                    }
                  >
                    <Badge
                      badgeContent={
                        participantSelection.filter((participant) =>
                          seminarSelection.some(
                            (seminar) =>
                              seminar.id === participant.attributes.id_seminar
                          )
                        ).length
                      }
                      color="primary"
                    >
                      <AdminPanelSettingsIcon
                        className={
                          pathname === "/seminare/approvaladmin"
                            ? "text-orange-900"
                            : ""
                        }
                      />
                    </Badge>
                  </div>
                }
              >
                Neuanmeldungen
                <Link to="/seminare/approvaladmin" />
              </MenuItem>
            ))}

          <MenuItem
            className={
              pathname === "/seminare/semverwaltung" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div>
                <CreateIcon
                  className={
                    pathname === "/seminare/semverwaltung"
                      ? "text-orange-900"
                      : ""
                  }
                />
              </div>
            }
          >
            Seminarverwaltung
            <Link to="/seminare/semverwaltung" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/seminare/seminarzusammenfassung" &&
              sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/seminare/seminarzusammenfassung"
                    ? "bg-white rounded-xl p-2"
                    : ""
                }
              >
                <ListIcon
                  className={
                    pathname === "/seminare/seminarzusammenfassung"
                      ? "text-orange-900"
                      : ""
                  }
                />
              </div>
            }
          >
            Kompl. Seminarliste
            <Link to="/seminare/seminarzusammenfassung" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/seminare/bestsemverwaltung" &&
              sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/seminare/bestsemverwaltung"
                    ? "bg-white rounded-xl p-2"
                    : ""
                }
              >
                <CheckCircleOutlineIcon
                  className={
                    pathname === "/seminare/bestsemverwaltung"
                      ? "text-orange-900"
                      : ""
                  }
                />
              </div>
            }
          >
            Bestätigte Seminare
            <Link to="/seminare/bestsemverwaltung" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/seminare/raume/verwaltung" &&
              sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/seminare/raume/verwaltung"
                    ? "bg-white rounded-xl p-2"
                    : ""
                }
              >
                <ApartmentIcon
                  className={
                    pathname === "/seminare/raume/verwaltung"
                      ? "text-orange-900"
                      : ""
                  }
                />
              </div>
            }
          >
            Räume
            <Link to="/seminare/raume/verwaltung" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/suche" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/suche" ? "bg-white rounded-xl p-2" : ""
                }
              >
                <SearchIcon
                  className={pathname === "/suche" ? "text-orange-900" : ""}
                />
              </div>
            }
          >
            Suche
            <Link to="/suche" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/seminare/referenten/verwaltung" &&
              sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/seminare/referenten/verwaltung"
                    ? "bg-white rounded-xl p-2"
                    : ""
                }
              >
                <PersonIcon
                  className={
                    pathname === "/seminare/referenten/verwaltung"
                      ? "text-orange-900"
                      : ""
                  }
                />
              </div>
            }
          >
            Referenten
            <Link to="/seminare/referenten/verwaltung" />
          </MenuItem>

          <MenuItem
            className={
              pathname === "/kunden/verwaltung" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/kunden/verwaltung"
                    ? "bg-white rounded-xl p-2"
                    : ""
                }
              >
                <GroupsIcon
                  className={
                    pathname === "/kunden/verwaltung" ? "text-orange-900" : ""
                  }
                />
              </div>
            }
          >
            Kunden
            <Link to="/kunden/verwaltung" />
          </MenuItem>
          <MenuItem
            className={
              pathname === "/rechnungen" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : seminarInputs.id !== undefined
                ? "rounded-xl m-1"
                : "rounded-xl m-1 opacity-25 pointer-events-none cursor-not-allowed"
            }
            icon={
              <div
                className={
                  pathname === "/rechnungen" ? "bg-white rounded-xl p-2" : ""
                }
              >
                <DescriptionIcon
                  className={
                    pathname === "/rechnungen" ? "text-orange-900" : ""
                  }
                />
              </div>
            }
          >
            Rechnungen
            <Link to="/rechnungen" />
          </MenuItem>
          <MenuItem
            className={
              pathname === "/emailexport" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/emailexport" ? "bg-white rounded-xl p-2" : ""
                }
              >
                <EmailIcon
                  className={
                    pathname === "/emailexport" ? "text-orange-900" : ""
                  }
                />
              </div>
            }
          >
            Werbung E-Mail Export
            <Link to="/emailexport" />
          </MenuItem>
          <MenuItem
            className={
              pathname === "/emailpreview" && sidebarClose === false
                ? "bg-white text-orange-900 rounded-xl m-1"
                : "rounded-xl m-1"
            }
            icon={
              <div
                className={
                  pathname === "/emailpreview" ? "bg-white rounded-xl p-2" : ""
                }
              >
                <Badge badgeContent={emailQueryResults.length} color="primary">
                  <SendIcon
                    className={
                      pathname === "/emailpreview" ? "text-orange-900" : ""
                    }
                  />
                </Badge>
              </div>
            }
          >
            E-Mail Ausgangsordner
            <Link to="/emailpreview" />
          </MenuItem>
        </Menu>

        <SidebarFooter className="flex justify-between">
          {userDetails?.role?.id === 1 ? (
            <MenuItem
              className="footerEndButton"
              onClick={() => navigate("/options")}
              icon={
                <div className={`${sidebarClose ? "hidden" : "ml-5"}`}>
                  Benutzer
                </div>
              }
            ></MenuItem>
          ) : null}
          <MenuItem
            className="footerEndButton"
            onClick={() => logOut()}
            icon={
              <div className={`${sidebarClose ? "hidden" : "mr-5"}`}>
                Abmelden
              </div>
            }
          ></MenuItem>
        </SidebarFooter>
      </ProSidebar>
    );
  };

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div
          className={`flex flex-col fixed inset-y-0 ${
            sidebarClose ? "w-56" : "w-64"
          }`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow overflow-y-auto">
            <div className="flex-1 flex flex-col">
              <nav className="flex-1 space-y-1">{navMenu()}</nav>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col ${
            sidebarClose ? "pl-20" : "pl-64"
          } transition-padding`}
        >
          <Outlet
            context={{
              userDetails,
              getUserDetails,
              token,
              notify,
              seminarSelection,
              raumeSelection,
              referentSelection,
              seminarReferenteSelection,
              seminarInputs,
              setSeminarInputs,
              getData,
              seminarParticipantsList,
              participantSelection,
              storedParticipantSelection,
              clientSelection,
              storedClientSelection,
              getClientData,
              getParticipantData,
              invoiceSelection,
              getInvoiceData,
              getAllUsersData,
              allUsers,
              editSeminar,
              setEditSeminar,
              errorHandling,
              setErrorHandling,
              downloadHandler,
              fileSelection,
              emailQueryResults,
              getFutureEmails,
              getStoredParticipantDetails,
              getStoredClientData,
              getSemPartData,
              setEmailQueryMode,
              emailQueryMode,
              WPToken,
              selectSeminarOnFirstLoad,
              setSelectSeminarOnFirstLoad,
              searchByClientOrSeminar,
              triggerSeminarSelect,
              setTriggerSeminarSelect,
              idFilter,
              dateRangeFilter,
              exactDateFilter,
              setIdFilter,
              setExactDateFilter,
              setDateRangeFilter,
              getSeminarData,
              axiosGetEmailListsRoute,
              wordPressAudienceOptions,
              axiosCustomQueryRoute,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PageLayout;
