import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
//import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function UserModal({
  open,
  setOpen,
  userInputs,
  setUserInputs,
  handleChange,
  currentUserHandler,
  formHandler,
  formErrorHandling,
  setFormErrorHandling,
  deliteHandler,
  setTryDelete,
  tryDelete,
  openTab,
  setOpenTab,
  errorHandling,
  setErrorHandling,
}) {
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordWrong, setIsCPasswordWrong] = useState(false);

  useEffect(
    () =>
      open === "add" &&
      setUserInputs({
        username: "",
        email: "",
        role: "1",
        // max_teilnehmer: 0,
        // anzahl_skripten: 0,
        // max_tn_zahl: 0,
        // id_raum: 423,
      }),
    [open, setUserInputs]
  );

  useEffect(() => {
    if (isCPasswordWrong) {
      if (password === cPassword) {
        setShowErrorMessage(false);
        handleChange(password, "password");
      } else {
        setShowErrorMessage(true);
      }
    }
    // eslint-disable-next-line
  }, [cPassword, password, isCPasswordWrong]);

  const handleCPassword = (e) => {
    setCPassword(e.target.value);
    setIsCPasswordWrong(true);
  };

  const disablebutton =
    open === "edit"
      ? (showErrorMessage && isCPasswordWrong) ||
        errorHandling?.email === false ||
        errorHandling?.phone === false ||
        !userInputs.username ||
        !userInputs.email
      : (showErrorMessage && isCPasswordWrong) ||
        errorHandling?.email === false ||
        errorHandling?.phone === false ||
        !userInputs.username ||
        !userInputs.email ||
        !userInputs.password;

  const pwError =
    open === "edit"
      ? showErrorMessage && isCPasswordWrong
      : !userInputs.password || (showErrorMessage && isCPasswordWrong);

  return (
    <Modal
      open={
        (open === "edit" && Object.keys(userInputs).length > 0) ||
        open === "add"
          ? true
          : false
      }
      aria-describedby="modal-modal-description"
      onClose={() => {
        //  setOpen(false);
        //setFormErrorHandling(null);
      }}
    >
      <Box
        sx={{ overflowY: "scroll" }}
        className="h-screen flex items-center justify-center"
      >
        <div
          id="modal-modal-description"
          className="p-4 flex items-center justify-center"
        >
          <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all">
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 px-10 py-5 pb-4 w-[600px] h-[500px]">
              <div className="col-span-12 border-b border-lightgray pb-5 flex justify-between items-end h-16">
                <div>
                  {open === "edit"
                    ? "Benutzerprofil aktualisieren"
                    : "Benutzerprofil hinzufügen"}
                </div>
                {open === "edit" && (
                  <button
                    type="button"
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                    onClick={() => setTryDelete(true)}
                  >
                    Entfernen
                  </button>
                )}
              </div>

              <nav
                className="col-span-12 flex justify-between items-center h-min"
                role="tablist"
              >
                <div className="py-2 h-min">
                  <div
                    className={
                      "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap h-min " +
                      (openTab === 1
                        ? "bg-primary-900 text-white"
                        : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Benutzer Einstellungen
                  </div>
                </div>
                <div className="py-2 h-min">
                  <div
                    className={
                      "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap h-min " +
                      (openTab === 2
                        ? "bg-primary-900 text-white"
                        : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                  >
                    SMTP Einstellungen
                  </div>
                </div>
              </nav>

              {openTab === 1 && (
                <>
                  <div
                    className={`col-span-4 ${
                      !userInputs.username ? "text-red-600" : ""
                    }`}
                  >
                    Name:
                  </div>
                  <input
                    name="username"
                    variant="outlined"
                    className={`col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                      !userInputs.username ? "border-red-600" : ""
                    }`}
                    value={
                      userInputs.username === null ? "" : userInputs.username
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    //error={determineIfError("username")}
                  />
                  <div
                    className={`col-span-4 ${
                      errorHandling?.email === false || !userInputs.email
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    E-Mail:
                  </div>
                  <input
                    name="email"
                    variant="outlined"
                    className={`col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                      errorHandling?.email === false || !userInputs.email
                        ? "border-red-600"
                        : ""
                    }`}
                    value={userInputs.email === null ? "" : userInputs.email}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name, "email")
                    }
                    //error={determineIfError("username")}
                  />
                  {formErrorHandling?.message === "Email already taken" ? (
                    <>
                      <div className="col-span-4 h-min"></div>
                      <div className="col-span-8 text-red-600 text-sm h-min">
                        Dieser E-Mail wird bereits verwendet
                      </div>
                    </>
                  ) : null}
                  <div
                    className={`col-span-4 ${
                      errorHandling?.phone === false ? "text-red-600" : ""
                    }`}
                  >
                    Telefon:
                  </div>
                  <input
                    name="phone"
                    type="tel"
                    variant="outlined"
                    className={`col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                      errorHandling?.phone === false ? "border-red-600" : ""
                    }`}
                    value={userInputs.phone === null ? "" : userInputs.phone}
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name, "phone")
                    }
                    //error={determineIfError("username")}
                  />
                  <div className="col-span-4 flex items-center">
                    Benutzertype
                  </div>
                  {/*  <div className="col-span-8">{userInputs?.role?.id}</div> */}
                  <FormGroup className="col-span-8 select-none  h-min">
                    <FormControlLabel
                      control={
                        <Switch
                          value={userInputs?.role?.id}
                          checked={userInputs?.role?.id === 2 ? false : true}
                          onChange={(e) =>
                            setUserInputs((current) => ({
                              ...current,
                              role: {
                                id: e.target.checked ? 1 : 2,
                              },
                            }))
                          }
                        />
                      }
                      label="Administrator"
                    />
                  </FormGroup>

                  <div
                    className={`col-span-4 ${pwError ? "text-red-600" : ""}`}
                  >
                    Passwort:
                  </div>
                  <input
                    name="password"
                    variant="outlined"
                    className={`col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                      pwError ? "border-red-600" : ""
                    }`}
                    value={userInputs.password}
                    onChange={(e) => {
                      handleChange(e.target.value, e.target.name);
                      setPassword(e.target.value);
                    }}
                  />
                  <div
                    className={`col-span-4 ${
                      showErrorMessage && isCPasswordWrong ? "text-red-600" : ""
                    }`}
                  >
                    Passwort bestatigen:
                  </div>
                  <input
                    name="confirmpassword"
                    variant="outlined"
                    className={`col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min ${
                      showErrorMessage && isCPasswordWrong
                        ? "border-red-600"
                        : ""
                    }`}
                    onChange={handleCPassword}
                    value={cPassword}
                  />
                  {showErrorMessage && isCPasswordWrong ? (
                    <>
                      <div className="col-span-4 "></div>
                      <div className="col-span-8 text-red-500 h-min text-sm">
                        {" "}
                        Passwords did not match{" "}
                      </div>
                    </>
                  ) : (
                    <div className="col-span-12 h-5"></div>
                  )}
                  <div className="col-span-4"></div>
                </>
              )}

              {openTab === 2 && (
                <>
                  <div className="col-span-4">SMTP Server:</div>
                  <input
                    name="smtp_server"
                    variant="outlined"
                    className="col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                    value={
                      userInputs.smpt_server === null
                        ? ""
                        : userInputs.smtp_server
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                  />
                  <div className="col-span-4">SMTP Port:</div>
                  <input
                    name="smtp_port"
                    variant="outlined"
                    className="col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                    value={
                      userInputs.smpt_port === null ? "" : userInputs.smtp_port
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                  />
                  <div className="col-span-4">SMTP Benutzername:</div>
                  <input
                    name="smtp_username"
                    variant="outlined"
                    className="col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                    value={
                      userInputs.smpt_username === null
                        ? ""
                        : userInputs.smtp_username
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                  />
                  <div className="col-span-4">SMTP Password:</div>
                  <input
                    name="smtp_password"
                    variant="outlined"
                    className="col-span-8 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                    value={
                      userInputs.smpt_password === null
                        ? ""
                        : userInputs.smtp_password
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                  />
                  <div className="col-span-12 h-[30px]"></div>
                </>
              )}
            </div>

            <div className="bg-gray-50 py-3 px-6 flex justify-end">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setOpen(false);
                  setUserInputs({});
                  setPassword("");
                  setCPassword("");
                  setFormErrorHandling(null);
                  setErrorHandling({});
                }}
              >
                Abbrechen
              </button>
              <button
                disabled={disablebutton}
                onClick={(event) => {
                  formHandler(event, open);
                  setPassword("");
                  setCPassword("");
                }}
                className="disabled:opacity-25 ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {open === "edit" ? "Aktualisieren" : "Hinzufügen"}
              </button>
            </div>
            <Modal
              open={tryDelete}
              aria-describedby="modal-modal-description"
              onClose={() => {}}
            >
              <Box
                sx={{ overflowY: "scroll", maxHeight: "100%" }}
                className="h-screen flex items-center justify-center"
              >
                <div
                  id="modal-modal-description"
                  className="p-4 h-full flex items-center justify-center"
                >
                  <div className="relative p-4 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full">
                    <div className="px-10 py-5 w-full">
                      Möchten Sie den Benutzer{" "}
                      <span className="font-bold">{userInputs.username}</span>{" "}
                      dauerhaft entfernen?
                    </div>
                    <div className="flex justify-between px-10 py-5">
                      <button
                        type="button"
                        className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                        onClick={() => setTryDelete(false)}
                      >
                        Abbrechen
                      </button>
                      <button
                        type="button"
                        className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                        onClick={() => deliteHandler(userInputs.id)}
                      >
                        Entfernen
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
