import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json";

const axioslogin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/auth/local/`,
  method: "post",
});

const axiosGetUserData = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/users/me/`,
  method: "get",
});

const axiosGetAllUsersData = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/users/`,
  method: "get",
});

const axiosSeminarRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/seminars/`,
});

const axiosRaumeRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/raeumes/`,
});

const axiosMitarbeiterRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/mitarbeiters/`,
});

const axiosAemterRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/aemters/`,
});

const axiosReferentRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/referents/`,
});

const axiosVb_SemRefRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/vb-sem-refs/`,
});

const axiosTeilnehmRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/teilnehms/`,
});

const axiosCustomerPreApproveRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/aemter-pre-approvals/`,
});

const axiosParticipantPreApproveRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/teilnehm-pre-approvals/`,
});

const axiosSeminarParticipantRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/sem-tn-listes/`,
});

const axiosInvoiceRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/rechnungs/`,
});

const axiosSendEmailRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/sendemails/`,
});

const axiosWordPressSeminarRoute = axios.create({
  baseURL: "https://biteg.de/wp-json/wp/v2/seminar",
});

const axiosWordPressAudienceRoute = axios.create({
  baseURL: "https://biteg.de/wp-json/wp/v2/audience",
});

const axiosWordPressLoginRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/getwptoken/`,
});

const axiosDownloadPdfRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/ftpget/`,
  timeout: 360000,
});

const axiosGenerateInvoice = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/pdfgen/`,
  timeout: 360000,
});

const axiosInvoiceExportRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/export/rechnung`,
});

const axiosEmailExportRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/export/email/`,
});

const axiosGetEmailListsRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/export/getlists/`,
});

const axiosInvoiceToPDFRoute = axios.create({
  baseURL: "https://invoiceapi.solutions.io/api/invoices/create",
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosCustomQueryRoute = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/customquery`,
  method: "post",
});

export {
  axioslogin,
  axiosSeminarRoute,
  axiosGetUserData,
  axiosGetAllUsersData,
  axiosRaumeRoute,
  axiosMitarbeiterRoute,
  axiosAemterRoute,
  axiosReferentRoute,
  axiosVb_SemRefRoute,
  axiosTeilnehmRoute,
  axiosWordPressSeminarRoute,
  axiosCustomerPreApproveRoute,
  axiosParticipantPreApproveRoute,
  axiosSeminarParticipantRoute,
  axiosInvoiceRoute,
  axiosInvoiceToPDFRoute,
  axiosSendEmailRoute,
  axiosDownloadPdfRoute,
  axiosGenerateInvoice,
  axiosInvoiceExportRoute,
  axiosEmailExportRoute,
  axiosWordPressLoginRoute,
  axiosCustomQueryRoute,
  axiosGetEmailListsRoute,
  axiosWordPressAudienceRoute,
};
