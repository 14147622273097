import { useEffect, Fragment, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import CircularProgress from "@mui/material/CircularProgress";

import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

import { checkZip } from "../helpers/validation";

import listofGermanStates from "./partials/listofGermanStates";

import { HTMLEditor } from "./partials/HTMLEditor";

export default function EmployeeAddModal({
  open,
  setOpen,
  formEmployee,
  employeeInputs,
  setEmployeeInputs,
  handleSecondChange,
  formErrorHandling,
  setFormErrorHandling,
  loading,
  errorHandling,
  setErrorHandling,
  clientInputs,
}) {
  useEffect(
    () =>
      open === "add" &&
      setEmployeeInputs({
        bundes_land: "Berlin und Brandenburg",
        ist_ausgeschieden: false,
        persoenlich: "n",
      }),
    [open, setEmployeeInputs]
  );

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const [query, setQuery] = useState("");

  const filteredlistofGermanStates =
    query === ""
      ? listofGermanStates
      : listofGermanStates.filter((land) => {
          return land.province.toLowerCase().includes(query.toLowerCase());
        });

  const landValue = listofGermanStates
    .filter((state) => state.province === employeeInputs.bundes_land)
    .map((item) => item.province)[0];

  const disablebutton =
    errorHandling?.telefon === false ||
    errorHandling?.email === false ||
    !employeeInputs.name ||
    !employeeInputs.vorname ||
    !employeeInputs.email ||
    !employeeInputs.telefon ||
    !employeeInputs.geschlecht ||
    !checkZip(employeeInputs.plz);

  return (
    <Modal
      open={
        ((open === "edit" || open === "copy") &&
          Object.keys(employeeInputs).length > 0) ||
        open === "add"
          ? true
          : false
      }
      aria-describedby="modal-modal-description"
      onClose={() => {
        //  setOpen(false);
        //  setEmployeeInputs({});
        setFormErrorHandling(null);
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="h-screen flex items-center justify-center"
      >
        <div
          id="modal-modal-description"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="modalContainerOuter">
            <div className="modalContainerMiddle">
              <div className="modalContainerInner">
                <div className="flex justify-between">
                  {" "}
                  <h3>
                    {open === "edit"
                      ? "Mitarbeiter aktualisieren"
                      : "Mitarbeiter hinzufügen"}
                  </h3>
                  <button
                    type="button"
                    className={`bg-primary-900 relative mb-4 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                    onClick={(event) => {
                      event.preventDefault();
                      setEmployeeInputs((prior) => ({
                        ...prior,
                        bundes_land: clientInputs.bundes_land_id || "",
                        telefon: clientInputs.telefon1 || "",
                        plz: clientInputs.plz || "",
                        ort: clientInputs.ort || "",
                        strasse: clientInputs.strasse || "",
                        haus_num: clientInputs.haus_num || "",
                        email: clientInputs.email.includes("@")
                          ? `@${clientInputs.email.split("@").slice(-1)}`
                          : "",
                      }));
                    }}
                  >
                    Kundendaten Übertragen
                  </button>
                </div>
              </div>

              <span className="col-span-1 flex items-center">B-land:</span>

              <div className="col-span-4">
                <Combobox
                  value={landValue}
                  onChange={(event) =>
                    handleSecondChange(event.province, "bundes_land")
                  }
                >
                  <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                      <Combobox.Input
                        className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                        onChange={(event) => setQuery(event.target.value)}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredlistofGermanStates.map((land) => (
                          <Combobox.Option
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 px-2 ${
                                active
                                  ? "bg-primary-900 text-white"
                                  : "bg-white"
                              }`
                            }
                            key={land.id}
                            value={land}
                          >
                            {land.province}
                          </Combobox.Option>
                        ))}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
              <div className="col-span-4"></div>
              <span className="col-span-1 whitespace-nowrap flex items-center">
                Titel:
              </span>
              <input
                name="title"
                className="col-span-2 inputDefault"
                variant="outlined"
                value={employeeInputs.title || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("title")}
              />

              <span
                className={`col-span-1 whitespace-nowrap flex items-center ${
                  !employeeInputs.name ? "text-red-600" : ""
                }`}
              >
                Name:
              </span>
              <input
                name="name"
                className={`col-span-4 inputDefault ${
                  !employeeInputs.name ? "border-red-600" : ""
                }`}
                variant="outlined"
                value={employeeInputs.name || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("name")}
              />

              <span
                className={`col-span-1 whitespace-nowrap flex items-center ${
                  !employeeInputs.vorname ? "text-red-600" : ""
                }`}
              >
                Vorname:
              </span>
              <input
                name="vorname"
                className={`col-span-3 inputDefault ${
                  !employeeInputs.vorname ? "border-red-600" : ""
                }`}
                variant="outlined"
                value={employeeInputs.vorname || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("vorname")}
              />

              <span
                className={`col-span-1 flex items-center ${
                  !employeeInputs.geschlecht ? "text-red-600" : ""
                }`}
              >
                Anrede:
              </span>
              <div className="col-span-2 flex items-center justify-between">
                <div
                  className={`flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none ${
                    !employeeInputs.geschlecht ? "border-red-600" : ""
                  }`}
                >
                  <div className="flex items-center">
                    <input
                      name="geschlecht"
                      type="checkbox"
                      checked={employeeInputs.geschlecht === "f" ? true : false}
                      value={employeeInputs.geschlecht || ""}
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleSecondChange("f", e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Frau
                    </div>
                  </div>
                  <div className="flex items-center">
                    <input
                      name="geschlecht"
                      type="checkbox"
                      checked={employeeInputs.geschlecht === "m" ? true : false}
                      value={employeeInputs.geschlecht || ""}
                      className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                      onChange={(e) => handleSecondChange("m", e.target.name)}
                    />
                    <div className="ml-3 text-xs font-medium text-gray-900">
                      Herr
                    </div>
                  </div>
                </div>
              </div>

              <span className="col-span-1 whitespace-nowrap flex items-center">
                Position:
              </span>
              <input
                name="position"
                className="col-span-4 inputDefault"
                variant="outlined"
                value={employeeInputs.position || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("position")}
              />
              <span className="col-span-1 whitespace-nowrap flex items-center">
                Abteilung:
              </span>
              <input
                name="abteilung"
                className="col-span-3 inputDefault"
                variant="outlined"
                value={employeeInputs.abteilung || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("abteilung")}
              />

              {open === "edit" ? (
                <>
                  <span className="col-span-1 flex items-center whitespace-nowrap">
                    Ausgeschieden:
                  </span>
                  <div className="col-span-2 flex items-center justify-between">
                    <div className="flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                      <div className="flex place-content-evenly w-full">
                        <div className="flex items-center">
                          <input
                            name="ist_ausgeschieden"
                            type="checkbox"
                            checked={
                              employeeInputs.ist_ausgeschieden === true
                                ? true
                                : false
                            }
                            value={employeeInputs.ist_ausgeschieden}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleSecondChange(
                                e.target.checked ? true : false,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Ja
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="ist_ausgeschieden"
                            type="checkbox"
                            checked={
                              employeeInputs.ist_ausgeschieden === true
                                ? false
                                : true
                            }
                            value={employeeInputs.ist_ausgeschieden}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleSecondChange(
                                e.target.checked ? false : true,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Nein
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-3"></div>
              )}

              <span
                className={`col-span-1 whitespace-nowrap flex items-center" ${
                  errorHandling?.telefon === false || !employeeInputs.telefon
                    ? "text-red-600"
                    : ""
                }`}
              >
                Telefon:
              </span>
              <input
                name="telefon"
                className={`col-span-2 inputDefault ${
                  errorHandling?.telefon === false || !employeeInputs.telefon
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                type="tel"
                value={employeeInputs.telefon || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name, "phone")
                }
                error={determineIfError("telefon")}
              />

              <span
                className={`col-span-1 whitespace-nowrap flex items-center ${
                  errorHandling?.email === false || !employeeInputs.email
                    ? "text-red-600"
                    : ""
                }`}
              >
                E-Mail:
              </span>
              <input
                name="email"
                className={`col-span-3 inputDefault ${
                  determineIfError("email") || errorHandling?.email === false
                    ? "border-red-600"
                    : ""
                }`}
                variant="outlined"
                value={employeeInputs.email || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name, "email")
                }
                error={determineIfError("email")}
              />

              <span
                className={`"col-span-1 whitespace-nowrap flex items-center ${
                  !checkZip(employeeInputs.plz) ? "text-red-600" : ""
                }`}
              >
                PLZ:
              </span>
              <input
                name="plz"
                className={`col-span-1 inputDefault ${
                  !checkZip(employeeInputs.plz) ? "border-red-600" : ""
                }`}
                type="number"
                variant="outlined"
                value={employeeInputs.plz || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("plz")}
              />

              {open === "edit" ? (
                <>
                  <span className="col-span-1 flex items-center whitespace-nowrap">
                    Email Opt-Out:
                  </span>
                  <div className="col-span-2 flex items-center justify-between">
                    <div className="flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none">
                      <div className="flex place-content-evenly w-full">
                        <div className="flex items-center">
                          <input
                            name="email_opt_out"
                            type="checkbox"
                            checked={
                              employeeInputs.email_opt_out === true
                                ? true
                                : false
                            }
                            value={employeeInputs.email_opt_out}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleSecondChange(
                                e.target.checked ? true : false,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Ja
                          </div>
                        </div>
                        <div className="flex items-center">
                          <input
                            name="email_opt_out"
                            type="checkbox"
                            checked={
                              employeeInputs.email_opt_out === true
                                ? false
                                : true
                            }
                            value={employeeInputs.email_opt_out}
                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded"
                            onChange={(e) =>
                              handleSecondChange(
                                e.target.checked ? false : true,
                                e.target.name
                              )
                            }
                          />
                          <div className="ml-3 text-xs font-medium text-gray-900">
                            Nein
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-span-3" />
              )}

              <span className="col-span-1 whitespace-nowrap flex items-center">
                Ort:
              </span>
              <input
                name="ort"
                className="col-span-2 inputDefault"
                variant="outlined"
                value={employeeInputs.ort || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("ort")}
              />

              {determineIfError("email") === "This attribute must be unique" ? (
                <>
                  <div className="col-span-1 h-min"></div>
                  <div className="col-span-11 text-red-600 text-sm h-min">
                    Dieser E-Mail wird bereits verwendet
                  </div>
                </>
              ) : null}
              <span className="col-span-1 whitespace-nowrap flex items-center">
                Straẞe:
              </span>
              <input
                name="strasse"
                className="col-span-2 inputDefault"
                variant="outlined"
                value={employeeInputs.strasse || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("strasse")}
              />
              <span className="col-span-1 whitespace-nowrap flex items-center">
                Haus-Nr.:
              </span>
              <input
                name="haus_num"
                className="col-span-2 inputDefault"
                variant="outlined"
                value={employeeInputs.haus_num || ""}
                onChange={(e) =>
                  handleSecondChange(e.target.value, e.target.name)
                }
                error={determineIfError("haus_num")}
              />

              <div className="col-span-12 text-lg font-bold">
                <label>Info</label>

                <HTMLEditor
                  value={employeeInputs.bemerkung || ""}
                  onChange={(value) => {
                    handleSecondChange(value, "bemerkung");
                  }}
                  readOnly={false}
                />
              </div>
            </div>

            <div className="bg-gray-50 py-3 px-6 flex justify-end">
              <button
                type="button"
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
                onClick={() => {
                  setOpen(false);
                  setFormErrorHandling(null);
                  setErrorHandling({});
                }}
              >
                Abbrechen
              </button>
              <button
                type="button"
                disabled={disablebutton}
                //className="ml-5 whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                className={`${
                  loading === "loadingEmployee"
                    ? "bg-white hover:bg-white disabled:ring-primary-500 disabled:ring-offset-2 disabled:ring-2"
                    : "bg-primary-900 disabled:opacity-40"
                } ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                onClick={(event) => formEmployee(event, open)}
              >
                {open === "edit" ? "Aktualisieren" : "Hinzufügen"}
                {loading === "loadingEmployee" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
