import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AgGridReact } from "ag-grid-react";
import Fuse from "fuse.js";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { HTMLEditor } from "./partials/HTMLEditor";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import DownloadIcon from "@mui/icons-material/Download";

import InputAdornment from "@mui/material/InputAdornment";

import deLocale from "date-fns/locale/de";

import AG_GRID_LOCALE_DE from "./locale.de";

import Box from "@mui/material/Box";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Modal from "@mui/material/Modal";

import Kundenverwaltung from "./Kundenverwaltung";
import { Combobox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import Fileviewer from "./Fileviewer";

import {
  axiosSeminarParticipantRoute,
  axiosGenerateInvoice,
} from "../helpers/axios";

export default function SeminarSelectModal({
  // open,
  setOpen,
  seminarInputs,
  handleSeminarChange,
  raumeSelection,
  seminarReferentListInputs,
  currentReferentHandler,
  referentSelection,
  seminarReferenteSelection,
  handleSeminarReferentChange,
  updateSeminarHandler,
  addSeminarReferentHandler,
  setSeminarInputs,
  formErrorHandling,
  setFormErrorHandling,
  setSeminarReferentListInputs,
  loading,
  editSeminar,
  setEditSeminar,
  seminarParticipantsList,
  storedClientSelection,
  rangeValidation,
  dateValidation,
  getData,
  notify,
  token,
  getSemPartData,
  getInvoiceData,
  // wordPressAudienceOptions,
}) {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const [openTab, setOpenTab] = useState(1);
  const [query, setQuery] = useState({ bezeichnung: "" });
  const [raumeQueryResults, setRaumeQueryResults] = useState([]);
  const [loadingDyn, setLoadingDyn] = useState([]);

  const [addParticipant, setAddParticipant] = useState(false);
  const [swapParticipant, setSwapParticipant] = useState(false);
  const [deleteParticipant, setDeleteParticipant] = useState(false);

  const [selectedParticipant, setSelectedParticipant] = useState([]);

  const [referentQuery, setReferentQuery] = useState("");

  const adhocGenPdfHandler = useCallback(
    async (e, type) => {
      e.preventDefault();
      const data = {
        searchbyseminarid: seminarInputs.id,
        type,
      };

      try {
        let result;

        result = await axiosGenerateInvoice.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          await (async () => {
            for await (let file of result?.data) {
              const newfile = new Blob([new Uint8Array(file.data).buffer], {
                type: "application/pdf",
              });
              const url = window.URL.createObjectURL(newfile);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "Referent-Rechnung");
              document.body.appendChild(link);
              link.click();
            }
          })();

          setLoadingDyn(false);

          // const url = window.URL.createObjectURL(new Blob([result.data]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute(
          //   "download",
          //   `Biteg-Referent-Rechnung-SemID-${seminarInputs.id}.pdf`
          // ); //or any other extension
          // document.body.appendChild(link);
          // link.click();
          // setLoadingDyn(false);
        }
      } catch (err) {
        notify(
          "error",
          err?.response?.data?.error?.message || "Etwas ist schief gelaufen..."
        );
        setLoadingDyn(false);
      }
    },
    [token, seminarInputs.id, notify]
  );

  const GreyButtonElement = ({
    name,
    text,
    onClick,
    disabled,
    download,
    width,
    color,
  }) => {
    const button_color =
      color === "blue"
        ? "text-white bg-primary-900 hover:bg-primary-600 border-primary-900"
        : "bg-gray-200 hover:bg-gray-300 hover:text-orange-900 focus:ring-gray-300";

    // to add later to a blue button
    // className="disabled:pointer-events-none disabled:bg-primary-400 disabled:border-primary-400 inline-flex items-center px-4 py-2 shadow-sm text-white font-medium rounded-md bg-primary-900 hover:bg-white hover:text-black border-4 border-primary-900"

    return (
      <button
        disabled={disabled}
        onClick={onClick}
        name={name}
        className={`${
          loadingDyn === name
            ? "bg-white pointer-events-none hover:bg-white disabled:ring-gray-300 disabled:ring-offset-2 disabled:ring-2 text-white focus:ring-orange-900"
            : `${button_color} disabled:opacity-25`
        } relative ${
          width && width
        } whitespace-nowrap inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
  focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none`}
      >
        {text}
        {loadingDyn === name && (
          <div className="absolute">
            <div className="relative">
              <CircularProgress
                size={36}
                sx={{
                  color: "#F4A305",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-7.5px",
                  marginLeft: "-15px",
                }}
              />
              {download && (
                <DownloadIcon
                  size={24}
                  sx={{
                    color: "#F4A305",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginLeft: "-9px",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </button>
    );
  };

  useEffect(() => {
    const options = {
      includeScore: false,
      useExtendedSearch: true,
      threshold: 0.2,
      keys: ["attributes.bezeichnung", "attributes.ort"],
    };
    const fuse = new Fuse(raumeSelection, options);
    if (raumeSelection?.length > 0) {
      let result;
      if (query.bezeichnung !== "") {
        result = fuse.search({
          $or: [
            ...(query.bezeichnung && [
              {
                $path: ["attributes.bezeichnung"],
                $val: query.bezeichnung,
              },
            ]),
            ...(query.bezeichnung && [
              {
                $path: ["attributes.ort"],
                $val: query.bezeichnung,
              },
            ]),
          ],
        });
        const destructitems = result.map(({ item }) => item);

        setRaumeQueryResults(
          destructitems.filter(
            (raume) => raume.id !== 423 && raume.attributes.is_aktiv
          )
        );
      } else {
        setRaumeQueryResults(
          raumeSelection.filter(
            (raume) => raume.id !== 423 && raume.attributes.is_aktiv
          )
        );
      }
    }

    // eslint-disable-next-line
  }, [query, raumeSelection]);

  const gridRefSeminar = useRef();

  const gridRefParticipants = useRef();
  const onRaumeSelectionChanged = useCallback(
    (event) => {
      if (event.node.isSelected()) {
        event.node.setSelected(false);
        setSeminarInputs((current) => ({
          ...current,
          id_raum: null,
          raum_details: {},
        }));
        event.api.onFilterChanged();
      } else {
        event.node.setSelected(true, true);
        setSeminarInputs((current) => ({
          ...current,
          id_raum: event.data.id,
          raum_details: {
            ...raumeSelection.find((raume) => raume.id === event.data.id)
              .attributes,
          },
        }));
      }
    },
    // [handleSeminarChange]
    [raumeSelection, setSeminarInputs]
  );

  useEffect(() => {
    if (seminarInputs.id_raum === 423 && gridRefSeminar?.current?.api) {
      gridRefSeminar.current.api.deselectAll();
    }
  }, [seminarInputs.id_raum, gridRefSeminar]);

  const [columnSeminarDefs] = useState([
    {
      checkboxSelection: true,
      minWidth: 50,
      maxWidth: 50,
      cellStyle: { pointerEvents: "none" },
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bezeichnung",
      headerName: "Raume Bezeichnung",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.is_aktiv",
      headerName: "Aktiv",
      sortable: true,
      //
      width: 70,
      minWidth: 70,
      headerClass: "header-blue",
      resizable: true,
      cellRenderer: (params) => <div>{params.value ? "Ja" : "Nein"}</div>,
    },
    {
      field: "attributes.ort",
      headerName: "Ort",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.bundesland",
      headerName: "Bundesland",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.ansprechpartner",
      headerName: "Ansprechpartner",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
  ]);

  const [columnParticipantsDefs] = useState([
    {
      field: "attributes.id_teilnehmer.data.attributes.name",
      headerName: "Name",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.id_teilnehmer.data.attributes.vorname",
      headerName: "Vorname",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.id_teilnehmer.data.attributes.email",
      headerName: "E-Mail",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "attributes.id_teilnehmer.data.attributes.telefon",
      headerName: "Telefon",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      field: "clientData.attributes.bezeichnung",
      headerName: "Kunde",
      sortable: true,
      headerClass: "header-blue",
      resizable: true,
    },
    {
      headerName: "Löschen",
      sortable: true,
      width: 110,
      headerClass: "header-blue",

      cellRenderer: (params) => {
        return (
          <DeleteIcon
            className={`${
              params.node.data.attributes.invoiced &&
              params.node.data.attributes.rechnung_id.data.attributes.status !==
                "bezahlt"
                ? "pointer-events-none opacity-10"
                : "cursor-pointer"
            }`}
            onClick={() => setDeleteParticipant(params.node.data.id)}
          />
        );
      },
    },
  ]);

  const participantDeleteHandler = async (participantid) => {
    try {
      let result;

      result = await axiosSeminarParticipantRoute.delete(`/${participantid}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data) {
        notify("info", "Teilnehmer gelöscht");
        getData();

        const getlocation = raumeSelection.find(
          (raume) =>
            Number(raume.id) === Number(result.data.updatedSeminar.id_raum)
        );

        const formattedlocation = {
          id: getlocation.id,
          ...getlocation.attributes,
        };

        setSeminarInputs({
          ...result.data.updatedSeminar,
          raum_details: formattedlocation,
        });
        getInvoiceData();
        getSemPartData();
        setDeleteParticipant(false);
      }
    } catch (err) {
      notify("error", "Etwas ist schief gelaufen...");
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return (
      seminarInputs.hasOwnProperty("id_raum") && seminarInputs.id_raum !== null
    );
  }, [seminarInputs]);

  const determineIfError = (field) => {
    return formErrorHandling?.filter((e) => e.path[0] === field)[0]?.message;
  };

  const refeentenFiltered = referentSelection?.filter((referent) =>
    seminarReferenteSelection.some(
      (semref) =>
        semref.attributes.id_seminar === seminarInputs.id &&
        referent.id === semref.attributes.id_referent
    )
      ? false
      : true
  );

  const refeentenFilteredActive = refeentenFiltered.filter(
    (referent) => referent.attributes.ist_aktuell === true
  );

  const filteredPeople =
    referentQuery === ""
      ? refeentenFilteredActive
      : refeentenFilteredActive.filter(
          (person) =>
            (person.attributes.name + " " + person.attributes.vorname)
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(referentQuery.toLowerCase().replace(/\s+/g, "")) //|| person.attributes.vorname.toLowerCase().replace(/\s+/g, '').includes(referentQuery.toLowerCase().replace(/\s+/g, ''))
        );

  const seminarReferentHandler = async (e, referentid, seminarid, action) => {
    try {
      setLoadingDyn(e.target.name);
      await addSeminarReferentHandler(e, action, seminarid, referentid);
    } catch (err) {
      notify("error", "Etwas ist schief gelaufen...");
    } finally {
      setLoadingDyn(false);
    }
  };

  return (
    <div className="sm:mt-0 text-sm text-left font-medium text-gray-700">
      <div className=" md:col-span-2 flex justify-center mt-2">
        <div
          className={`bg-white ${
            seminarInputs.id ? "" : "opacity-30 pointer-events-none"
          } `}
        >
          <div className="flex w-full">
            <div className="w-full">
              <div className="flex">
                <div className="w-10/12 space-y-2">
                  <div className="w-full grid grid-cols-12 gap-3">
                    <span className="col-span-12 text-gray-500 text-xs flex justify-end items-center">
                      Bearbeitung{" "}
                      {editSeminar ? "eingeschaltet" : "ausgeschaltet"}
                      {editSeminar ? (
                        <LockOpenIcon fontSize="small" />
                      ) : (
                        <LockIcon fontSize="small" />
                      )}
                    </span>

                    <span
                      className={`${
                        determineIfError("thema") || seminarInputs.thema === ""
                          ? "text-red-600"
                          : ""
                      } col-span-2 flex items-center`}
                    >
                      Seminarthema:
                    </span>
                    <input
                      name="thema"
                      className="col-span-10 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                      variant="outlined"
                      value={seminarInputs.thema || ""}
                      onChange={(e) =>
                        handleSeminarChange(e.target.value, e.target.name)
                      }
                      error={determineIfError("thema")}
                      disabled={editSeminar ? false : true}
                    />
                    {determineIfError("thema") ===
                    "thema must be at least 1 characters" ? (
                      <>
                        <div className="col-span-2 h-min"></div>
                        <div className="col-span-10 text-red-600 text-sm h-min">
                          Dieses Feld darf nicht leer gelassen werden
                        </div>
                      </>
                    ) : null}
                    <span
                      className={`${
                        dateValidation(seminarInputs.datum) && seminarInputs.id
                          ? "text-red-600"
                          : ""
                      } col-span-2 flex items-center`}
                    >
                      Datum:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={deLocale}
                      >
                        <DatePicker
                          mask="__.__.____"
                          value={seminarInputs.datum}
                          onChange={(newValue) => {
                            handleSeminarChange(newValue, "datum");
                          }}
                          disabled={editSeminar ? false : true}
                          // disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="relative"
                              disabled={editSeminar ? false : true}
                              // onBlur={dataError}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span
                      className={`${
                        dateValidation(seminarInputs.end_datum) &&
                        seminarInputs.id
                          ? "text-red-600"
                          : ""
                      } col-span-2 flex items-center`}
                    >
                      bis:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={deLocale}
                      >
                        <DatePicker
                          value={seminarInputs.end_datum}
                          onChange={(newValue) =>
                            handleSeminarChange(newValue, "end_datum")
                          }
                          disabled={editSeminar ? false : true}
                          // disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={editSeminar ? false : true}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    {rangeValidation("daterange") && (
                      <>
                        <div className="col-span-6"></div>
                        <div className="col-span-6 text-red-600 text-sm">
                          Das Enddatum des Seminars darf nicht vor dem
                          Startdatum liegen
                        </div>
                      </>
                    )}
                    <span
                      className={`${
                        determineIfError("anfangs_zeit") ||
                        (dateValidation(seminarInputs.anfangs_zeit) &&
                          seminarInputs.id)
                          ? "text-red-600"
                          : ""
                      } col-span-2 flex items-center`}
                    >
                      Anfangszeit:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          ampm={false}
                          inputFormat="HH:mm"
                          mask="__:__"
                          label="Custom input"
                          value={seminarInputs.anfangs_zeit || null}
                          onChange={(newValue) =>
                            handleSeminarChange(newValue, "anfangs_zeit")
                          }
                          disabled={editSeminar ? false : true}
                          disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={editSeminar ? false : true}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span className="col-span-2 flex items-center">
                      Stornotermin:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={deLocale}
                      >
                        <DatePicker
                          mask="__.__.____"
                          value={seminarInputs.frage_termin}
                          onChange={(newValue) =>
                            handleSeminarChange(newValue, "frage_termin")
                          }
                          disabled={editSeminar ? false : true}
                          // disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={editSeminar ? false : true}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span
                      className={`${
                        determineIfError("end_zeit") ||
                        (dateValidation(seminarInputs.end_zeit) &&
                          seminarInputs.id)
                          ? "text-red-600"
                          : ""
                      } col-span-2 flex items-center`}
                    >
                      Endzeit:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <TimePicker
                          ampm={false}
                          inputFormat="HH:mm"
                          mask="__:__"
                          value={seminarInputs.end_zeit || null}
                          onChange={(newValue) =>
                            handleSeminarChange(newValue, "end_zeit")
                          }
                          disabled={editSeminar ? false : true}
                          disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={editSeminar ? false : true}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <span className="col-span-2 flex items-center">
                      Zahlungstermin:
                    </span>
                    <div className="flex col-span-4 items-center">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={deLocale}
                      >
                        <DatePicker
                          mask="__.__.____"
                          value={seminarInputs.zahl_termin}
                          onChange={(newValue) =>
                            handleSeminarChange(newValue, "zahl_termin")
                          }
                          disabled={editSeminar ? false : true}
                          // disableOpenPicker
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              disabled={editSeminar ? false : true}
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    {rangeValidation("timerange") && (
                      <>
                        <div className="col-span-2"></div>
                        <div className="col-span-10 text-red-600 text-sm">
                          Die Schließzeit kann nicht die Öffnungszeit des
                          Seminars vorwegnehmen
                        </div>
                      </>
                    )}
                    <span
                      className={`${
                        seminarInputs.preis === "" ? "text-red-600" : ""
                      } col-span-2 flex items-center`}
                    >
                      Netto-Teilnehmerpreis:
                    </span>
                    <div className="flex col-span-4 items-center">
                      {" "}
                      <input
                        name="preis"
                        type="number"
                        className="p-1 w-[202px] border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                        variant="outlined"
                        value={seminarInputs.preis || ""}
                        onChange={(e) =>
                          handleSeminarChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("preis")}
                        disabled={editSeminar ? false : true}
                      />
                    </div>

                    <div className="col-span-2 flex items-center">
                      Markierung:
                    </div>
                    <div className="flex col-span-4 items-center">
                      <input
                        name="marke"
                        className="p-1 w-[202px] border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                        variant="outlined"
                        value={seminarInputs.marke || ""}
                        onChange={(e) =>
                          handleSeminarChange(e.target.value, e.target.name)
                        }
                        error={determineIfError("marke")}
                        disabled={editSeminar ? false : true}
                      />
                    </div>

                    {seminarInputs?.id_raum === 423 ? (
                      <>
                        <div className="flex items-center whitespace-nowrap col-span-2">
                          Veranstaltungsort:
                        </div>
                        <div className="flex items-center col-span-10">
                          <div className="font-bold">Online</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center whitespace-nowrap col-span-2">
                          Veranstaltungsort:
                        </div>
                        <div className="flex items-center col-span-10">
                          <div className="font-bold">
                            {seminarInputs?.raum_details?.ort &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.ort
                              : ""}
                          </div>
                        </div>
                        <div className="flex items-center whitespace-nowrap col-span-2">
                          Raumbezeichung:
                        </div>
                        <div className="flex items-center col-span-10">
                          <div className="font-bold">
                            {seminarInputs?.raum_details?.bezeichnung &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.bezeichnung
                              : ""}
                          </div>
                        </div>
                        <div className="flex items-center whitespace-nowrap col-span-2">
                          Anschrift:
                        </div>
                        <div className="flex items-center col-span-10">
                          <div className="font-bold">
                            {seminarInputs?.raum_details?.strasse &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.strasse
                              : ""}{" "}
                            {seminarInputs?.raum_details?.haus_nr &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.haus_nr
                              : ""}
                            <br />
                            {seminarInputs?.raum_details?.plz &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.plz
                              : ""}{" "}
                            {seminarInputs?.raum_details?.ort &&
                            seminarInputs?.id_raum !== null
                              ? seminarInputs.raum_details.ort
                              : ""}
                          </div>
                        </div>
                      </>
                    )}

                    <span className="col-span-2 flex items-center">
                      Max. Teilnehmerzahl:
                    </span>
                    <input
                      name="max_tn_zahl"
                      type="number"
                      className="col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                      variant="outlined"
                      value={seminarInputs.max_tn_zahl || ""}
                      onChange={(e) =>
                        handleSeminarChange(e.target.value, e.target.name)
                      }
                      disabled={editSeminar ? false : true}
                    />
                    <div className="col-span-1"></div>
                    <span className="col-span-2 flex items-center">
                      Aktuelle Teilnehmerzahl:
                    </span>
                    <div className="col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50  focus:ring-0 outline-none sm:text-sm flex items-center h-min">
                      {seminarInputs.max_teilnehmer || "0"}
                    </div>
                    <div className="col-span-1"></div>
                    <span className="col-span-2 flex items-center">
                      Gedruckte Skripte:
                    </span>
                    <input
                      name="anzahl_skripten"
                      type="number"
                      className="col-span-1 p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm h-min"
                      variant="outlined"
                      value={seminarInputs.anzahl_skripten || ""}
                      onChange={(e) =>
                        handleSeminarChange(e.target.value, e.target.name)
                      }
                      disabled={editSeminar ? false : true}
                    />
                    <span className="col-span-2 flex items-center">
                      Manuelle Bestätigung:
                    </span>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={editSeminar ? false : true}
                            name="is_confirmed"
                            onChange={(e) =>
                              handleSeminarChange(
                                e.target.checked,
                                e.target.name
                              )
                            }
                            checked={seminarInputs.is_confirmed ? true : false}
                          />
                        }
                        label={seminarInputs.is_confirmed ? "Ein" : "Aus"}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-span-12 flex flex-col items-center pt-5 border-lightgray">
                    <div className="w-full font-bold text-lg">
                      Seminardetails
                    </div>
                    <div className="flex flex-wrap w-full">
                      <div className="w-full">
                        <nav
                          className="mb-0 list-none flex px-2"
                          role="tablist"
                        >
                          <div className="pr-5 py-2 flex items-center w-min">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                                (openTab === 1
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(1);
                              }}
                              data-toggle="tab"
                              href="#link1"
                              role="tablist"
                            >
                              Bemerkungen
                            </div>
                          </div>
                          <div className="px-5 py-2 flex items-center w-min">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer whitespace-nowrap " +
                                (openTab === 2
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(2);
                              }}
                              data-toggle="tab"
                              href="#link2"
                              role="tablist"
                            >
                              Referent/-in
                            </div>
                          </div>

                          <div className="px-5 py-2 flex items-center w-min">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                                (openTab === 3
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(3);
                              }}
                              data-toggle="tab"
                              href="#link3"
                              role="tablist"
                            >
                              Räume
                            </div>
                          </div>
                          <div className="px-5 py-2 flex items-center w-min">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                                (openTab === 4
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(4);
                              }}
                              data-toggle="tab"
                              href="#link4"
                              role="tablist"
                            >
                              Webseite
                            </div>
                          </div>
                          <div className="px-5 py-2 flex items-center w-min">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                                (openTab === 5
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(5);
                              }}
                              data-toggle="tab"
                              href="#link5"
                              role="tablist"
                            >
                              Teilnehmer
                            </div>
                          </div>
                          <div className="px-5 py-2 flex items-center">
                            <div
                              className={
                                "px-5 py-2 font-medium text-sm rounded-md text-center cursor-pointer " +
                                (openTab === 6
                                  ? "bg-primary-900 text-white"
                                  : "text-gray-500 hover:text-orange-900 hover:bg-navbargray")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(6);
                              }}
                              data-toggle="tab"
                              href="#link5"
                              role="tablist"
                            >
                              Seminar-Verzeichnis
                            </div>
                          </div>
                        </nav>
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                          <div className="px-2 py-5 flex-auto">
                            <div className="tab-content tab-space">
                              <div
                                className={openTab === 1 ? "block" : "hidden"}
                                id="link1"
                              >
                                <div className="col-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	">
                                  <label className="absolute -top-2">
                                    Bemerkungen
                                  </label>

                                  <HTMLEditor
                                    value={seminarInputs.bemerkung || " "}
                                    height={"600"}
                                    onChange={(value) => {
                                      handleSeminarChange(value, "bemerkung");
                                    }}
                                    readOnly={!editSeminar}
                                  />
                                </div>
                              </div>
                              <div
                                className={openTab === 2 ? "block" : "hidden"}
                                id="link2"
                              >
                                <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                                  <div className="col-span-1 grid grid-cols-6 gap-3">
                                    <span className="col-span-2">
                                      Auswahlfeld Referenten:
                                    </span>
                                    <span className="col-span-4">
                                      <Combobox
                                        disabled={editSeminar ? false : true}
                                        value={
                                          Object.keys(seminarReferentListInputs)
                                            .length === 0
                                            ? ""
                                            : seminarReferentListInputs.name +
                                              " " +
                                              seminarReferentListInputs.vorname
                                        }
                                        onChange={currentReferentHandler}
                                      >
                                        <div className="relative mt-1">
                                          <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                            <Combobox.Input
                                              className="w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
                                              displayValue={(person) => person}
                                              onChange={(event) =>
                                                setReferentQuery(
                                                  event.target.value
                                                )
                                              }
                                            />
                                            {referentQuery === "" &&
                                            Object.keys(
                                              seminarReferentListInputs
                                            ).length === 0 ? null : (
                                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-7">
                                                <HighlightOffIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    setSeminarReferentListInputs(
                                                      {}
                                                    )
                                                  }
                                                />
                                              </Combobox.Button>
                                            )}
                                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                              <SelectorIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </Combobox.Button>
                                          </div>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            afterLeave={() =>
                                              setReferentQuery("")
                                            }
                                          >
                                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {filteredPeople.length === 0 &&
                                              referentQuery !== "" ? (
                                                <div className="relative cursor-default select-none py-2 px-4 ">
                                                  Nichts gefunden.
                                                </div>
                                              ) : (
                                                filteredPeople.map((person) => (
                                                  <Combobox.Option
                                                    key={person.id}
                                                    className={({ active }) =>
                                                      `relative cursor-default select-none py-2 px-2 ${
                                                        active
                                                          ? "bg-primary-900 text-white"
                                                          : ""
                                                      }`
                                                    }
                                                    value={person.id}
                                                  >
                                                    <span className="block truncate">
                                                      {person.attributes.name}{" "}
                                                      {
                                                        person.attributes
                                                          .vorname
                                                      }
                                                    </span>
                                                  </Combobox.Option>
                                                ))
                                              )}
                                            </Combobox.Options>
                                          </Transition>
                                        </div>
                                      </Combobox>
                                    </span>
                                    <div className="col-span-2 whitespace-nowrap flex items-center">
                                      Vereinbartes Netto-Honorar:
                                    </div>
                                    <div className="col-span-4 flex items-center">
                                      <input
                                        name="brutto_honorar_euro"
                                        variant="outlined"
                                        className="w-full h-min p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                        value={
                                          seminarReferentListInputs.brutto_honorar_euro ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleSeminarReferentChange(
                                            e.target.value,
                                            e.target.name
                                          )
                                        }
                                        error={determineIfError(
                                          "brutto_honorar_euro"
                                        )}
                                        inputprops={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              €
                                            </InputAdornment>
                                          ),
                                        }}
                                        disabled={editSeminar ? false : true}
                                      />
                                    </div>

                                    <div className="col-span-2 whitespace-nowrap flex items-center">
                                      Sonstige Kosten - Feld
                                    </div>
                                    <div className="col-span-4 flex items-center">
                                      <input
                                        name="sonstige_kosten_name"
                                        variant="outlined"
                                        className="w-full h-min p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                        value={
                                          seminarReferentListInputs.sonstige_kosten_name ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleSeminarReferentChange(
                                            e.target.value,
                                            e.target.name
                                          )
                                        }
                                        error={determineIfError(
                                          "sonstige_kosten_name"
                                        )}
                                        inputprops={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              €
                                            </InputAdornment>
                                          ),
                                        }}
                                        disabled={editSeminar ? false : true}
                                      />
                                    </div>

                                    <div className="col-span-2 whitespace-nowrap flex items-center">
                                      Sonstige Kosten - Wert
                                    </div>
                                    <div className="col-span-4 flex items-center">
                                      <input
                                        name="sonstige_kosten_wert"
                                        variant="outlined"
                                        className="w-full h-min p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                        value={
                                          seminarReferentListInputs.sonstige_kosten_wert ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleSeminarReferentChange(
                                            e.target.value,
                                            e.target.name
                                          )
                                        }
                                        error={determineIfError(
                                          "sonstige_kosten_wert"
                                        )}
                                        inputprops={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              €
                                            </InputAdornment>
                                          ),
                                        }}
                                        disabled={editSeminar ? false : true}
                                      />
                                    </div>

                                    <span className="col-span-2 flex items-center">
                                      Referent MwSt.-pflichtig:
                                    </span>
                                    <div className="col-span-4 flex items-center justify-between">
                                      <div
                                        className={`flex place-content-evenly w-full col-span-4 border border-lightgray focus-within:ring-primary-900 focus-within:border-primary-900 focus-within:ring-1 rounded-md px-3 py-2 shadow-sm outline-none ${
                                          editSeminar
                                            ? ""
                                            : "opacity-50 cursor-not-allowed"
                                        }`}
                                      >
                                        <div className="flex items-center">
                                          <input
                                            name="ist_mehrwert_steuer"
                                            type="checkbox"
                                            value={
                                              seminarReferentListInputs.ist_mehrwert_steuer
                                                ? seminarReferentListInputs.ist_mehrwert_steuer
                                                : undefined
                                            }
                                            checked={
                                              seminarReferentListInputs.ist_mehrwert_steuer
                                                ? true
                                                : false
                                            }
                                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded disabled:cursor-not-allowed"
                                            disabled={
                                              editSeminar ? false : true
                                            }
                                            onChange={(e) =>
                                              handleSeminarReferentChange(
                                                e.target.checked,
                                                e.target.name
                                              )
                                            }
                                          />
                                          <div className="ml-3 text-xs font-medium text-gray-900">
                                            mit MwSt.
                                          </div>
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            name="ist_mehrwert_steuer"
                                            type="checkbox"
                                            value={
                                              seminarReferentListInputs.ist_mehrwert_steuer
                                                ? seminarReferentListInputs.ist_mehrwert_steuer
                                                : undefined
                                            }
                                            checked={
                                              seminarReferentListInputs.ist_mehrwert_steuer ===
                                              false
                                                ? true
                                                : false
                                            }
                                            className="mr-1 focus:ring-primary-900 h-4 w-4 border-lightgray rounded disabled:cursor-not-allowed"
                                            disabled={
                                              editSeminar ? false : true
                                            }
                                            onChange={(e) =>
                                              handleSeminarReferentChange(
                                                e.target.checked ? false : true,
                                                e.target.name
                                              )
                                            }
                                          />
                                          <div className="ml-3 text-xs font-medium text-gray-900">
                                            ohne MwSt.
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-span-6 flex justify-end mt-5 h-min">
                                      <GreyButtonElement
                                        name={"add_referent"}
                                        text={"Referent hinzufügen"}
                                        disabled={
                                          seminarReferentListInputs.hasOwnProperty(
                                            "id_referent"
                                          )
                                            ? false
                                            : true
                                        }
                                        onClick={(event) => {
                                          seminarReferentHandler(
                                            event,
                                            seminarReferentListInputs?.id_referent,
                                            seminarInputs?.id,
                                            "add"
                                          );
                                        }}
                                        width="w-1/3"
                                        color={"blue"}
                                        download={false}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-span-1 flex flex-col justify-between">
                                    <div className="w-full overflow-y-auto relative">
                                      <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-primary-900">
                                          <tr>
                                            <th
                                              scope="col"
                                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                                            >
                                              Name
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                            >
                                              Honorar
                                            </th>
                                            <th
                                              scope="col"
                                              className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                                            >
                                              MwSt.
                                            </th>
                                            <th
                                              scope="col"
                                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                            >
                                              <span className="sr-only">
                                                Entfernen
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody className=" h-min overflow-y-auto">
                                          {referentSelection.length > 0 &&
                                            seminarReferenteSelection.length >
                                              0 &&
                                            seminarReferenteSelection
                                              .filter(
                                                (seminar) =>
                                                  Number(
                                                    seminar.attributes
                                                      .id_seminar
                                                  ) === Number(seminarInputs.id)
                                              )
                                              .map((referentenid) => {
                                                const {
                                                  attributes: { name, vorname },
                                                } = referentSelection.filter(
                                                  (referent) =>
                                                    Number(referent.id) ===
                                                    Number(
                                                      referentenid.attributes
                                                        .id_referent
                                                    )
                                                )[0];

                                                return (
                                                  <tr
                                                    key={referentenid.id}
                                                    className="w-full p-2 h-min"
                                                  >
                                                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 h-min">
                                                      {name + " " + vorname}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500  h-min">
                                                      {referentenid.attributes
                                                        .ref_brutto_honorar_euro
                                                        ? Number(
                                                            referentenid
                                                              .attributes
                                                              .ref_brutto_honorar_euro
                                                          ).toLocaleString(
                                                            "de-DE",
                                                            {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            }
                                                          ) + " €"
                                                        : ""}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 h-min">
                                                      {referentenid.attributes
                                                        .ist_mehrwert_steuer
                                                        ? "Ja"
                                                        : "Nein"}
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 h-min">
                                                      <GreyButtonElement
                                                        name={"delete_referent"}
                                                        text={"Löschen"}
                                                        disabled={
                                                          !editSeminar ||
                                                          loadingDyn ===
                                                            "delete_referent"
                                                            ? true
                                                            : false
                                                        }
                                                        onClick={(e) =>
                                                          seminarReferentHandler(
                                                            e,
                                                            referentenid.id,
                                                            seminarInputs.id,
                                                            "delete"
                                                          )
                                                        }
                                                        width="w-1/2"
                                                        download={false}
                                                      />
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="flex justify-evenly">
                                      <GreyButtonElement
                                        name={"honorar_uberweisung"}
                                        text={"Honorar per Überweisung"}
                                        disabled={false}
                                        onClick={(e) => {
                                          adhocGenPdfHandler(e, e.target.name);
                                          setLoadingDyn(e.target.name);
                                        }}
                                        width="w-1/2"
                                        download={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={openTab === 3 ? "block" : "hidden"}
                                id="link3"
                              >
                                <div className="col-span-6 flex flex-col items-center">
                                  <div className="flex w-full  items-center ">
                                    <FormGroup className="">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            //value={onlineLocal}
                                            checked={
                                              seminarInputs.id_raum === 423
                                            }
                                            onChange={(e) =>
                                              handleSeminarChange(
                                                e.target.checked ? 423 : null,
                                                "id_raum"
                                              )
                                            }
                                            disabled={
                                              editSeminar ? false : true
                                            }
                                          />
                                        }
                                        label="Online"
                                      />
                                    </FormGroup>
                                    <div className="whitespace-nowrap pr-2">
                                      Tippen, um Räume zu filtern:
                                    </div>
                                    <input
                                      name="bezeichnung"
                                      className="w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
                                      variant="outlined"
                                      value={query.bezeichnung}
                                      onChange={(e) =>
                                        setQuery((prior) => ({
                                          ...prior,
                                          bezeichnung: e.target.value,
                                        }))
                                      }
                                      disabled={
                                        editSeminar &&
                                        seminarInputs.id_raum !== 423
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`w-full py-2 col-span-3 ${
                                      editSeminar
                                        ? ""
                                        : "z-40 pointer-events-none opacity-30"
                                    } `}
                                  >
                                    <div
                                      className={`ag-theme-alpine z-10  ${
                                        seminarInputs.id_raum === Number(423)
                                          ? "pointer-events-none opacity-30"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        style={{
                                          height: 350,
                                        }}
                                      >
                                        <AgGridReact
                                          enableCellTextSelection
                                          defaultColDef={{
                                            filter: false,
                                          }}
                                          localeText={localeText}
                                          //domLayout={"autoHeight"}
                                          rowData={raumeQueryResults}
                                          columnDefs={columnSeminarDefs}
                                          rowSelection={"single"}
                                          onRowClicked={onRaumeSelectionChanged}
                                          isExternalFilterPresent={
                                            seminarInputs.id_raum === 423
                                              ? false
                                              : isExternalFilterPresent
                                          }
                                          suppressRowClickSelection
                                          doesExternalFilterPass={(e) =>
                                            e.data.id ===
                                              seminarInputs.id_raum &&
                                            seminarInputs.id_raum !== 423
                                          }
                                          ref={gridRefSeminar}
                                          onGridSizeChanged={(e) => {
                                            openTab === 3 &&
                                              e.api.sizeColumnsToFit();
                                          }}
                                          onModelUpdated={(e) => {
                                            if (
                                              seminarInputs.hasOwnProperty(
                                                "id_raum"
                                              ) &&
                                              seminarInputs.id_raum !== null
                                            ) {
                                              e.api.forEachNode((node) => {
                                                seminarInputs.id_raum ===
                                                  node.data.id &&
                                                  node.setSelected(true, true);
                                              });
                                            } else {
                                              e.api.forEachNode((node) => {
                                                node.setSelected(false);
                                              });
                                            }
                                          }}
                                        ></AgGridReact>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={openTab === 4 ? "block" : "hidden"}
                                id="link4"
                              >
                                <div className="grid grid-cols-6 gap-y-8 ">
                                  <div className="grid-span-6 whitespace-nowrap">
                                    <FormGroup className="pr-4 select-none">
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            disabled={
                                              loading ||
                                              seminarInputs.id === undefined
                                                ? true
                                                : false
                                            }
                                            value={
                                              seminarInputs.wp_published
                                                ? seminarInputs.wp_published
                                                : undefined
                                            }
                                            checked={
                                              seminarInputs.wp_published
                                                ? seminarInputs.wp_published
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleSeminarChange(
                                                e.target.checked,
                                                "wp_published"
                                              )
                                            }
                                          />
                                        }
                                        label="Seminar auf der Website veröffentlichen"
                                        disabled={editSeminar ? false : true}
                                      />
                                    </FormGroup>
                                  </div>
                                  <div className="col-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	">
                                    <label className="absolute -top-2">
                                      Seminarbeschreibung
                                    </label>

                                    <HTMLEditor
                                      value={seminarInputs.lang_thema || " "}
                                      onChange={(value) => {
                                        handleSeminarChange(
                                          value,
                                          "lang_thema"
                                        );
                                      }}
                                      readOnly={!editSeminar}
                                    />
                                  </div>
                                  <div className="col-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	">
                                    <label className="absolute -top-2">
                                      Teilnehmende
                                    </label>

                                    <HTMLEditor
                                      value={seminarInputs.participants || " "}
                                      onChange={(value) => {
                                        handleSeminarChange(
                                          value,
                                          "participants"
                                        );
                                      }}
                                      readOnly={!editSeminar}
                                    />
                                  </div>
                                  <div className="col-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	">
                                    <label className="absolute -top-2">
                                      Schwerpunkte
                                    </label>
                                    <HTMLEditor
                                      value={seminarInputs.focus_areas || " "}
                                      onChange={(value) => {
                                        handleSeminarChange(
                                          value,
                                          "focus_areas"
                                        );
                                      }}
                                      readOnly={!editSeminar}
                                    />
                                  </div>
                                  <div className="col-span-6 relative rounded-md pt-5 shadow-sm text-lg font-bold	">
                                    <label className="absolute -top-2">
                                      Teilnahmebedingungen
                                    </label>

                                    <HTMLEditor
                                      value={
                                        seminarInputs.conditions_of_participation ||
                                        " "
                                      }
                                      onChange={(value) => {
                                        handleSeminarChange(
                                          value,
                                          "conditions_of_participation"
                                        );
                                      }}
                                      readOnly={!editSeminar}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={openTab === 5 ? "block" : "hidden"}
                                id="link5"
                              >
                                <div className="col-span-6 flex flex-col items-center">
                                  <div className="flex w-full justify-end">
                                    {selectedParticipant.length > 0 && (
                                      <button
                                        onClick={(event) => {
                                          event.preventDefault();
                                          setSwapParticipant(true);
                                        }}
                                        className="whitespace-nowrap mr-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                      >
                                        Teilnehmer Austauschen
                                      </button>
                                    )}
                                    <button
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setAddParticipant(true);
                                      }}
                                      className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                    >
                                      Teilnehmer hinzufügen
                                    </button>
                                  </div>
                                  <div className={`w-full py-2 col-span-3  `}>
                                    <div className="ag-theme-alpine z-10">
                                      <div
                                        style={{
                                          height: 350,
                                        }}
                                      >
                                        <AgGridReact
                                          enableCellTextSelection
                                          defaultColDef={{
                                            filter: false,
                                          }}
                                          localeText={localeText}
                                          //domLayout={"autoHeight"}
                                          rowData={seminarParticipantsList
                                            ?.filter(
                                              (participant) =>
                                                participant.attributes
                                                  .id_seminar.data.id ===
                                                  seminarInputs.id &&
                                                participant.attributes
                                                  .status === "active"
                                            )
                                            .map((sempart) => {
                                              return {
                                                ...sempart,
                                                clientData:
                                                  storedClientSelection.find(
                                                    (client) =>
                                                      Number(client.id) ===
                                                      Number(
                                                        sempart.attributes
                                                          .id_teilnehmer.data
                                                          .attributes.id_amt
                                                      )
                                                  ),
                                              };
                                            })}
                                          onSelectionChanged={(event) => {
                                            setSelectedParticipant(
                                              event.api
                                                .getSelectedNodes()
                                                .map((node) => node.data)
                                            );
                                          }}
                                          columnDefs={columnParticipantsDefs}
                                          rowSelection={"single"}
                                          ref={gridRefParticipants}
                                        ></AgGridReact>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={openTab === 6 ? "block" : "hidden"}
                                id="link6"
                              >
                                <Fileviewer />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center flex-col px-2 h-min flex-1 sticky top-20 z-10 space-y-3">
                  <Fab
                    onClick={(event) => {
                      event.preventDefault();
                      if (editSeminar) {
                        setOpen(false);
                        setFormErrorHandling(null);
                        setSeminarReferentListInputs({});
                        setQuery({
                          bezeichnung: "",
                        });
                        setSeminarInputs({});
                        setRaumeQueryResults();
                        setEditSeminar(false);
                      } else {
                        setEditSeminar(true);
                      }
                    }}
                    disabled={
                      loading || seminarInputs.id === undefined ? true : false
                    }
                    color={editSeminar ? "warning" : "primary"}
                    aria-label="edit"
                  >
                    {editSeminar === false ? <EditIcon /> : <CancelIcon />}
                  </Fab>

                  <Fab
                    onClick={(event) => {
                      event.preventDefault();
                      updateSeminarHandler(event, "edit");
                    }}
                    disabled={
                      editSeminar === false ||
                      loading ||
                      seminarInputs.id === undefined ||
                      dateValidation(seminarInputs.end_datum) ||
                      dateValidation(seminarInputs.datum) ||
                      dateValidation(seminarInputs.anfangs_zeit) ||
                      dateValidation(seminarInputs.end_zeit) ||
                      rangeValidation("daterange") ||
                      rangeValidation("timerange") ||
                      seminarInputs.id_raum === null ||
                      seminarInputs.preis === "" ||
                      seminarInputs.thema === ""
                    }
                    color="primary"
                    aria-label="edit"
                  >
                    {loading ? <CircularProgress /> : <SaveIcon />}
                  </Fab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <Modal
          open={addParticipant || swapParticipant ? true : false}
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ overflowY: "scroll", maxHeight: "100%" }}
            className="customized-scrollbar h-screen flex items-center justify-center"
          >
            <div
              id="modal-modal-description"
              className="p-4 h-full customized-scrollbar flex items-center justify-center w-11/12"
            >
              <div className="p-4 relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full">
                <Kundenverwaltung
                  mode={
                    (addParticipant && "addnewparticipant") ||
                    (swapParticipant && "swapparticipant")
                  }
                  selectedParticipantInvoicesDetails={
                    selectedParticipant.length > 0 && selectedParticipant[0]
                  }
                  seminarId={seminarInputs.id}
                  setAddParticipant={setAddParticipant}
                  setSwapParticipant={setSwapParticipant}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </>
      <>
        <Modal
          open={deleteParticipant ? true : false}
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ overflowY: "scroll", maxHeight: "100%" }}
            className="customized-scrollbar h-screen flex items-center justify-center"
          >
            <div
              id="modal-modal-description"
              className="p-4 h-full customized-scrollbar flex items-center justify-center w-1/3"
            >
              <div className="p-4 relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full">
                <div className="pb-4">
                  Bitte bestätigen Sie, dass Sie diesen Teilnehmer löschen
                  möchten
                </div>
                <div className="flex w-full justify-end">
                  <button
                    onClick={() => setDeleteParticipant(false)}
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 mr-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-900 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Nein
                  </button>
                  <button
                    onClick={() => participantDeleteHandler(deleteParticipant)}
                    className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-500 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                  >
                    Ja
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    </div>
  );
}
