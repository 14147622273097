import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";
import {
  axiosCustomerPreApproveRoute,
  axiosParticipantPreApproveRoute,
  axiosTeilnehmRoute,
  axiosAemterRoute,
  axiosWordPressSeminarRoute,
  axiosGenerateInvoice,
} from "../helpers/axios";
import ClientApproveModule from "./ClientApproveModule";
import ParticipantApproveModule from "./ParticipantApproveModule";

import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { AgGridReact } from "ag-grid-react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import AG_GRID_LOCALE_DE from "./locale.de";

import SeminarSelectGrid from "./SeminarSelectGrid";
import listofGermanStates from "./partials/listofGermanStates";

const WPApprovalAdmin = ({ filter }) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);

  const {
    token,
    notify,
    seminarSelection,
    seminarInputs,
    setSeminarInputs,
    getData,
    participantSelection,
    storedParticipantSelection,
    clientSelection,
    storedClientSelection,
    getClientData,
    getParticipantData,
    editSeminar,
    setEditSeminar,
    errorHandling,
    setErrorHandling,
    getStoredParticipantDetails,
    WPToken,
  } = useOutletContext();

  const [open, setOpen] = useState(false);

  const [rawClientData, setRawClientData] = useState({});

  const [storedClientId, setStoredClientId] = useState(null);

  const [loadingDyn, setLoadingDyn] = useState(false);

  const [formErrorHandling, setFormErrorHandling] = useState(null);

  const [allowLinkToExternalSeminar, setAllowLinkToExternalSeminar] =
    useState(false);

  const [secondaryErrorHandling, setSecondaryErrorHandling] = useState(null);
  const [participantInputs, setParticipantInputs] = useState({});

  const [query, setQuery] = useState({
    semid: "",
    anfangsdatum: "",
    enddatum: "",
    thema: "",
  });

  const [addParticipant, setAddParticipant] = useState(false);

  const adhocGenPdfHandler = useCallback(
    async (e, type) => {
      e.preventDefault();
      const data = {
        searchbyseminarid: seminarInputs.id,
        type,
        searchbycustomerid: storedClientId,
        rawclientdata: rawClientData,
      };

      try {
        let result;

        result = await axiosGenerateInvoice.post(
          `/`,
          {
            data: data,
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Biteg-${
              (type === "tnlistbyclient" && "Neuanmeldungen") ||
              (type === "tnlist" && "Teilnehmerlist") ||
              (type === "courserating" && "Bewertungsbogen")
            }-SemID-${seminarInputs.id}.pdf`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoadingDyn(false);
        }
      } catch (err) {
        console.log(err);
        setLoadingDyn(false);
        setFormErrorHandling(err.response.data.error.details.errors);
      }
    },
    [token, seminarInputs.id, storedClientId, rawClientData]
  );

  const gridRefClient = useRef();

  const columnClientDefs = useMemo(
    () => [
      {
        field: "edit",
        headerName: "Bearbeiten",
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        cellRenderer: () => (
          <EditIcon
            fontSize="small"
            className={`${
              !seminarInputs.id && "opacity-40 pointer-events-none"
            } cursor-pointer flex h-full`}
            onClick={() => setOpen("client")}
          />
        ),
      },
      {
        field: "linked",
        headerName: "Linked",
        width: 65,
        minWidth: 65,
        headerClass: "header-blue",
        cellRenderer: (params) =>
          params.data.id_linked_client ? (
            <LinkIcon className="flex h-full" />
          ) : (
            <LinkOffIcon className="pointer-events-none opacity-30 flex h-full" />
          ),
      },
      {
        field: "firme",
        headerName: "Firme",
        sortable: true,

        width: 200,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email_besteller",
        headerName: "E-Mail Besteller",
        sortable: true,

        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "email_rechnung",
        headerName: "E-Mail Rechnung",
        sortable: true,

        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "bemerkung",
        headerName: "Bemerkung",
        sortable: true,

        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "ansprechpartner",
        headerName: "Ansprechpartner",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "telefon",
        headerName: "Telefon",
        sortable: true,

        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "ort",
        headerName: "Ort",
        sortable: true,

        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "participants",
        headerName: "Teilnehmer",
        sortable: true,

        width: 92,
        minWidth: 92,
        headerClass: "header-blue",
        resizable: true,
      },
    ],
    [seminarInputs]
  );

  useEffect(() => {
    if (storedClientId) {
      getStoredParticipantDetails(storedClientId);
    }
  }, [storedClientId, getStoredParticipantDetails]);

  const onClientSelectionChanged = useCallback(() => {
    const selectedRows = gridRefClient.current.api.getSelectedRows();
    const selectedCompany = selectedRows.length === 1 ? selectedRows[0] : "";
    setRawClientData(selectedCompany);
  }, []);

  const formClient = async (event, mode, data) => {
    event.preventDefault();
    try {
      let result;

      if (mode === "edit") {
        result = await axiosAemterRoute.put(
          `/${data.id_linked_client}`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (mode === "add") {
        result = await axiosAemterRoute.post(
          "/",
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      await axiosCustomerPreApproveRoute.put(
        `/${rawClientData.id}`,
        {
          data: { id_linked_client: result.data.data.id },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStoredClientId(result.data.data.id);

      notify("info", "Client Linked");

      setErrorHandling({});
      getClientData();
      setOpen("participant");
      setLoadingDyn(false);
    } catch (err) {
      console.log(err);
      setFormErrorHandling(err.response.data.error.details.errors);
      notify("error", "Etwas ist schief gelaufen...");
      setLoadingDyn(false);
    }
  };

  const formParticipant = async (event, mode, data) => {
    event.preventDefault();
    try {
      let result;

      if (mode === "edit") {
        result = await axiosTeilnehmRoute.put(
          `/${data.id_linked_participant}`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (mode === "add") {
        result = await axiosTeilnehmRoute.post(
          "/",
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const addid = await axiosParticipantPreApproveRoute.put(
        `/${data.stored_participant_id}`,
        {
          data: {
            id_linked_participant: result.data.data.id,
            ist_besteller: data.ist_besteller === true ? true : false,
            ...(allowLinkToExternalSeminar === true && {
              id_seminar: seminarInputs.id,
            }),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (addid.data.course_full) {
        const wptoken = WPToken;

        let formdata = new FormData();

        formdata.append("acf[is_booked_up]", "1");

        try {
          let result;
          result = await axiosWordPressSeminarRoute.post(
            `/${addid.data.id_wp_entry}`,
            formdata,
            {
              headers: {
                Authorization: `Bearer ${wptoken}`,
              },
            }
          );

          if (result?.data) {
            console.log(result.data);
          }
        } catch (err) {
          notify("error", "Etwas ist schief gelaufen...");

          console.log(err);
        }
      }

      addParticipant && setAddParticipant(false);
      setSecondaryErrorHandling({});

      notify("info", "Participant Linked");
      getParticipantData();
      setErrorHandling({});
      getData();
      setParticipantInputs({});
      setLoadingDyn(false);
    } catch (err) {
      setLoadingDyn(false);
      setFormErrorHandling(err.response.data.error.details.errors);
      notify(
        "error",
        err.response.data.error.message || "Etwas ist schief gelaufen..."
      );
    }
  };

  return (
    <main>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex flex-1 justify-between m-6 ">
          <h3>Neuanmeldungen</h3>
        </div>
      </div>
      <div className="w-full px-6 py-5 text-sm text-left font-medium text-gray-700">
        <div className="grid grid-cols-6 gap-x-6 gap-y-4 w-full">
          <SeminarSelectGrid
            filter={filter}
            query={query}
            setQuery={setQuery}
            seminarInputs={seminarInputs}
            seminarSelection={seminarSelection.filter((seminar) =>
              allowLinkToExternalSeminar
                ? true
                : participantSelection.some(
                    (participant) =>
                      Number(seminar.id) ===
                        Number(participant.attributes.id_seminar) &&
                      participant.attributes.id_linked_participant === null
                  )
            )}
            setSeminarInputs={setSeminarInputs}
            setOpen={setOpen}
            editSeminar={editSeminar}
            setEditSeminar={setEditSeminar}
          />
          <div className="ag-theme-alpine w-full col-span-12 mt-16">
            {rawClientData?.id && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => {
                        setAllowLinkToExternalSeminar((current) => {
                          if (current) {
                            setSeminarInputs({});
                            setRawClientData({});
                            return false;
                          } else {
                            setSeminarInputs({});
                            return true;
                          }
                        });
                      }}
                      checked={allowLinkToExternalSeminar}
                    />
                  }
                  label="Link zu anderen Seminaren"
                />
              </FormGroup>
            )}

            {((participantSelection.length > 0 && seminarInputs.id) ||
              (allowLinkToExternalSeminar && rawClientData)) && (
              <AgGridReact
                enableCellTextSelection
                rowHeight={33}
                defaultColDef={{
                  filter: false,
                }}
                rowData={clientSelection
                  .filter((client) =>
                    allowLinkToExternalSeminar
                      ? client.id === rawClientData.id
                      : participantSelection.some(
                          (participant) =>
                            // show only inbound clients that apply to selected seminar and have unlinked participants
                            Number(seminarInputs.id) ===
                              Number(participant.attributes.id_seminar) &&
                            participant.attributes.id_linked_participant ===
                              null &&
                            Number(
                              participant.attributes.id_amt_pre_approval
                            ) === Number(client.id)
                        )
                  )
                  .map((client) => ({
                    ...client.attributes,
                    id: client.id,

                    //determine total number of participants for this client
                    participants: participantSelection.filter(
                      (participant) =>
                        client.id ===
                          participant.attributes.id_amt_pre_approval &&
                        client.attributes.id_seminar ===
                          participant.attributes.id_seminar
                    ).length,
                  }))}
                columnDefs={columnClientDefs}
                rowSelection={"single"}
                onRowClicked={onClientSelectionChanged}
                ref={gridRefClient}
                domLayout={"autoHeight"}
                localeText={localeText}
                onGridSizeChanged={(e) => {
                  e.api.sizeColumnsToFit();
                }}
              ></AgGridReact>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={open ? true : false}
        aria-describedby="modal-modal-description"
        onClose={() => {
          //  setOpenClient(false);
          setFormErrorHandling(null);
        }}
      >
        <Box
          sx={{ overflowY: "scroll", maxHeight: "100%" }}
          className="customized-scrollbar h-screen flex items-center justify-center"
        >
          <div
            id="modal-modal-description"
            className="p-4 h-full flex items-center justify-center"
          >
            {open === "client" && Object.keys(rawClientData).length > 0 && (
              <ClientApproveModule
                setLoadingDyn={setLoadingDyn}
                loadingDyn={loadingDyn}
                setOpen={setOpen}
                open={open}
                rawClientData={rawClientData}
                storedClientSelection={storedClientSelection}
                getClientData={getClientData}
                listofGermanStates={listofGermanStates}
                formClient={formClient}
                setFormErrorHandling={setFormErrorHandling}
                formErrorHandling={formErrorHandling}
                errorHandling={errorHandling}
                setErrorHandling={setErrorHandling}
              />
            )}
            {open === "participant" && (
              <ParticipantApproveModule
                // seminarInputs={seminarInputs}
                // allowLinkToExternalSeminar={allowLinkToExternalSeminar}
                setLoadingDyn={setLoadingDyn}
                loadingDyn={loadingDyn}
                adhocGenPdfHandler={adhocGenPdfHandler}
                setOpen={setOpen}
                open={open}
                participantSelection={participantSelection.filter(
                  (participant) =>
                    rawClientData.id ===
                    participant.attributes.id_amt_pre_approval
                  // &&
                  // seminarInputs.id === participant.attributes.id_seminar
                )}
                storedParticipantSelection={storedParticipantSelection.filter(
                  (dbparticipant) =>
                    Number(dbparticipant.attributes.id_amt) ===
                    Number(storedClientId)
                )}
                rawClientData={rawClientData}
                email_besteller={rawClientData?.email_besteller}
                listofGermanStates={listofGermanStates}
                formParticipant={formParticipant}
                setFormErrorHandling={setFormErrorHandling}
                formErrorHandling={formErrorHandling}
                storedClientId={storedClientId}
                getParticipantData={getParticipantData}
                setSeminarInputs={setSeminarInputs}
                setSecondaryErrorHandling={setSecondaryErrorHandling}
                addParticipant={addParticipant}
                setAddParticipant={setAddParticipant}
                secondaryErrorHandling={secondaryErrorHandling}
                setParticipantInputs={setParticipantInputs}
                participantInputs={participantInputs}
                setAllowLinkToExternalSeminar={setAllowLinkToExternalSeminar}
                setRawClientData={setRawClientData}
              />
            )}
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default WPApprovalAdmin;
